import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useIntl } from "react-intl";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { WmButton, WmPagination, WmToggletip, } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import ExtractDetails from "./ExtractDetails";
import { cohortSelector, retryExtract, downloadExtract, } from "./CohortSlice";
import Loading from "../../features/common/component/Loading";
import { timelineSelector } from "../../features/licensurePlans/TimelineSlice";
import { ExtractStatus } from "./data/ExtractStatus";
import { getI18nValueByKey, getValueByKey, } from "../../features/common/commonActions";
import { useDispatch } from "react-redux";
const AdminExtractsOverview = ({ renderTabHeader, setOpenExtractSubmissions, students, }) => {
    var _a, _b, _c, _d, _e;
    const { isFetchingExtracts, extractList, currentCohort } = useSelector(cohortSelector);
    const { timelineData, currentProgramData } = useSelector(timelineSelector);
    const dispatch = useDispatch();
    const allTimelineRequirements = [
        ...((_b = (_a = timelineData === null || timelineData === void 0 ? void 0 : timelineData.sections) === null || _a === void 0 ? void 0 : _a.map((sec) => sec.requirements.length ? sec.requirements : [])) !== null && _b !== void 0 ? _b : []),
        ...((_d = (_c = timelineData === null || timelineData === void 0 ? void 0 : timelineData.sections) === null || _c === void 0 ? void 0 : _c.map((sec) => {
            var _a, _b;
            return (_b = (_a = sec.subSections) === null || _a === void 0 ? void 0 : _a.flatMap((sub) => sub.requirements.length ? sub.requirements : [])) !== null && _b !== void 0 ? _b : [];
        })) !== null && _d !== void 0 ? _d : []),
    ].flat();
    const itemsPerPage = 7;
    const [currentPage, setCurrentPage] = useState(1);
    const [extracts, setExtracts] = useState(extractList);
    const [openExtractDetails, setOpenExtractDetails] = useState(false);
    const [selectedExtract, setSelectedExtract] = useState(null);
    const [focusId, setFocusId] = useState("");
    const creationDateLabel = useIntl().formatMessage({
        id: "global.creation.date",
    });
    const studentsLabel = useIntl().formatMessage({
        id: "app.openCohort.students",
    });
    const requirementsIncludedLabel = useIntl().formatMessage({
        id: "app.licensure.plan.extracts.requirement.included",
    });
    const statusLabel = useIntl().formatMessage({
        id: "global.status",
    });
    const downloadLabel = useIntl().formatMessage({
        id: "global.download",
    });
    const moreInfoLabel = useIntl().formatMessage({
        id: "app.openCohort.moreInfoLabel",
    });
    const statusToggletipText = useIntl().formatMessage({
        id: "app.licensure.plan.extracts.status.tooltip",
    });
    const downloadText = useIntl().formatMessage({
        id: "app.licensure.plan.extracts.download",
    });
    let orgUuid = localStorage.getItem("orgUuid");
    useEffect(() => {
        setExtracts(extractList === null || extractList === void 0 ? void 0 : extractList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage > (extractList === null || extractList === void 0 ? void 0 : extractList.length)
            ? extractList === null || extractList === void 0 ? void 0 : extractList.length
            : currentPage * itemsPerPage));
    }, [extractList]);
    const handlePageChange = (event) => {
        event.preventDefault();
        const newPage = event.target.currentPage;
        setCurrentPage(event.target.currentPage);
        setExtracts(extractList === null || extractList === void 0 ? void 0 : extractList.slice((newPage - 1) * itemsPerPage, newPage * itemsPerPage > (extractList === null || extractList === void 0 ? void 0 : extractList.length)
            ? extractList === null || extractList === void 0 ? void 0 : extractList.length
            : newPage * itemsPerPage));
    };
    const renderEmptyStateView = () => {
        var _a;
        return (_jsxs("div", Object.assign({ className: "extracts-empty-view" }, { children: [_jsx("p", Object.assign({ className: "main-text" }, { children: translate("app.licensure.plan.extracts.no.extract.primary.text") })), _jsx("p", Object.assign({ className: "sub-text" }, { children: translate("app.licensure.plan.extracts.no.extract.secondary.text") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "extract-submission-btn", onClick: () => {
                        setOpenExtractSubmissions(true);
                    }, disabled: ((_a = extractList === null || extractList === void 0 ? void 0 : extractList.filter((extract) => extract.status === "PENDING")) === null || _a === void 0 ? void 0 : _a.length) > 0, placeholder: undefined }, { children: translate("app.licensure.plan.extracts.extract.submissions.text", {
                        value: "",
                    }) }))] })));
    };
    const renderExtractedSubmissionsTable = () => {
        return (_jsxs("table", Object.assign({ className: "extracted-submissions-table data-table", role: "grid", "aria-readonly": true, tabIndex: 0 }, { children: [_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsxs("tr", Object.assign({ className: "dataheaderrow", role: "row" }, { children: [_jsx("th", Object.assign({ className: "creation-date-header", tabIndex: 0, role: "columnheader", scope: "col", "data-th": creationDateLabel }, { children: creationDateLabel })), _jsx("th", Object.assign({ className: "students-header", tabIndex: 0, role: "columnheader", scope: "col", "data-th": studentsLabel }, { children: studentsLabel })), _jsx("th", Object.assign({ className: "requirements-header", tabIndex: 0, role: "columnheader", scope: "col", "data-th": requirementsIncludedLabel }, { children: requirementsIncludedLabel })), _jsxs("th", Object.assign({ className: "status-header", tabIndex: 0, role: "columnheader", scope: "col", "data-th": statusLabel }, { children: [_jsx("span", { children: statusLabel }), _jsx(WmToggletip, { className: "toggletip", label: moreInfoLabel, tooltip: statusToggletipText, tooltipPosition: "right", placeholder: undefined })] })), _jsx("th", Object.assign({ className: "download-header", tabIndex: 0, role: "columnheader", scope: "col", "data-th": downloadLabel }, { children: downloadLabel }))] }), "headers") })), _jsx("tbody", Object.assign({ className: "databody" }, { children: extracts === null || extracts === void 0 ? void 0 : extracts.map((extract) => {
                        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                        return (_jsxs("tr", Object.assign({ className: "datarow" }, { children: [_jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: `${format(new Date(extract === null || extract === void 0 ? void 0 : extract.createdAt), "P")} ${format(new Date(extract === null || extract === void 0 ? void 0 : extract.createdAt), "p")}` })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: ((_a = extract === null || extract === void 0 ? void 0 : extract.users) === null || _a === void 0 ? void 0 : _a.length) === (students === null || students === void 0 ? void 0 : students.length) ? (translate("app.licensure.plan.extracts.all.cohort.students")) : ((_b = extract === null || extract === void 0 ? void 0 : extract.users) === null || _b === void 0 ? void 0 : _b.length) > 2 ? (_jsxs(_Fragment, { children: [(_c = extract === null || extract === void 0 ? void 0 : extract.users.slice(0, 2)) === null || _c === void 0 ? void 0 : _c.map((user) => (_jsx(Fragment, { children: `${user === null || user === void 0 ? void 0 : user.firstName.slice(0, 1)}. ${user === null || user === void 0 ? void 0 : user.lastName}` +
                                                    ", " }, user.uuid))), _jsx(WmButton, Object.assign({ buttonType: "textonly", id: "extract-details-students-" + extract.uuid, onClick: () => {
                                                    setOpenExtractDetails(true);
                                                    setSelectedExtract(extract);
                                                    setFocusId("extract-details-students-" + extract.uuid);
                                                }, placeholder: undefined }, { children: translate("global.plus.count.more", {
                                                    count: ((_d = extract === null || extract === void 0 ? void 0 : extract.users) === null || _d === void 0 ? void 0 : _d.length) - 2,
                                                }) }))] })) : ((_e = extract === null || extract === void 0 ? void 0 : extract.users) === null || _e === void 0 ? void 0 : _e.length) === 2 ? ((_f = extract === null || extract === void 0 ? void 0 : extract.users) === null || _f === void 0 ? void 0 : _f.map((user, i) => (_jsx(Fragment, { children: `${user === null || user === void 0 ? void 0 : user.firstName.slice(0, 1)}. ${user === null || user === void 0 ? void 0 : user.lastName} ${i === 0 ? ", " : ""}` }, user.uuid)))) : ((_g = extract === null || extract === void 0 ? void 0 : extract.users) === null || _g === void 0 ? void 0 : _g.map((user) => (_jsx(Fragment, { children: `${user === null || user === void 0 ? void 0 : user.firstName.slice(0, 1)}. ${user === null || user === void 0 ? void 0 : user.lastName}` }, user.uuid)))) })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: ((_h = extract === null || extract === void 0 ? void 0 : extract.requirements) === null || _h === void 0 ? void 0 : _h.length) ===
                                        (allTimelineRequirements === null || allTimelineRequirements === void 0 ? void 0 : allTimelineRequirements.length) ? (translate("app.licensure.plan.extracts.all.requirements")) : ((_j = extract === null || extract === void 0 ? void 0 : extract.requirements) === null || _j === void 0 ? void 0 : _j.length) > 2 ? (_jsxs(_Fragment, { children: [(_l = (_k = extract === null || extract === void 0 ? void 0 : extract.extractRequirement) === null || _k === void 0 ? void 0 : _k.slice(0, 2)) === null || _l === void 0 ? void 0 : _l.map((extractReq) => { var _a; return ((_a = extractReq === null || extractReq === void 0 ? void 0 : extractReq.requirement) === null || _a === void 0 ? void 0 : _a.title) + ","; }), _jsx(WmButton, Object.assign({ buttonType: "textonly", id: "extract-details-reqs-" + extract.uuid, onClick: () => {
                                                    setOpenExtractDetails(true);
                                                    setSelectedExtract(extract);
                                                    setFocusId("extract-details-reqs-" + extract.uuid);
                                                }, placeholder: undefined }, { children: translate("global.plus.count.more", {
                                                    count: ((_m = extract === null || extract === void 0 ? void 0 : extract.requirements) === null || _m === void 0 ? void 0 : _m.length) - 2,
                                                }) }))] })) : ((_o = extract === null || extract === void 0 ? void 0 : extract.requirements) === null || _o === void 0 ? void 0 : _o.length) === 2 ? ((_p = extract === null || extract === void 0 ? void 0 : extract.requirements) === null || _p === void 0 ? void 0 : _p.map((req, i) => (_jsx(Fragment, { children: `${req.title} ${i === 0 ? ", " : ""}` }, req.uuid)))) : ((_q = extract === null || extract === void 0 ? void 0 : extract.extractRequirement) === null || _q === void 0 ? void 0 : _q.map((extractReq) => { var _a; return (_a = extractReq === null || extractReq === void 0 ? void 0 : extractReq.requirement) === null || _a === void 0 ? void 0 : _a.title; }).join(", ")) })), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: _jsx("span", Object.assign({ className: getValueByKey(extract === null || extract === void 0 ? void 0 : extract.status, ExtractStatus) }, { children: getI18nValueByKey(extract === null || extract === void 0 ? void 0 : extract.status, ExtractStatus) })) })), _jsxs("td", Object.assign({ role: "gridcell", className: "template-updated td-content" }, { children: [extract.status === "FAILED" && (_jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "", id: `try-again-${extract.uuid}`, onClick: () => {
                                                dispatch(retryExtract({
                                                    orgId: localStorage.getItem("orgUuid"),
                                                    progUuid: currentProgramData.uuid,
                                                    cohortUuid: currentCohort.uuid,
                                                    extractUuid: extract.uuid,
                                                }));
                                            }, placeholder: undefined }, { children: translate("app.licensure.plan.extracts.try.again") }))), extract.status === "COMPLETED" && (_jsx(WmButton, { buttonType: "icononly", tooltip: downloadText, tooltipPosition: "right", icon: "f1da", id: `download-${extract.uuid}`, onClick: () => {
                                                downloadExtract({
                                                    fileUuid: extract.uuid,
                                                    filePath: extract.zipFilePath,
                                                });
                                            }, placeholder: undefined }))] }))] }), extract === null || extract === void 0 ? void 0 : extract.uuid));
                    }) }))] }), JSON.stringify(extracts)));
    };
    return (_jsxs("section", Object.assign({ className: "extracts-overview" }, { children: [renderTabHeader("app.licensure.plan.extracts.tab.heading", "app.licensure.plan.extracts.help.text", ((_e = extractList === null || extractList === void 0 ? void 0 : extractList.filter((extract) => extract.status === "PENDING")) === null || _e === void 0 ? void 0 : _e.length) >
                0), isFetchingExtracts ? (_jsx(Loading, {})) : !(extractList === null || extractList === void 0 ? void 0 : extractList.length) ? (renderEmptyStateView()) : (_jsxs("div", Object.assign({ className: "extracts-main-content immersive-container" }, { children: [_jsx("h3", Object.assign({ className: "extracted-submissions-header" }, { children: translate("app.licensure.plan.extracts.recently.extracted.submissions.text") })), _jsx("p", Object.assign({ className: "extracted-submissions-sub-header" }, { children: translate("app.licensure.plan.extracts.recently.extracted.submissions.info.text") })), renderExtractedSubmissionsTable(), (extractList === null || extractList === void 0 ? void 0 : extractList.length) > itemsPerPage ? (_jsx("div", Object.assign({ className: "pagination-footer" }, { children: _jsx(WmPagination, { currentPage: currentPage, itemsPerPage: itemsPerPage, totalItems: extractList === null || extractList === void 0 ? void 0 : extractList.length, onWmPaginationPageClicked: (event) => {
                                handlePageChange(event);
                            }, id: "extracted-submissions-view", placeholder: undefined }, "test" + extractList) }))) : ("")] }))), _jsx("div", { className: "sr-only", id: "dialog-wrapper-top", tabIndex: 0 }), openExtractDetails ? (_jsx(ExtractDetails, { extract: selectedExtract, setOpenExtractDetails: setOpenExtractDetails, focusId: focusId })) : null, _jsx("div", { className: "sr-only", id: "dialog-wrapper-bottom", tabIndex: 0 })] })));
};
export default AdminExtractsOverview;
