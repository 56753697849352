import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useState } from "react";
import copy from "copy-to-clipboard";
import { useIntl } from "react-intl";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { resetError, userSelector } from "../../../features/login/UserSlice";
export default function ViewError(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const [isExpanded, setIsExpanded] = useState(false);
    const OpenTechnicalDetailsView = () => {
        setIsExpanded(!isExpanded);
    };
    const { errorMsg } = useSelector(userSelector);
    const errorHelperText = useIntl().formatMessage({
        id: "app.error.helpText",
    });
    const tryAgainHelperText = useIntl().formatMessage({
        id: "app.error.tryAgainHelperText",
    });
    const contactHelperText = useIntl().formatMessage({
        id: "app.error.contactHelperText",
    });
    const copyDetailsText = useIntl().formatMessage({
        id: "app.error.copyDetailsText",
    });
    const contactSupportText = useIntl().formatMessage({
        id: "app.error.contactSupportText",
    });
    const technicalDetailsText = useIntl().formatMessage({
        id: "app.error.technicalDetailsText",
    });
    const goToHomeText = useIntl().formatMessage({
        id: "app.error.goToHomeText",
    });
    const errorText = useIntl().formatMessage({
        id: "app.error.errorText",
    });
    document.title = "Error | Watermark";
    const goToHome = () => {
        dispatch(resetError());
        navigate(`${localStorage.getItem("goToHome")}`);
    };
    return (_jsx("div", Object.assign({ className: "pagewrapper" }, { children: _jsx("div", Object.assign({ className: "nav-main" }, { children: _jsx("div", Object.assign({ className: "contentwrapper" }, { children: _jsx("main", Object.assign({ id: "maincontent", className: "page-content-card" }, { children: _jsx("div", Object.assign({ id: "error-route", className: "pagewrapper" }, { children: _jsx("div", Object.assign({ className: "error-container" }, { children: _jsxs("div", { children: [_jsx("img", { src: "/images/watermarklogo.svg", alt: "Watermark" }), _jsxs("div", Object.assign({ className: "paper-card error-section" }, { children: [_jsxs("div", Object.assign({ className: "header" }, { children: [_jsx("div", Object.assign({ className: "-align" }, { children: _jsx(WmButton, { buttonType: "navigational", id: "help-icon", icon: "f2d7", iconSize: "48px", tooltip: "Help", tooltipPosition: "bottom", placeholder: undefined }) })), _jsx("div", Object.assign({ className: "helper-text" }, { children: errorHelperText }))] })), _jsxs("div", Object.assign({ className: "body" }, { children: [_jsxs("div", Object.assign({ className: "error-msg -msg" }, { children: [tryAgainHelperText, _jsx("div", Object.assign({ className: "home-btn" }, { children: _jsx(WmButton, Object.assign({ class: "-space", buttonType: "textonly", icon: "f2dc", id: "go-to-home", onClick: goToHome, placeholder: undefined }, { children: goToHomeText })) }))] })), _jsx("div", Object.assign({ className: "error-msg -border" }, { children: contactHelperText })), _jsxs("div", Object.assign({ className: "error-options" }, { children: [_jsxs("div", Object.assign({ className: "-option" }, { children: [_jsx("span", Object.assign({ className: "-space" }, { children: "1." })), _jsx(WmButton, Object.assign({ class: "-space", buttonType: "textonly", id: "technical-details-btn", onClick: () => {
                                                                            let details = errorText + ": " + errorMsg;
                                                                            if (details) {
                                                                                copy(details);
                                                                            }
                                                                        }, placeholder: undefined }, { children: copyDetailsText })), _jsx("i", { className: "mdi mdi-content-copy -icon" })] })), _jsxs("div", Object.assign({ className: "-option" }, { children: [_jsx("span", Object.assign({ className: "-space" }, { children: "2." })), _jsx(WmButton, Object.assign({ class: "-space", buttonType: "textonly", id: "technical-details-btn", onClick: () => {
                                                                            window.open("https://www.watermarkinsights.com/support/", "_blank");
                                                                        }, placeholder: undefined }, { children: contactSupportText })), _jsx("i", { className: "mdi mdi-open-in-new -icon" })] }))] })), _jsxs("div", Object.assign({ className: "technical-details" }, { children: [_jsxs("div", Object.assign({ className: "label flex-row -justified -verticallycentered" }, { children: [_jsx("div", { children: technicalDetailsText }), _jsx("div", { children: _jsx("button", { className: "button section-button -textonly mdi " +
                                                                                (isExpanded
                                                                                    ? " mdi-chevron-up"
                                                                                    : " mdi-chevron-down"), "aria-expanded": isExpanded, title: "View technical details", onClick: OpenTechnicalDetailsView }) })] })), isExpanded ? (_jsxs("div", Object.assign({ id: "details-sec", className: "details-section" }, { children: [errorText, ": ", errorMsg] }))) : ("")] }))] }))] }))] }) })) })) })) })) })) })));
}
