import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useEffect } from "react";
import { closeFlyout, focusButtonComponent, openFlyout, } from "../../static/js/commons";
import translate from "../../i18n/translate";
import { useIntl } from "react-intl";
import ViewApplicationResponses from "./ViewApplicationResponses";
import { useSelector } from "react-redux";
import { applicationSelector } from "./ApplicationSlice";
export default function ViewSubmittedApplication({ setViewSubmittedApp, applicationObject, }) {
    const { studentApplicationSubmission } = useSelector(applicationSelector);
    const flyoutCloseToolTip = useIntl().formatMessage({
        id: "app.cohort.secondaryButtonText",
    });
    useEffect(() => {
        setTimeout(() => openFlyout("view-submitted-app-flyout", "view-submitted-app-btn", "flyout-close"), 200);
    }, []);
    const closeFlyoutHandler = (event) => {
        event.preventDefault();
        setViewSubmittedApp(false);
        closeFlyout();
        focusButtonComponent("view-submitted-app-btn");
    };
    const renderFlyoutHeader = () => {
        return (_jsx("section", Object.assign({ className: "immersive-header header view-submitted-app-header" }, { children: _jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered header-section" }, { children: [_jsxs("div", Object.assign({ className: "header-title" }, { children: [_jsx("h2", Object.assign({ className: "view-submitted-app-label" }, { children: translate("app.programApplications.external.application.view.submitted.app") })), _jsx("h3", Object.assign({ className: "view-submitted-app-title" }, { children: translate("app.programApplications.external.application.sys.admin.records.view.submitted.app.header.title", {
                                    name: `${(studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.lastName) || ""}, ${(studentApplicationSubmission === null || studentApplicationSubmission === void 0 ? void 0 : studentApplicationSubmission.firstName) || ""}`,
                                }) })), _jsx("h4", Object.assign({ className: "view-submitted-app-sub-title" }, { children: applicationObject.title }))] })), _jsx(WmButton, { className: "close-btn", buttonType: "navigational", icon: "close", tooltip: flyoutCloseToolTip, tooltipPosition: "bottom", id: "flyout-close", class: "hydrated", onClick: (event) => closeFlyoutHandler(event), placeholder: undefined })] })) })));
    };
    const renderMainContent = () => {
        return (_jsx("section", Object.assign({ className: "view-submitted-app-body immersive-container" }, { children: _jsx(ViewApplicationResponses, { applicationObject: applicationObject, studentApplicationSubmission: studentApplicationSubmission }) })));
    };
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "view-submitted-app-flyout", tabIndex: -1, "aria-label": "View Submitted Application" }, { children: [_jsx("div", { className: "overlay" }), _jsxs("div", Object.assign({ className: "container" }, { children: [renderFlyoutHeader(), renderMainContent()] }))] })));
}
