import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton, WmPagination, WmSearch, } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { closeFlyout, focusButtonComponent, isNullOrEmpty, openFlyout, } from "../../static/js/commons";
import SelectRequirements from "./SelectRequirements";
import { useDispatch, useSelector } from "react-redux";
import { cohortSelector, createExtract, saveUserExtract, getAllExtracts, } from "./CohortSlice";
import { timelineSelector } from "../../features/licensurePlans/TimelineSlice";
export default function ExtractSubmissions({ setOpenExtractSubmissions, students, program, orgUuid, isStudentView, currentCohortUuid, onExtractTabChange, }) {
    var _a, _b, _c, _d;
    const dispatch = useDispatch();
    const { currentCohort, userExtract } = useSelector(cohortSelector);
    const { timelineData } = useSelector(timelineSelector);
    const allTimelineRequirements = [
        ...((_b = (_a = timelineData === null || timelineData === void 0 ? void 0 : timelineData.sections) === null || _a === void 0 ? void 0 : _a.map((sec) => sec.requirements.length
            ? sec.requirements.filter((req) => req.isStudentFileUploadRequired === true)
            : [])) !== null && _b !== void 0 ? _b : []),
        ...((_d = (_c = timelineData === null || timelineData === void 0 ? void 0 : timelineData.sections) === null || _c === void 0 ? void 0 : _c.map((sec) => {
            var _a, _b;
            return (_b = (_a = sec.subSections) === null || _a === void 0 ? void 0 : _a.flatMap((sub) => sub.requirements.length
                ? sub.requirements.filter((req) => req.isStudentFileUploadRequired === true)
                : [])) !== null && _b !== void 0 ? _b : [];
        })) !== null && _d !== void 0 ? _d : []),
    ].flat();
    const [flyoutView, setFlyoutView] = useState(isStudentView ? "requirement-selection-page" : "student-selection-page");
    const [currentPage, setCurrentPage] = useState(1);
    const [selectAllStudents, setSelectAllStudents] = useState(false);
    const [selectedStudentsUuids, setSelectedStudentsUuids] = useState([]);
    const [noStudentSelectedError, setNoStudentSelectedError] = useState(false);
    const [selectedUuids, setSelectedUuids] = useState([]);
    const [noneSelectedError, setNoneSelectedError] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [filteredStudents, setFilteredStudents] = useState(students);
    const [studentSubmissions, setStudentSubmissions] = useState([]);
    const itemsPerPage = 10;
    const studentSearchTextLabel = useIntl().formatMessage({
        id: "app.licensure.plan.extracts.search.students.placeholder",
    });
    const studentNameHeader = useIntl().formatMessage({
        id: "app.openRequirement.table.header.studentName",
    });
    const studentEmailHeader = useIntl().formatMessage({
        id: "app.openRequirement.table.header.studentEmail",
    });
    const flyoutCloseToolTip = useIntl().formatMessage({
        id: "app.cohort.secondaryButtonText",
    });
    useEffect(() => {
        setTimeout(() => openFlyout("extract-submissions-flyout", "extract-submissions-btn", isStudentView ? "search-req" : "search-students-by"), 200);
    }, []);
    useEffect(() => {
        if (!isNullOrEmpty(searchInput)) {
            setCurrentPage(1);
            setFilteredStudents(students.filter((student) => {
                return (student.user.firstName
                    .toLowerCase()
                    .includes(searchInput.toLowerCase()) ||
                    student.user.lastName
                        .toLowerCase()
                        .includes(searchInput.toLowerCase()) ||
                    student.user.email.toLowerCase().includes(searchInput.toLowerCase()));
            }));
        }
        else {
            setFilteredStudents(students);
        }
    }, [searchInput]);
    useEffect(() => {
        setStudentSubmissions(filteredStudents === null || filteredStudents === void 0 ? void 0 : filteredStudents.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage > filteredStudents.length
            ? filteredStudents.length
            : currentPage * itemsPerPage));
    }, [filteredStudents]);
    useEffect(() => {
        if (selectedStudentsUuids.length) {
            setNoStudentSelectedError(false);
        }
    }, [selectedStudentsUuids]);
    const isStudentSelected = (userUuid) => {
        return selectedStudentsUuids.includes(userUuid);
    };
    const changePageHandler = (pageId, focusId) => {
        setNoneSelectedError(false);
        setFlyoutView(pageId);
        setTimeout(() => {
            var _a;
            (_a = document.getElementById(focusId)) === null || _a === void 0 ? void 0 : _a.focus();
        });
    };
    const handlePageChange = (event) => {
        event.preventDefault();
        const newPage = event.target.currentPage;
        setCurrentPage(event.target.currentPage);
        setStudentSubmissions(students === null || students === void 0 ? void 0 : students.slice((newPage - 1) * itemsPerPage, newPage * itemsPerPage > students.length
            ? students.length
            : newPage * itemsPerPage));
    };
    const closeFlyoutHandler = () => {
        setOpenExtractSubmissions(false);
        closeFlyout();
        focusButtonComponent("extract-submission-btn");
    };
    const renderFlyoutHeader = () => {
        return (_jsx("section", Object.assign({ className: "immersive-header header extract-submissions-header" }, { children: _jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered header-section" }, { children: [isStudentView ? (_jsx("h3", Object.assign({ className: "student-flow-header" }, { children: translate("app.studentWorkflow.export.submissions") }))) : (_jsxs("div", Object.assign({ className: "header-title" }, { children: [flyoutView === "student-selection-page" ? (_jsx("h4", Object.assign({ className: "select-label" }, { children: translate("app.openRequirement.selectStudents") }))) : (_jsxs("div", Object.assign({ className: "bread-crumbs" }, { children: [_jsx("a", Object.assign({ href: "", id: "go-to-previous-state", onClick: (event) => {
                                            event.preventDefault();
                                            changePageHandler("student-selection-page", "search-students-by");
                                        }, className: "link" }, { children: _jsx("span", { children: translate("app.openRequirement.selectStudents") }) })), _jsx("span", Object.assign({ className: "bread-crumb-text" }, { children: translate("app.licensure.plan.extracts.select.licensure.plan.req.text") }))] }))), _jsx("h2", Object.assign({ className: "extract-submission-title" }, { children: translate("app.licensure.plan.extracts.extract.submissions.text", {
                                    value: "Cohort",
                                }) })), _jsxs("p", Object.assign({ className: "extract-submission-info-text" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] }))] }))), _jsx(WmButton, { className: "close-btn", buttonType: "navigational", icon: "close", tooltip: flyoutCloseToolTip, tooltipPosition: "bottom", id: "flyout-close", class: "hydrated", onClick: closeFlyoutHandler, placeholder: undefined })] })) })));
    };
    const updatedSelectedStudents = (uuid, isSelected) => {
        if (isSelected) {
            setSelectedStudentsUuids((prevUuids) => [...prevUuids, uuid]);
        }
        else {
            setSelectedStudentsUuids((prevUuids) => prevUuids.filter((cUuid) => cUuid != uuid));
        }
    };
    const selectAllHandler = (isSelected) => {
        setSelectAllStudents(isSelected);
        if (isSelected) {
            setSelectedStudentsUuids(filteredStudents.map((sub) => sub.userUuid));
        }
        else {
            setSelectedStudentsUuids([]);
        }
    };
    const renderStudentSearch = () => {
        return students.length ? (_jsx("div", Object.assign({ className: "search-div" }, { children: _jsx(WmSearch, { id: "search-students-by", className: "students-search", placeholder: studentSearchTextLabel, label: studentSearchTextLabel, onInput: (event) => {
                    event.preventDefault();
                    setNoStudentSelectedError(false);
                    setSearchInput(event.target.value);
                }, numResults: studentSubmissions.length, value: searchInput }) }))) : null;
    };
    const renderStudentsTable = () => {
        return (_jsxs(_Fragment, { children: [renderStudentSearch(), _jsx("div", Object.assign({ className: "student-submission-body" }, { children: students.length ? (studentSubmissions.length ? (_jsxs("div", Object.assign({ className: "student-submission-content" }, { children: [_jsxs("table", Object.assign({ className: "data-table", role: "grid", "aria-readonly": true }, { children: [_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsxs("tr", Object.assign({ className: "dataheaderrow", role: "row" }, { children: [_jsx("th", Object.assign({ tabIndex: 0, role: "columnheader", scope: "col", className: "student-name", "aria-label": studentNameHeader }, { children: _jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "select-all", checked: filteredStudents.length ===
                                                                    selectedStudentsUuids.length, onChange: () => selectAllHandler(!selectAllStudents) }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "select-all" }, { children: _jsx("div", { children: translate("app.openRequirement.table.header.studentName") }) }))] })) })), _jsx("th", Object.assign({ tabIndex: 0, role: "columnheader", scope: "col", className: "student-email", "aria-label": studentEmailHeader }, { children: translate("app.openRequirement.table.header.studentEmail") }))] }), "headers") })), _jsx("tbody", Object.assign({ id: "student-table-body", className: "databody", tabIndex: 0 }, { children: studentSubmissions.map((submission) => {
                                            return (_jsxs("tr", Object.assign({ className: "datarow", role: "row" }, { children: [_jsx("td", Object.assign({ className: "student-name", role: "gridcell", "data-td": submission.user.lastName +
                                                            ", " +
                                                            submission.user.firstName }, { children: _jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "st-" + submission.userUuid, checked: isStudentSelected(submission.userUuid), onChange: () => updatedSelectedStudents(submission.userUuid, !isStudentSelected(submission.userUuid)) }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "st-" + submission.userUuid }, { children: _jsx("div", { children: submission.user.lastName +
                                                                            ", " +
                                                                            submission.user.firstName }) }))] })) })), _jsx("td", Object.assign({ className: "student-email", role: "gridcell", "data-td": submission.user.email }, { children: submission.user.email }))] }), submission.uuid));
                                        }) }), "body" + currentPage)] })), filteredStudents.length > itemsPerPage ? (_jsx("div", Object.assign({ className: "pagination-footer" }, { children: _jsx(WmPagination, { currentPage: currentPage, itemsPerPage: itemsPerPage, totalItems: filteredStudents.length, onWmPaginationPageClicked: (event) => {
                                        handlePageChange(event);
                                    }, id: "view-extracts-students", placeholder: undefined }, "test" + filteredStudents) }))) : ("")] }))) : (_jsxs("div", Object.assign({ className: "empty-list", role: "alert", "aria-live": "assertive", "aria-atomic": true }, { children: [_jsx("table", Object.assign({ className: "data-table", role: "grid", "aria-readonly": true }, { children: _jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsx("tr", { className: "dataheaderrow empty-row", role: "row" }, "headers") })) })), _jsx("div", Object.assign({ className: "no-results" }, { children: translate("app.gatewaysLandingPage.noMatch") })), _jsx("div", Object.assign({ className: "try-again" }, { children: translate("app.gatewaysLandingPage.tryAgain") }))] })))) : (_jsxs("div", Object.assign({ className: "empty-list", role: "alert", "aria-live": "assertive", "aria-atomic": true }, { children: [_jsx("div", Object.assign({ className: "no-results" }, { children: translate("global.noStudentsInCohort") })), _jsx("div", Object.assign({ className: "try-again" }, { children: translate("global.noStudentCohortsInfo") }))] }))) }))] }));
    };
    const renderMainContent = () => {
        if (flyoutView === "student-selection-page") {
            return (_jsx("section", Object.assign({ className: "extracts-main-body" }, { children: _jsxs("div", Object.assign({ className: "student-selection-container" }, { children: [students.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs("p", Object.assign({ className: `select-student-text ${noStudentSelectedError ? "alert-label" : ""}` }, { children: [translate("app.licensure.plan.extracts.select.students.text"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), noStudentSelectedError && (_jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), noStudentSelectedError && (_jsx("p", Object.assign({ className: "none-selected-error error-message", id: "none-selected", tabIndex: 0 }, { children: translate("app.licensure.plan.extracts.no.students.error.text") })))] })) : null, renderStudentsTable()] })) })));
        }
        else {
            return (_jsx(SelectRequirements, { selectedUuids: selectedUuids, setSelectedUuids: setSelectedUuids, noneSelectedError: noneSelectedError, setNoneSelectedError: setNoneSelectedError, isStudentView: isStudentView }));
        }
    };
    const nextHandler = () => {
        if (selectedStudentsUuids.length) {
            setNoStudentSelectedError(false);
            changePageHandler("requirement-selection-page", "search-req");
        }
        else {
            setNoStudentSelectedError(true);
            setTimeout(() => {
                var _a;
                (_a = document.getElementById("none-selected")) === null || _a === void 0 ? void 0 : _a.focus();
            }, 10);
        }
    };
    const extractHandler = () => {
        const requirementUuidList = [
            ...selectedUuids
                .flatMap((id) => allTimelineRequirements.filter((reqObj) => id === reqObj.uuid))
                .map((entry) => entry.uuid),
        ];
        let selectedRequirements = [];
        let reqCount = 0;
        for (let sec of timelineData.sections) {
            if (sec.requirements.length > 0) {
                for (let secReq of sec.requirements) {
                    if (requirementUuidList.includes(secReq.uuid)) {
                        ++reqCount;
                        selectedRequirements.push({
                            requirementUuid: secReq.uuid,
                            sortOrder: reqCount,
                        });
                    }
                }
            }
            if (sec.subSections.length > 0) {
                for (let subSec of sec.subSections) {
                    if (subSec.requirements.length > 0) {
                        for (let subSecReq of subSec.requirements) {
                            if (requirementUuidList.includes(subSecReq.uuid)) {
                                ++reqCount;
                                selectedRequirements.push({
                                    requirementUuid: subSecReq.uuid,
                                    sortOrder: reqCount,
                                });
                            }
                        }
                    }
                }
            }
        }
        if (selectedUuids.length) {
            setNoneSelectedError(false);
            closeFlyoutHandler();
            if (isStudentView) {
                dispatch(saveUserExtract({
                    cohortUuid: currentCohortUuid,
                    requirementUuidList: requirementUuidList,
                    orgUuid: orgUuid,
                    progUuid: program.uuid,
                })).then((data) => {
                    let extractUuid = data.payload.uuid;
                    const url = `/timeline/studentExtracts/organizations/${orgUuid}/programs/${program.uuid}/cohorts/${currentCohortUuid}/extracts/${extractUuid}`;
                    const newTab = window.open(url, "_blank");
                    if (newTab) {
                        localStorage.setItem("isExtractTabOpen", "true");
                        onExtractTabChange === null || onExtractTabChange === void 0 ? void 0 : onExtractTabChange(true);
                    }
                });
            }
            else {
                dispatch(createExtract({
                    cohortUuid: currentCohort === null || currentCohort === void 0 ? void 0 : currentCohort.uuid,
                    studentUuidList: selectedStudentsUuids,
                    selectedRequirements: selectedRequirements,
                    orgId: orgUuid,
                    progUuid: program.uuid,
                })).then(() => {
                    dispatch(getAllExtracts({
                        cohortUuid: currentCohort === null || currentCohort === void 0 ? void 0 : currentCohort.uuid,
                        orgId: orgUuid,
                        progUuid: program.uuid,
                    }));
                });
            }
        }
        else {
            setNoneSelectedError(true);
            setTimeout(() => {
                var _a;
                (_a = document.getElementById("no-selection")) === null || _a === void 0 ? void 0 : _a.focus();
            }, 10);
        }
    };
    const renderFlyoutFooter = () => {
        return students.length || isStudentView ? (_jsx("footer", Object.assign({ className: "flyout-footer" }, { children: _jsxs("div", Object.assign({ className: "button-collection" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "btn-secondary", onClick: flyoutView === "student-selection-page" || isStudentView
                            ? closeFlyoutHandler
                            : () => changePageHandler("student-selection-page", "search-students-by"), placeholder: undefined }, { children: flyoutView === "student-selection-page" || isStudentView
                            ? translate("app.cohort.secondaryButtonText")
                            : translate("app.programApplications.component.footer.back.label") })), isStudentView && selectedUuids.length ? (_jsx(WmButton, Object.assign({ tabIndex: -1, buttonType: "primary", className: "wm-button -primary", onClick: () => extractHandler(), placeholder: undefined }, { children: flyoutView === "student-selection-page"
                            ? translate("app.cohort.next.button")
                            : translate("app.licensure.plan.extracts.extract.button.text") }))) : (_jsx(WmButton, Object.assign({ buttonType: "primary", id: flyoutView === "student-selection-page"
                            ? "next-button"
                            : "extract-button", onClick: () => {
                            flyoutView === "student-selection-page"
                                ? nextHandler()
                                : extractHandler();
                        }, placeholder: undefined }, { children: flyoutView === "student-selection-page"
                            ? translate("app.cohort.next.button")
                            : translate("app.licensure.plan.extracts.extract.button.text") })))] })) }))) : null;
    };
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "extract-submissions-flyout", tabIndex: -1, "aria-label": "Extract Submissions" }, { children: [_jsx("div", { className: "overlay" }), _jsxs("div", Object.assign({ className: "container" }, { children: [renderFlyoutHeader(), renderMainContent(), renderFlyoutFooter()] }))] })));
}
