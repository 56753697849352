import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton, WmSearch } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { closeFlyout, isNullOrEmpty, openFlyout, } from "../../static/js/commons.js";
import { cohortSelector, getLogCategoryCourses, resetLogCategoryCourses, setLogCategory, } from "../../features/cohorts/CohortSlice";
import SelectCourse from "./SelectCourse";
export default function SelectLogCategories({ setOpenLogCategorySelection, selectedLogCategory, setSelectedLogCategory, }) {
    var _a;
    const { isFetchingLogCategories, isFetchingLogCategoryCourses, logCategories, logCategoryCourses, logCategory, } = useSelector(cohortSelector);
    const [searchText, setSearchText] = useState("");
    const [flyoutView, setFlyoutView] = useState("category-selection");
    const [noCategorySelectedError, setNoCategorySelectedError] = useState(false);
    const [renderLogCategories, setRenderLogCategories] = useState(logCategories);
    let availableLogCategories = logCategories.filter((lc) => {
        return lc.uuid !== logCategory.uuid;
    });
    const [selectLogCategory, setSelectLogCategory] = useState(isNullOrEmpty(selectedLogCategory === null || selectedLogCategory === void 0 ? void 0 : selectedLogCategory.uuid)
        ? availableLogCategories[0]
        : selectedLogCategory);
    const [selectedCourses, setSelectedCourses] = useState(((_a = selectedLogCategory === null || selectedLogCategory === void 0 ? void 0 : selectedLogCategory.courses) === null || _a === void 0 ? void 0 : _a.length) > 0 ? selectedLogCategory === null || selectedLogCategory === void 0 ? void 0 : selectedLogCategory.courses : []);
    const [noneSelectedError, setNoneSelectedError] = useState(false);
    const dispatch = useDispatch();
    const flyoutCloseToolTip = useIntl().formatMessage({
        id: "app.cohort.secondaryButtonText",
    });
    const searchPlaceholder = useIntl().formatMessage({
        id: "app.timelog.add.log.category.search.by.text",
    });
    const logCategoryText = useIntl().formatMessage({
        id: "app.timelog.add.log.category.text",
    });
    useEffect(() => {
        setTimeout(() => openFlyout("log-category-flyout", "add-log-category-btn", !isNullOrEmpty(selectedLogCategory === null || selectedLogCategory === void 0 ? void 0 : selectedLogCategory.uuid)
            ? "input-" + (selectedLogCategory === null || selectedLogCategory === void 0 ? void 0 : selectedLogCategory.uuid)
            : "log-category-search"), 200);
    }, []);
    useEffect(() => {
        if (searchText != "") {
            setRenderLogCategories(logCategories.filter((logCat) => {
                return logCat.title.toLowerCase().includes(searchText.toLowerCase());
            }));
        }
        else {
            setRenderLogCategories(logCategories);
        }
    }, [searchText, logCategories]);
    useEffect(() => {
        var _a;
        if (selectLogCategory === null || selectLogCategory === void 0 ? void 0 : selectLogCategory.uuid)
            setNoCategorySelectedError(false);
        if (((_a = selectLogCategory === null || selectLogCategory === void 0 ? void 0 : selectLogCategory.courses) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            document.getElementById("log-category-" + (selectLogCategory === null || selectLogCategory === void 0 ? void 0 : selectLogCategory.uuid));
        }
    }, [selectLogCategory]);
    const closeFlyoutHandler = (event) => {
        event.preventDefault();
        setOpenLogCategorySelection(false);
        setSelectedLogCategory(null);
        setSelectedCourses([]);
        closeFlyout();
    };
    const changeViewHandler = (pageId, focusId) => {
        setNoneSelectedError(false);
        setFlyoutView(pageId);
        setTimeout(() => {
            var _a;
            (_a = document.getElementById(focusId)) === null || _a === void 0 ? void 0 : _a.focus();
        });
    };
    const nextHandler = () => {
        var _a;
        if (!(selectLogCategory === null || selectLogCategory === void 0 ? void 0 : selectLogCategory.uuid)) {
            setNoCategorySelectedError(true);
            setTimeout(() => {
                var _a;
                (_a = document.getElementById("none-selected")) === null || _a === void 0 ? void 0 : _a.focus();
            }, 10);
        }
        else {
            setNoCategorySelectedError(false);
            changeViewHandler("course-selection", "search-course");
            if (((_a = selectLogCategory === null || selectLogCategory === void 0 ? void 0 : selectLogCategory.courseUuids) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                dispatch(getLogCategoryCourses(selectLogCategory === null || selectLogCategory === void 0 ? void 0 : selectLogCategory.courseUuids));
            }
        }
    };
    const addLogCategoryHandler = () => {
        if (selectedCourses === null || selectedCourses === void 0 ? void 0 : selectedCourses.length) {
            dispatch(setLogCategory(Object.assign(Object.assign({}, selectLogCategory), { courses: selectedCourses })));
            setOpenLogCategorySelection(false);
            setSelectedLogCategory(null);
            setSelectedCourses([]);
            closeFlyout();
        }
        else {
            setNoneSelectedError(true);
            setTimeout(() => {
                var _a;
                (_a = document.getElementById("no-selection")) === null || _a === void 0 ? void 0 : _a.focus();
            }, 10);
        }
    };
    const renderHeader = () => {
        return (_jsx("section", Object.assign({ className: "select-log-category-header immersive-header header flex-row -justified -verticallycentered" }, { children: _jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered header-section" }, { children: [_jsxs("div", Object.assign({ className: "header-title" }, { children: [flyoutView === "category-selection" ? (_jsx("h4", Object.assign({ className: "timelog-label" }, { children: translate("app.timelog.add.log.category.time.log.conditions") }))) : (_jsxs("div", Object.assign({ className: "bread-crumbs" }, { children: [_jsx("a", Object.assign({ href: "", id: "go-to-previous-state", onClick: (event) => {
                                            event.preventDefault();
                                            changeViewHandler("category-selection", "log-category-search");
                                            dispatch(resetLogCategoryCourses());
                                        }, className: "link" }, { children: _jsx("span", { children: translate("app.timelog.add.log.category.select.label") }) })), _jsx("span", Object.assign({ className: "bread-crumb-text" }, { children: translate("app.timelog.add.log.category.set.time.log.req.text") }))] }))), _jsx("h2", Object.assign({ className: "add-log-category-title" }, { children: flyoutView === "category-selection"
                                    ? translate("app.timelog.add.log.category.btn.text", {
                                        action: !isNullOrEmpty(selectedLogCategory === null || selectedLogCategory === void 0 ? void 0 : selectedLogCategory.uuid)
                                            ? "Edit"
                                            : "Add",
                                    })
                                    : translate("app.timelog.add.qualifying.courses.text") })), _jsxs("p", Object.assign({ className: "info-text" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] }))] })), _jsx(WmButton, { className: "close-btn", buttonType: "navigational", icon: "f156", tooltip: flyoutCloseToolTip, tooltipPosition: "bottom", id: "flyout-close", class: "hydrated", onClick: closeFlyoutHandler, placeholder: undefined })] })) })));
    };
    const renderFlyoutSearch = () => {
        return (_jsx("section", Object.assign({ className: "search-div" }, { children: _jsx(WmSearch, { id: "log-category-search", className: "log-category-search", placeholder: searchPlaceholder, label: searchPlaceholder, onInput: (event) => {
                    event.preventDefault();
                    setNoCategorySelectedError(false);
                    setSearchText(event.target.value);
                }, numResults: renderLogCategories.length, value: searchText }) })));
    };
    const renderCategoriesList = () => {
        return (_jsx(_Fragment, { children: (logCategories === null || logCategories === void 0 ? void 0 : logCategories.length) > 0 ? (_jsxs(_Fragment, { children: [renderFlyoutSearch(), (renderLogCategories === null || renderLogCategories === void 0 ? void 0 : renderLogCategories.length) > 0 ? (_jsxs("table", Object.assign({ className: "data-table", role: "grid", "aria-readonly": true, tabIndex: 0 }, { children: [_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsx("tr", Object.assign({ className: "dataheaderrow", role: "row" }, { children: _jsx("th", Object.assign({ role: "columnheader", scope: "col", "aria-label": logCategoryText }, { children: logCategoryText })) }), "headers") })), _jsx("tbody", Object.assign({ id: "category-table-body", className: `databody ${noCategorySelectedError ? "category-selection-tbody" : ""}` }, { children: renderLogCategories === null || renderLogCategories === void 0 ? void 0 : renderLogCategories.map((category) => {
                                    return (_jsx("tr", Object.assign({ className: "datarow", role: "row" }, { children: _jsx("td", Object.assign({ role: "gridcell", "data-td": category.title }, { children: _jsxs("div", Object.assign({ className: "category-name" }, { children: [_jsx("input", { type: "radio", name: "LogCategories", id: "input-" + category.uuid, value: category.uuid, className: "radio-input", checked: (selectLogCategory === null || selectLogCategory === void 0 ? void 0 : selectLogCategory.uuid) === (category === null || category === void 0 ? void 0 : category.uuid), onChange: () => {
                                                            setSelectLogCategory(category);
                                                            if (category.uuid !== selectLogCategory.uuid)
                                                                setSelectedCourses([]);
                                                            dispatch(resetLogCategoryCourses());
                                                        } }), _jsx("label", Object.assign({ htmlFor: "input-" + category.uuid, className: "radio" }, { children: category.title }))] })) })) }), category.uuid));
                                }) }))] }))) : (_jsxs("section", Object.assign({ className: "empty-list", role: "alert", "aria-live": "assertive", "aria-atomic": true }, { children: [_jsx("div", Object.assign({ className: "no-results" }, { children: translate("app.gatewaysLandingPage.noMatch") })), _jsx("div", Object.assign({ className: "try-again" }, { children: translate("app.gatewaysLandingPage.tryAgain") }))] })))] })) : (_jsxs("section", Object.assign({ className: "empty-flyout-view" }, { children: [_jsx("div", Object.assign({ className: "no-data-label" }, { children: translate("app.timelog.log.categories.empty.state.label") })), _jsx("div", Object.assign({ className: "no-data-text" }, { children: translate("app.timelog.log.categories.empty.state.description") }))] }))) }));
    };
    const renderMainContent = () => {
        if (flyoutView === "category-selection") {
            return (_jsx("section", Object.assign({ className: "categories-main-body" }, { children: _jsxs("div", Object.assign({ className: "category-selection-container" }, { children: [logCategories.length > 0 ? (_jsxs(_Fragment, { children: [_jsxs("p", Object.assign({ className: `select-category-text ${noCategorySelectedError ? "alert-label" : ""}` }, { children: [translate("app.timelog.add.log.category.select.label"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), noCategorySelectedError && (_jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), noCategorySelectedError && (_jsx("p", Object.assign({ className: "none-selected-error error-message", id: "none-selected", tabIndex: 0 }, { children: translate("app.timelog.add.log.category.select.error.text") })))] })) : null, renderCategoriesList()] })) })));
        }
        else {
            return isFetchingLogCategoryCourses ? (_jsx("div", Object.assign({ className: "cohort-loader" }, { children: _jsx("div", Object.assign({ className: "content-loader content", role: "alert" }, { children: "Loading..." })) }))) : (_jsx(SelectCourse, { selectedCategoryName: selectLogCategory === null || selectLogCategory === void 0 ? void 0 : selectLogCategory.title, selectedCourses: selectedCourses, setSelectedCourses: setSelectedCourses, noneSelectedError: noneSelectedError, setNoneSelectedError: setNoneSelectedError }));
        }
    };
    const renderFooter = () => {
        return isFetchingLogCategoryCourses ? null : (_jsx("footer", Object.assign({ className: "select-log-category-footer" }, { children: ((logCategories === null || logCategories === void 0 ? void 0 : logCategories.length) && flyoutView === "category-selection") ||
                ((logCategoryCourses === null || logCategoryCourses === void 0 ? void 0 : logCategoryCourses.length) && flyoutView !== "category-selection") ? (_jsxs("div", Object.assign({ className: "button-collection" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "btn-secondary", onClick: flyoutView === "category-selection"
                            ? (event) => closeFlyoutHandler(event)
                            : () => {
                                changeViewHandler("category-selection", "log-category-search");
                                dispatch(resetLogCategoryCourses());
                            }, placeholder: undefined }, { children: translate(flyoutView === "category-selection"
                            ? "app.cohort.secondaryButtonText"
                            : "app.programApplications.component.footer.back.label") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: flyoutView === "category-selection" ? "next-btn" : "add-btn", onClick: () => {
                            flyoutView === "category-selection"
                                ? nextHandler()
                                : addLogCategoryHandler();
                        }, placeholder: undefined }, { children: translate(flyoutView === "category-selection"
                            ? "global.next"
                            : "app.requirement.add") }))] }))) : (_jsx(WmButton, Object.assign({ buttonType: "primary", id: "close-btn", onClick: (event) => closeFlyoutHandler(event), placeholder: undefined }, { children: translate("app.studentWorkflow.flyout.closeButton") }))) })));
    };
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "log-category-flyout", tabIndex: -1, "aria-label": "Select Log Category" }, { children: [_jsx("div", { className: "overlay" }), _jsx("div", Object.assign({ className: "container" }, { children: isFetchingLogCategories ? (_jsx("div", Object.assign({ className: "cohort-loader" }, { children: _jsx("div", Object.assign({ className: "content content-loader", role: "alert" }, { children: "Loading..." })) }))) : (_jsxs(_Fragment, { children: [renderHeader(), renderMainContent(), renderFooter()] })) }))] })));
}
