var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_APPLICATION_ROOT, API_ROOT } from "../../env-config";
import { fetchWrapper } from "../common/fetchWrapper";
import { isNullOrEmpty } from "../../static/js/commons";
export const getOrganizationAndChildOrganizations = createAsyncThunk("/getOrganizationAndChildOrganizations", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/organizations`, {}, null);
    return data ? data : error;
}));
export const saveTemplate = createAsyncThunk("/saveTemplate", ({ orgUuid, title, status, templateComponentList, copiedFromTemplateUuid, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/templates/save`, {}, {
        organizationUuid: orgUuid,
        title: title,
        status: status,
        templateComponentList: templateComponentList,
        copiedFromTemplateUuid: copiedFromTemplateUuid,
    });
    return data ? data : error;
}));
export const updateTemplate = createAsyncThunk("/updateTemplate", ({ uuid, orgUuid, status, title, templateComponentList }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_APPLICATION_ROOT}api/templates/update`, {}, {
        uuid: uuid,
        organizationUuid: orgUuid,
        title: title,
        status: status,
        templateComponentList: templateComponentList,
    });
    return data ? data : error;
}));
export const getAllApplicationTemplates = createAsyncThunk("/getAllApplicationTemplates", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/templates`, {}, null);
    return data ? data : error;
}));
export const getAllApplicationTemplatesForOrg = createAsyncThunk("/getAllApplicationTemplatesForOrg", ({ orgUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/organization/${orgUuid}/templates`, {}, null);
    return data ? data : error;
}));
export const getAllApplicationTemplatesForExtApp = createAsyncThunk("/getAllApplicationTemplatesForExtApp", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/templates`, {}, null);
    return data ? data : error;
}));
export const getAllApplicationComponents = createAsyncThunk("/getAllApplicationComponents", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/components`, {}, null);
    return data ? data : error;
}));
export const saveApplication = createAsyncThunk("/saveApplication", ({ title, description, organizationUuid, status, applicationTemplates, applicationSchedulesList, type, admissionTerm, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    let scheduleList = applicationSchedulesList.map((schedule) => {
        let startDateTime = new Date(schedule.startDate);
        let startUTCDateTime = new Date(Date.UTC(startDateTime.getUTCFullYear(), startDateTime.getUTCMonth(), startDateTime.getUTCDate(), startDateTime.getUTCHours(), startDateTime.getUTCMinutes()));
        let endDateTime = new Date(schedule.endDate);
        let endUTCDateTime = new Date(Date.UTC(endDateTime.getUTCFullYear(), endDateTime.getUTCMonth(), endDateTime.getUTCDate(), endDateTime.getUTCHours(), endDateTime.getUTCMinutes()));
        return Object.assign(Object.assign({}, schedule), { startDate: startUTCDateTime, endDate: endUTCDateTime });
    });
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/applications/save`, {}, {
        title: title,
        description: description,
        organizationUuid: organizationUuid,
        status: status,
        applicationTemplates: applicationTemplates,
        applicationSchedulesList: scheduleList,
        type,
        admissionTerm,
    });
    if (error && error === "duplicate") {
        return thunkAPI.rejectWithValue("duplicate");
    }
    return data ? data : error;
}));
export const updateApplication = createAsyncThunk("/updateApplication", ({ uuid, title, description, organizationUuid, status, applicationTemplates, applicationSchedulesList, type, admissionTerm, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    let scheduleList = applicationSchedulesList.map((schedule) => {
        let startDateTime = new Date(schedule.startDate ? schedule.startDate : "");
        let startUTCDateTime = new Date(Date.UTC(startDateTime.getUTCFullYear(), startDateTime.getUTCMonth(), startDateTime.getUTCDate(), startDateTime.getUTCHours(), startDateTime.getUTCMinutes()));
        let endDateTime = new Date(schedule.endDate ? schedule.endDate : "");
        let endUTCDateTime = new Date(Date.UTC(endDateTime.getUTCFullYear(), endDateTime.getUTCMonth(), endDateTime.getUTCDate(), endDateTime.getUTCHours(), endDateTime.getUTCMinutes()));
        return Object.assign(Object.assign({}, schedule), { startDate: startUTCDateTime, endDate: endUTCDateTime });
    });
    const [data, error] = yield fetchWrapper("PUT", `${API_APPLICATION_ROOT}api/applications/${uuid}/update`, {}, {
        uuid: uuid,
        title: title,
        description: description,
        organizationUuid: organizationUuid,
        status: status,
        applicationTemplates: applicationTemplates,
        applicationSchedulesList: scheduleList,
        type,
        admissionTerm,
    });
    if (error && error === "duplicate") {
        return thunkAPI.rejectWithValue("duplicate");
    }
    return data ? data : error;
}));
export const getAllApplications = createAsyncThunk("/getAllApplications", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/applications`, {}, null);
    if (error) {
        throw error;
    }
    return data;
}));
export const getLPUsage = createAsyncThunk("/getLPUsage", ({ applicationUuids }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/pa/applications/is-used`, {}, { applicationUuids });
    return data ? data : error;
}));
export const getApplication = createAsyncThunk("/getApplication", ({ applicationUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}`, {}, null);
    return data ? data : error;
}));
export const getApplicationTemplate = createAsyncThunk("/getApplicationTemplate", ({ templateUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/templates/${templateUuid}`, {}, null);
    return data ? data : error;
}));
export const updateApplicationStatus = createAsyncThunk("/updateApplicationStatus", ({ uuid, status }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_APPLICATION_ROOT}api/applications/${uuid}/status-update/${status}`, {}, null);
    return data ? data : error;
}));
export const getAllStudentApplications = createAsyncThunk("/getAllStudentApplications", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/student/applications`, {}, null);
    return data ? data : error;
}));
export const getStudentApplication = createAsyncThunk("/getStudentApplication", ({ applicationUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/student-application`, {}, null);
    return data ? data : error;
}));
export const saveStudentApplication = createAsyncThunk("/saveStudentApplication", ({ applicationUuid, payload }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/response`, {}, Object.assign({}, payload));
    return data ? data : error;
}));
export const updateStudentApplicationResponse = createAsyncThunk("/updateStudentApplicationResponse", ({ applicationUuid, responseUuid, payload }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/response/${responseUuid}`, {}, Object.assign({}, payload));
    return data ? data : error;
}));
export const getStudentResponses = createAsyncThunk("/getStudentResponses", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/applications/student-responses`, {}, null);
    return data ? data : error;
}));
export const getSubmissionStatusCount = createAsyncThunk("/getSubmissionStatusCount", ({ applicationUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/submissions`, {}, null);
    return data ? data : error;
}));
export const getStudentSubmissionRecord = createAsyncThunk("/getStudentSubmissionRecord", ({ studentUuid, applicationUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/students/${studentUuid}/submission-records`, {}, null);
    return data ? data : error;
}));
export const updateStudentSubmissionStatus = createAsyncThunk("/updateStudentSubmissionStatus", ({ studentUuid, applicationUuid, applicationStatus, allowResubmission, noteForStudent, resubmissionLink, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/students/${studentUuid}/status`, {}, {
        applicationStatus: applicationStatus,
        allowResubmission,
        noteForStudent,
        resubmissionLink,
    });
    return data ? data : error;
}));
export const getAllOpenApplications = createAsyncThunk("/getAllOpenApplications", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/openApplications`, {}, null);
    return data ? data : error;
}));
export const updateSchedule = createAsyncThunk("/updateSchedule", ({ applicationUuid, scheduleUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/schedule/${scheduleUuid}`, {}, null);
    return data ? data : error;
}));
export const getStudentSubmissionsLP = createAsyncThunk("/getStudentSubmissionsLP", ({ applicationUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/pa/application/${applicationUuid}/submissions`, {}, null);
    return data ? data : error;
}));
export const getTimelineApplications = createAsyncThunk("/getTimelineApplications", (applicationIds, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/timeline-applications`, {}, { applicationIds: applicationIds });
    return data ? data : error;
}));
export const deleteApplication = createAsyncThunk("/deleteApplication", ({ uuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("DELETE", `${API_APPLICATION_ROOT}api/applications/${uuid}`, {}, null);
    return data ? data : error;
}));
export const countAssociatedTemplates = createAsyncThunk("/countAssociatedTemplates", (templateUuid, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/templates/${templateUuid}/count`, {}, null);
    return data ? data : error;
}));
export const deleteApplicationTemplate = createAsyncThunk("/deleteApplicationTemplate", ({ templateUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("DELETE", `${API_APPLICATION_ROOT}api/templates/${templateUuid}`, {}, null);
    return data ? data : error;
}));
export const changeTemplateStatus = createAsyncThunk("/changeTemplateStatus", ({ templateUuid, status }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_APPLICATION_ROOT}api/templates/${templateUuid}/change-status`, {}, {
        uuid: templateUuid,
        status: status,
    });
    return data ? data : error;
}));
export const getOpenApplicationsList = createAsyncThunk("/getOpenApplicationsList", ({ uuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/templates/${uuid}/applications-list`, {}, null);
    return data ? data : error;
}));
export const saveInternalAdminNote = createAsyncThunk("/saveInternalAdminNote", ({ applicationUuid, studentUuid, studentApplicationUuid, text }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/students/${studentUuid}/note`, {}, {
        studentApplicationUuid,
        text,
    });
    return data ? data : error;
}));
export const getApplicationExternal = createAsyncThunk("/getApplicationExternal", ({ applicationUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/public/applications/${applicationUuid}`, {}, null);
    return data ? data : error;
}));
export const saveStudentApplicationExternal = createAsyncThunk("/saveStudentApplicationExternal", ({ captchaToken, applicationUuid, payload }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/public/applications/${applicationUuid}/response`, {}, Object.assign(Object.assign({}, payload), { captchaToken }));
    if (error && error === "invalid_captcha") {
        return thunkAPI.rejectWithValue("invalid_captcha");
    }
    return data ? data : error;
}));
export const getTermsExternal = createAsyncThunk("/getTermsExternal", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/public/terms`, {}, null);
    return data ? data : error;
}));
export const getProbableMatches = createAsyncThunk("/getProbableMatches", ({ applicationUuid, studentAppUuids }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/student-applications/probable-matches`, {}, {
        studentApplicationUuids: studentAppUuids,
    });
    return data ? data : error;
}));
export const searchUsers = createAsyncThunk("/searchUsers", ({ searchCriteria, signal, appUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/application/${appUuid}/users/search`, {}, {
        searchCriteria,
    }, signal);
    if (error) {
        return thunkAPI.rejectWithValue(error);
    }
    return data ? data : error;
}));
export const saveMatchedRecords = createAsyncThunk("/saveMatchedRecords", ({ applicationUuid, externalStudentApplications }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/student-applications/merge`, {}, {
        externalStudentApplications: externalStudentApplications,
    });
    return data ? data : error;
}));
export const getStudentApplicationExternal = createAsyncThunk("/getStudentApplicationExternal", ({ applicationUuid, studentApplicationUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/public/applications/${applicationUuid}/responses/${studentApplicationUuid}`, {}, null);
    return data ? data : error;
}));
export const updateStudentApplicationResponseExternal = createAsyncThunk("/updateStudentApplicationResponseExternal", ({ applicationUuid, responseUuid, payload }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_APPLICATION_ROOT}api/public/applications/${applicationUuid}/responses/${responseUuid}`, {}, Object.assign({}, payload));
    return data ? data : error;
}));
export const exportInternalApp = createAsyncThunk("/exportInternalApp", ({ applicationUuid, cohortUuids }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/download-csv`, {}, { cohortUuids: cohortUuids }, undefined, false);
    if (error) {
        throw error;
    }
    return data;
}));
export const exportExternalApp = createAsyncThunk("/exportExternalApp", ({ applicationUuid, termUuids }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/download-csv-external`, {}, { termUuids: termUuids }, undefined, false);
    if (error) {
        throw error;
    }
    return data;
}));
export const getTerms = createAsyncThunk("/getTerms", ({ orgUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/organizations/${orgUuid}/terms`, {}, null);
    return data ? data : error;
}));
export const getZipFileByExtractId = createAsyncThunk("/getZipFileByExtractId", ({ applicationUuid, extractUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_APPLICATION_ROOT}api/applications/${applicationUuid}/zip-extract/${extractUuid}`, {}, null);
    return data ? data : error;
}));
export const initialState = {
    isFetchingTerms: false,
    isCopyCompleted: true,
    isFetchingData: true,
    isFetchingApplications: true,
    isSavingApplication: true,
    isFetchingStudetApplicationData: true,
    isFetchingStudentResponseData: true,
    isFetchinStudentApplicationResponseData: true,
    isFetchingSubmissionCount: true,
    isFetchingStudentSubmissionRecord: false,
    isFetchingSchedules: true,
    isFetchingProbableMatches: false,
    snackNotifications: "[]",
    associatedTemplatesCount: null,
    organizationList: [],
    applicationTemplate: {},
    applicationTemplateList: [],
    applicationTemplateListPerOrg: [],
    applicationComponentList: [],
    updatedComponentsList: [],
    applicationTemplateComponentsList: [],
    isEditComponentClicked: false,
    applicationObj: {},
    applicationsList: [],
    saveApplicationFulfilled: false,
    saveApplicationRejected: false,
    duplicateApplication: false,
    studentApplicationList: [],
    studentResponse: {},
    studentResponseSnapshot: {},
    studentApplicationResponseList: [],
    applicationSubmissionList: [],
    awaitingReviewSubmissionList: [],
    acceptedSubmissionList: [],
    conditionalSubmissionList: [],
    deniedSubmissionList: [],
    applicationSubmissionObj: {},
    studentApplicationSubmission: {},
    openApplicationsList: [],
    submissionsLP: [],
    applicationSchedules: {},
    applicationSchedulesList: [],
    appStatusFilter: [],
    templateStatusFilter: [],
    isFetchingApplicationSchedule: false,
    timelineApplications: [],
    applicationsForSpecificTemplate: [],
    blockMovement: false,
    ariaMoveText: "",
    appAudienceFilter: [],
    termList: [],
    invalidCaptcha: false,
    showMessage: false,
    openMergeScreen: false,
    probableMatches: {},
    probableMatchRecord: {},
    searchRecords: [],
    isRecordMerging: false,
    isFetchingSearchRecords: false,
    internalAppExportDetailsCSV: null,
    exportInternalAppRejected: false,
    externalAppExportDetailsCSV: null,
    exportExternalAppRejected: false,
    isFetchingExtracts: false,
    applicationExtracts: {},
};
export const applicationSlice = createSlice({
    name: "applicationSliceData",
    initialState,
    reducers: {
        closeSnackbar: (state, { payload }) => {
            const snackList = JSON.parse(state.snackNotifications);
            const snackIndex = snackList.findIndex((snack) => snack.id === payload);
            snackList.splice(snackIndex, 1);
            state.snackNotifications = JSON.stringify(snackList);
        },
        updateComponentDetails: (state, { payload }) => {
            state.applicationTemplateComponentsList.splice(payload.sortOrder, 1, payload);
        },
        setIsEditComponentClicked: (state, { payload }) => {
            state.isEditComponentClicked = payload;
        },
        setComponentDetails: (state, { payload }) => {
            var _a;
            if (((_a = state.applicationTemplateComponentsList) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                state.applicationTemplateComponentsList.splice(payload.sortOrder, 0, payload);
                let componentList = state.applicationTemplateComponentsList;
                componentList = componentList.map((template, index) => {
                    template.sortOrder = index;
                    return template;
                });
                state.applicationTemplateComponentsList = componentList;
            }
            else {
                state.applicationTemplateComponentsList = [payload];
            }
        },
        placeComponentToNewPosition: (state, { payload }) => {
            var _a;
            let source = Number(payload.source);
            let dest = Number(payload.dest);
            if (dest < source) {
                dest = payload.insertBefore ? dest : dest + 1;
            }
            if (((_a = state.applicationTemplateComponentsList) === null || _a === void 0 ? void 0 : _a.length) > 1 &&
                source != dest) {
                let template = state.applicationTemplateComponentsList.splice(source, 1);
                state.applicationTemplateComponentsList.splice(dest, 0, template[0]);
                let componentList = state.applicationTemplateComponentsList;
                componentList = componentList.map((template, index) => {
                    template.sortOrder = index;
                    return template;
                });
                state.applicationTemplateComponentsList = componentList;
            }
        },
        placeComponentToNewPositionKeyboard: (state, { payload }) => {
            var _a;
            let { source, keyCode, movedToPosition, component } = payload;
            let finalComponent = {};
            if (isNullOrEmpty(component === null || component === void 0 ? void 0 : component.uuid)) {
                let unsavedComp = Object.assign(Object.assign({}, component), { uuid: source });
                component = unsavedComp;
            }
            const updateComponentSortOrder = (newIndex) => {
                let comp = state.applicationTemplateComponentsList.splice(component.sortOrder, 1);
                state.applicationTemplateComponentsList.splice(newIndex, 0, comp[0]);
                let componentList = state.applicationTemplateComponentsList;
                componentList = componentList.map((templateComp, index) => {
                    templateComp.sortOrder = index;
                    if (templateComp.uuid === component.uuid) {
                        finalComponent = templateComp;
                    }
                    return templateComp;
                });
                state.applicationTemplateComponentsList = componentList;
                state.ariaMoveText =
                    finalComponent.componentType +
                        " " +
                        (isNullOrEmpty(finalComponent === null || finalComponent === void 0 ? void 0 : finalComponent.componentLabel)
                            ? finalComponent === null || finalComponent === void 0 ? void 0 : finalComponent.inputText
                            : finalComponent === null || finalComponent === void 0 ? void 0 : finalComponent.componentLabel) +
                        " " +
                        movedToPosition +
                        " " +
                        (finalComponent.sortOrder + 1);
            };
            if (((_a = state.applicationTemplateComponentsList) === null || _a === void 0 ? void 0 : _a.length) > 1) {
                let componentList = state.applicationTemplateComponentsList;
                componentList = componentList.map((templateComp) => {
                    if (isNullOrEmpty(templateComp === null || templateComp === void 0 ? void 0 : templateComp.uuid)) {
                        let unsavedTemplateComp = Object.assign(Object.assign({}, templateComp), { uuid: templateComp.componentType +
                                (isNullOrEmpty(templateComp.componentLabel)
                                    ? templateComp.inputText
                                    : templateComp.componentLabel) +
                                templateComp.sortOrder });
                        templateComp = unsavedTemplateComp;
                    }
                    return templateComp;
                });
                state.applicationTemplateComponentsList = componentList;
                if (keyCode === 38) {
                    if ((component === null || component === void 0 ? void 0 : component.sortOrder) && (component === null || component === void 0 ? void 0 : component.sortOrder) > 0) {
                        updateComponentSortOrder(component.sortOrder - 1);
                    }
                }
                else if (keyCode === 40) {
                    if (component.sortOrder <
                        state.applicationTemplateComponentsList.length - 1) {
                        updateComponentSortOrder(component.sortOrder + 1);
                    }
                }
            }
            state.blockMovement = false;
        },
        setComponentsListData: (state, { payload }) => {
            if (payload.reset) {
                state.applicationTemplateComponentsList =
                    state.updatedComponentsList.map((li) => {
                        return li;
                    });
            }
            else {
                state.updatedComponentsList =
                    state.applicationTemplateComponentsList.map((li) => {
                        return li;
                    });
            }
        },
        setAriaMoveText: (state, { payload }) => {
            state.ariaMoveText = payload.text;
        },
        setBlockMovement: (state, { payload }) => {
            state.blockMovement = payload.val;
        },
        removeComponent: (state, { payload }) => {
            state.applicationTemplateComponentsList.splice(payload.position, 1);
            if (state.applicationTemplateComponentsList.length > 0) {
                let componentList = state.applicationTemplateComponentsList;
                componentList = componentList.map((template, index) => {
                    template.sortOrder = index;
                    return template;
                });
                state.applicationTemplateComponentsList = componentList;
            }
        },
        resetApplicationTemplateComponentsList: (state, { payload }) => {
            state.applicationTemplateComponentsList = payload;
        },
        setApplicationTemplate: (state, { payload }) => {
            state.applicationTemplate = payload;
        },
        resetFlags: (state) => {
            state.saveApplicationFulfilled = false;
            state.saveApplicationRejected = false;
            state.duplicateApplication = false;
        },
        updateStudentApplication: (state, { payload }) => {
            var _a;
            let { uuid, templateUuid, templateComponentUuid, responseText, linkTitle, multiselectOptions, attachedFiles, deletedFiles, isAcknowledged, admissionTermUuid, firstName, lastName, email, } = payload;
            let optionalProps = {};
            optionalProps = Object.assign(Object.assign({}, optionalProps), { linkTitle });
            optionalProps = Object.assign(Object.assign({}, optionalProps), { isAcknowledged });
            if (attachedFiles) {
                optionalProps = Object.assign(Object.assign({}, optionalProps), { files: attachedFiles });
            }
            if (deletedFiles) {
                optionalProps = Object.assign(Object.assign({}, optionalProps), { deletedFiles });
            }
            if (multiselectOptions) {
                optionalProps = Object.assign(Object.assign({}, optionalProps), { multiselectOptions });
            }
            let index = isNullOrEmpty(templateUuid) && isNullOrEmpty(templateComponentUuid)
                ? -2
                : (_a = state.studentResponse.responses) === null || _a === void 0 ? void 0 : _a.findIndex((obj) => obj.templateUuid === templateUuid &&
                    obj.templateComponentUuid === templateComponentUuid);
            if (index === -1 || index === undefined) {
                state.studentResponse.responses = state.studentResponse.responses || [];
                state.studentResponse.responses.push(Object.assign({ uuid,
                    templateUuid,
                    templateComponentUuid,
                    responseText }, optionalProps));
            }
            else if (index > -1) {
                state.studentResponse.responses[index] = Object.assign(Object.assign(Object.assign({}, state.studentResponse.responses[index]), { responseText }), optionalProps);
            }
            else if (index == -2) {
                if (admissionTermUuid || admissionTermUuid == "") {
                    state.studentResponse.admissionTermUuid = admissionTermUuid;
                }
                else {
                    state.studentResponse.firstName = firstName;
                    state.studentResponse.lastName = lastName;
                    state.studentResponse.email = email;
                }
            }
        },
        setSnackNotifications: (state) => {
            state.snackNotifications = "[]";
        },
        resetApplicationsList: (state) => {
            state.isFetchingSubmissionCount = true;
            state.applicationSubmissionList = [];
        },
        removeFileFromApplicationSubmissionList: (state, { payload }) => {
            state.applicationSubmissionList.map((submission) => {
                if (submission.studentApplicationUuid === payload.uuid) {
                    submission.files = payload.files;
                }
            });
        },
        resetAwaitingReviewSubmissionList: (state) => {
            state.awaitingReviewSubmissionList = [];
        },
        initApplicationSchedule: (state, { payload }) => {
            state.applicationSchedulesList = payload.applicationSchedules;
        },
        saveApplicationSchedule: (state, { payload }) => {
            state.applicationSchedulesList.push(payload);
        },
        updateApplicationSchedule: (state, { payload }) => {
            const updatedSchedule = state.applicationSchedulesList.splice(payload.currentScheduleId, 1);
            state.applicationSchedulesList.splice(payload.currentScheduleId, 0, Object.assign(Object.assign({}, updatedSchedule[0]), { name: payload.name, startDate: payload.startDate, endDate: payload.endDate }));
        },
        resetApplicationSchedule: (state) => {
            state.applicationSchedulesList = [];
        },
        removeSchedule: (state, { payload }) => {
            state.applicationSchedulesList.splice(payload.scheduleId, 1);
        },
        resetApplicationObj: (state) => {
            state.applicationObj = {};
        },
        copyUrlSnack: (state) => {
            notifications(state, "", "Application URL was copied.", "");
        },
        mergeRecordSnack: (state, { payload }) => {
            notifications(state, { title: payload.verifiedCount }, " student records are being merged.", "");
        },
        noRecordMergedSnack: (state) => {
            notifications(state, "", "No available system matches merged.", "");
        },
        mergeSingleRecordSnack: (state) => {
            notifications(state, "", "Student record has been merged.", "");
        },
        swapRecordSnack: (state) => {
            notifications(state, "", "System Administrator match being swapped.", "");
        },
        setProbableMatchRecord: (state, { payload }) => {
            state.probableMatchRecord = payload;
        },
        updateCurrentMatchResultForStudentApplication: (state, { payload }) => {
            var _a, _b;
            const { studentApplicationUuid, currentMatch } = payload;
            const originalMatchUuid = ((_b = (_a = state.probableMatches[studentApplicationUuid]) === null || _a === void 0 ? void 0 : _a.originalMatch) === null || _b === void 0 ? void 0 : _b.uuid) ||
                "";
            const newMatchUuid = (currentMatch === null || currentMatch === void 0 ? void 0 : currentMatch.uuid) || "";
            state.probableMatches[studentApplicationUuid] = Object.assign(Object.assign({}, state.probableMatches[studentApplicationUuid]), { currentMatch: newMatchUuid ? currentMatch : null, status: "UNVERIFIED", isUserUpdated: originalMatchUuid !== newMatchUuid });
            state.probableMatches = refreshProbableMatchesForList(state.probableMatches);
        },
        updateStudentMatchVerifiedStatus: (state, { payload }) => {
            const { studentApplicationUuid, status } = payload;
            if (studentApplicationUuid &&
                status &&
                state.probableMatches[studentApplicationUuid]) {
                state.probableMatches[studentApplicationUuid].status = status;
            }
        },
    },
    extraReducers: {
        "/getTerms/pending": (state, { payload }) => {
            state.isFetchingTerms = true;
        },
        "/getTerms/fulfilled": (state, { payload }) => {
            state.isFetchingTerms = false;
            state.termList = payload;
        },
        "/getOrganizationAndChildOrganizations/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/getOrganizationAndChildOrganizations/fulfilled": (state, { payload }) => {
            state.organizationList = payload;
            state.isFetchingData = false;
        },
        "/saveTemplate/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/saveTemplate/fulfilled": (state, { payload }) => {
            state.applicationTemplate = payload.template;
            state.isFetchingData = false;
            notifications(state, { title: payload.template.title }, " has been created.", "");
            return state;
        },
        "/updateTemplate/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/updateTemplate/fulfilled": (state, { payload }) => {
            state.applicationTemplate = payload.template;
            state.isFetchingData = false;
            return state;
        },
        "/getAllApplicationTemplates/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/getAllApplicationTemplates/fulfilled": (state, { payload }) => {
            state.applicationTemplateList = payload;
            state.isFetchingData = false;
            state.templateStatusFilter = Array.from(new Set(state.applicationTemplateList.map((template) => template.template.status))).sort();
        },
        "/getAllApplicationTemplatesForOrg/pending": (state, { payload }) => {
            state.applicationTemplateListPerOrg = [];
        },
        "/getAllApplicationTemplatesForOrg/fulfilled": (state, { payload }) => {
            state.applicationTemplateListPerOrg = payload;
        },
        "/getAllApplicationComponents/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/getAllApplicationComponents/fulfilled": (state, { payload }) => {
            state.applicationComponentList = payload;
            state.isFetchingData = false;
        },
        "/saveApplication/pending": (state, { payload }) => {
            state.isSavingApplication = true;
            state.saveApplicationFulfilled = false;
            state.saveApplicationRejected = false;
            state.duplicateApplication = false;
        },
        "/saveApplication/fulfilled": (state, { payload }) => {
            state.isSavingApplication = false;
            state.saveApplicationFulfilled = true;
            state.applicationObj = payload;
        },
        "/saveApplication/rejected": (state, { payload }) => {
            state.isSavingApplication = false;
            state.saveApplicationRejected = true;
            if (payload === "duplicate")
                state.duplicateApplication = true;
        },
        "/getAllApplications/pending": (state, { payload }) => {
            state.isFetchingApplications = true;
        },
        "/getAllApplications/fulfilled": (state, { payload }) => {
            let listOfApplicationsWithSubmissions;
            let awatingReviewList;
            let acceptedList;
            let conditionalList;
            let deniedList;
            listOfApplicationsWithSubmissions = payload;
            state.applicationsList = [];
            state.applicationsList = listOfApplicationsWithSubmissions === null || listOfApplicationsWithSubmissions === void 0 ? void 0 : listOfApplicationsWithSubmissions.map((application) => {
                var _a, _b;
                awatingReviewList = [];
                acceptedList = [];
                conditionalList = [];
                deniedList = [];
                (_b = (_a = application.applicationDetailsResponse) === null || _a === void 0 ? void 0 : _a.applicationSubmissionList) === null || _b === void 0 ? void 0 : _b.forEach((appSubmission) => {
                    switch (appSubmission.applicationStatus) {
                        case "AWAITING_REVIEW":
                            awatingReviewList.push(appSubmission);
                            break;
                        case "ACCEPTED":
                            acceptedList.push(appSubmission);
                            break;
                        case "CONDITIONALLY_ACCEPTED":
                            conditionalList.push(appSubmission);
                            break;
                        case "DENIED":
                            deniedList.push(appSubmission);
                            break;
                    }
                });
                application.awaitingReviewSubmissionList = awatingReviewList.sort((a, b) => {
                    return (a.lastName + "," + a.firstName).localeCompare(b.lastName + "," + b.firstName);
                });
                application.acceptedSubmissionList = acceptedList;
                application.conditionalSubmissionList = conditionalList;
                application.deniedSubmissionList = deniedList;
                return application;
            });
            state.appStatusFilter = Array.from(new Set(state.applicationsList.map((app) => app.status)))
                .sort()
                .reverse();
            state.appAudienceFilter = Array.from(new Set(state.applicationsList.map((app) => app.type)))
                .sort()
                .reverse();
            state.isFetchingApplications = false;
        },
        "/getApplication/pending": (state, { payload }) => {
            state.isFetchingData = true;
            state.applicationObj = {};
        },
        "/getApplication/fulfilled": (state, { payload }) => {
            state.applicationObj = payload;
            state.applicationSchedulesList = payload.applicationSchedules;
            state.isFetchingData = false;
        },
        "/getAllStudentApplications/pending": (state, { payload }) => {
            state.isFetchingStudetApplicationData = true;
            state.studentApplicationList = [];
        },
        "/getAllStudentApplications/fulfilled": (state, { payload }) => {
            var _a;
            let applicationList = new Array();
            applicationList = payload;
            state.studentApplicationList = applicationList === null || applicationList === void 0 ? void 0 : applicationList.sort((a, b) => {
                return a.title.localeCompare(b.title);
            });
            if (((_a = state.studentApplicationList) === null || _a === void 0 ? void 0 : _a.length) === (applicationList === null || applicationList === void 0 ? void 0 : applicationList.length)) {
                state.isFetchingStudetApplicationData = false;
            }
        },
        "/getApplicationTemplate/pending": (state, { payload }) => {
            state.isFetchingData = true;
            state.isFetchingApplications = true;
        },
        "/getApplicationTemplate/fulfilled": (state, { payload }) => {
            var _a;
            state.applicationTemplate = payload.template;
            state.applicationTemplateComponentsList =
                (_a = payload.template) === null || _a === void 0 ? void 0 : _a.templateComponentList;
            state.isFetchingData = false;
            state.isFetchingApplications = false;
        },
        "/updateApplicationStatus/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/updateApplicationStatus/fulfilled": (state, { payload }) => {
            let index = state.applicationsList.findIndex((app) => app.uuid === payload.uuid);
            state.applicationsList[index] = payload;
            state.isFetchingData = false;
        },
        "/getStudentApplication/pending": (state, { payload }) => {
            state.isFetchingStudentResponseData = true;
        },
        "/getStudentApplication/fulfilled": (state, { payload }) => {
            state.studentResponse =
                payload.uuid === undefined ? {} : payload;
            state.studentResponseSnapshot = state.studentResponse;
            state.isFetchingStudentResponseData = false;
        },
        "/saveStudentApplication/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/saveStudentApplication/fulfilled": (state, { payload }) => {
            state.isFetchingData = false;
        },
        "/getStudentResponses/pending": (state, { payload }) => {
            state.isFetchinStudentApplicationResponseData = true;
        },
        "/getStudentResponses/fulfilled": (state, { payload }) => {
            let studentApplicationResponseList = new Array();
            studentApplicationResponseList = payload;
            state.studentApplicationResponseList =
                studentApplicationResponseList === null || studentApplicationResponseList === void 0 ? void 0 : studentApplicationResponseList.sort((a, b) => a.application.title.localeCompare(b.application.title));
            if (studentApplicationResponseList.length ===
                state.studentApplicationResponseList.length) {
                state.isFetchinStudentApplicationResponseData = false;
            }
        },
        "/updateStudentApplicationResponse/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/updateStudentApplicationResponse/fulfilled": (state, { payload }) => {
            state.studentResponse = payload;
            state.isFetchingData = false;
        },
        "getSubmissionStatusCount/pending": (state, { payload }) => {
            state.isFetchingSubmissionCount = true;
        },
        "/getSubmissionStatusCount/fulfilled": (state, { payload }) => {
            var _a, _b, _c, _d;
            state.applicationSubmissionList = payload.applicationSubmissionList;
            (state.awaitingReviewSubmissionList = []),
                (state.acceptedSubmissionList = []),
                (state.conditionalSubmissionList = []),
                (state.deniedSubmissionList = []),
                (state.applicationSubmissionObj = payload);
            (_b = (_a = state.applicationSubmissionObj) === null || _a === void 0 ? void 0 : _a.applicationSubmissionList) === null || _b === void 0 ? void 0 : _b.forEach((appSubmission, index) => {
                switch (appSubmission.applicationStatus) {
                    case "AWAITING_REVIEW":
                        state.awaitingReviewSubmissionList.push(appSubmission);
                        break;
                    case "ACCEPTED":
                        state.acceptedSubmissionList.push(appSubmission);
                        break;
                    case "CONDITIONALLY_ACCEPTED":
                        state.conditionalSubmissionList.push(appSubmission);
                        break;
                    case "DENIED":
                        state.deniedSubmissionList.push(appSubmission);
                        break;
                }
                if (state.applicationSubmissionObj.applicationSubmissionList.length -
                    1 ===
                    index) {
                    state.awaitingReviewSubmissionList.sort((a, b) => {
                        return (a.lastName + "," + a.firstName).localeCompare(b.lastName + "," + b.firstName);
                    });
                    state.isFetchingSubmissionCount = false;
                }
            });
            if (((_d = (_c = state.applicationSubmissionObj) === null || _c === void 0 ? void 0 : _c.applicationSubmissionList) === null || _d === void 0 ? void 0 : _d.length) === 0) {
                state.isFetchingSubmissionCount = false;
            }
        },
        "/getStudentSubmissionRecord/pending": (state, { payload }) => {
            state.isFetchingStudentSubmissionRecord = true;
        },
        "/getStudentSubmissionRecord/fulfilled": (state, { payload }) => {
            state.studentApplicationSubmission = payload;
            state.isFetchingStudentSubmissionRecord = false;
        },
        "/updateStudentSubmissionStatus/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/updateStudentSubmissionStatus/fulfilled": (state, { payload }) => {
            state.studentApplicationSubmission = payload;
            state.isFetchingData = false;
            if (state.applicationSubmissionList) {
                state.applicationSubmissionList.map((obj) => {
                    if (obj.studentApplicationUuid ===
                        state.studentApplicationSubmission.studentApplicationUuid) {
                        obj.applicationStatus =
                            state.studentApplicationSubmission.applicationStatus;
                    }
                    return obj;
                });
            }
        },
        "/getAllOpenApplications/pending": (state, { payload }) => {
            state.isFetchingApplications = true;
        },
        "/getAllOpenApplications/fulfilled": (state, { payload }) => {
            state.openApplicationsList = payload;
            state.isFetchingApplications = false;
        },
        "/updateApplication/pending": (state, { payload }) => {
            state.isSavingApplication = true;
            state.saveApplicationFulfilled = false;
            state.saveApplicationRejected = false;
            state.duplicateApplication = false;
        },
        "/updateApplication/fulfilled": (state, { payload }) => {
            state.isSavingApplication = false;
            state.saveApplicationFulfilled = true;
            state.applicationObj = payload;
        },
        "/updateApplication/rejected": (state, { payload }) => {
            state.isSavingApplication = false;
            state.saveApplicationRejected = true;
            if (payload === "duplicate")
                state.duplicateApplication = true;
        },
        "/deleteApplication/pending": (state, { payload }) => { },
        "/deleteApplication/fulfilled": (state, { payload }) => {
            let index = state.applicationsList.findIndex((obj) => obj.uuid === payload);
            let deletedApps = state.applicationsList.splice(index, 1);
            notifications(state, { title: deletedApps[0].title }, " has been deleted.", "");
        },
        "/deleteApplicationTemplate/pending": (state, { payload }) => { },
        "/deleteApplicationTemplate/fulfilled": (state, { payload }) => {
            let index = state.applicationTemplateList.findIndex((obj) => obj.template.uuid === payload);
            let deletedTemplate = state.applicationTemplateList.splice(index, 1);
            notifications(state, { title: deletedTemplate[0].template.title }, " has been deleted.", "");
        },
        "/getStudentSubmissionsLP/pending": (state, { payload }) => {
            state.submissionsLP = [];
        },
        "/getStudentSubmissionsLP/fulfilled": (state, { payload }) => {
            state.submissionsLP = payload;
        },
        "/getTimelineApplications/pending": (state, { payload }) => {
            state.isFetchingApplicationSchedule = true;
        },
        "/getTimelineApplications/fulfilled": (state, { payload }) => {
            state.timelineApplications = payload;
            state.isFetchingApplicationSchedule = false;
        },
        "/countAssociatedTemplates/pending": (state, { payload }) => {
            state.isCopyCompleted = false;
            state.isFetchingData = true;
        },
        "/countAssociatedTemplates/fulfilled": (state, { payload }) => {
            state.associatedTemplatesCount = payload;
            state.isCopyCompleted = true;
            state.isFetchingData = false;
        },
        "/changeTemplateStatus/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/changeTemplateStatus/fulfilled": (state, { payload }) => {
            state.isFetchingData = false;
            state.applicationTemplate = payload;
        },
        "/getOpenApplicationsList": (state, { payload }) => {
            state.applicationsForSpecificTemplate = payload;
        },
        "/saveInternalAdminNote/pending": (state, { payload }) => { },
        "/saveInternalAdminNote/fulfilled": (state, { payload }) => {
            state.studentApplicationSubmission.adminInternalNotes.push(payload);
            state.studentApplicationSubmission.adminInternalNotes.sort((a, b) => {
                return (b === null || b === void 0 ? void 0 : b.createdAt).localeCompare(a === null || a === void 0 ? void 0 : a.createdAt);
            });
        },
        "/getAllApplicationTemplatesForExtApp/pending": (state, { payload }) => {
            state.applicationTemplateListPerOrg = [];
        },
        "/getAllApplicationTemplatesForExtApp/fulfilled": (state, { payload }) => {
            let templateList = payload;
            state.applicationTemplateListPerOrg = templateList.filter((temp) => {
                return temp.template.status !== "DRAFT";
            });
        },
        "/getApplicationExternal/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/getApplicationExternal/fulfilled": (state, { payload }) => {
            state.isFetchingData = false;
            state.isFetchingStudentResponseData = false;
            state.applicationObj = payload;
        },
        "/getTermsExternal/pending": (state, { payload }) => { },
        "/getTermsExternal/fulfilled": (state, { payload }) => {
            state.termList = payload;
        },
        "/saveStudentApplicationExternal/pending": (state, { payload }) => {
            state.isFetchingData = true;
            state.showMessage = false;
        },
        "/saveStudentApplicationExternal/fulfilled": (state, { payload }) => {
            state.isFetchingData = false;
            state.showMessage = true;
        },
        "/saveStudentApplicationExternal/rejected": (state, { payload }) => {
            state.isFetchingData = false;
            state.showMessage = false;
            if (payload === "invalid_captcha")
                state.invalidCaptcha = true;
        },
        "/getLPUsage/pending": (state, { payload }) => { },
        "/getLPUsage/fulfilled": (state, { payload }) => {
            let { applicationUuids } = payload;
            state.applicationsList.map((app) => {
                if (applicationUuids.includes(app.uuid))
                    app.applicationUsedInLicensurePlan = true;
                return app;
            });
        },
        "/getProbableMatches/pending": (state, { payload }) => {
            state.isFetchingProbableMatches = true;
            state.openMergeScreen = true;
        },
        "/getProbableMatches/fulfilled": (state, { payload }) => {
            state.isFetchingProbableMatches = false;
            const matchResponses = payload.probableMatches;
            const matchResults = {};
            const usersIdToExclude = [];
            Object.keys(matchResponses).map((key) => {
                const id = key;
                const originalMatches = matchResponses[key] || [];
                // filter probable matches
                const probableMatches = originalMatches.filter((matches) => !usersIdToExclude.includes(matches.uuid));
                const currentMatch = probableMatches[0] || null;
                if (currentMatch === null || currentMatch === void 0 ? void 0 : currentMatch.uuid) {
                    usersIdToExclude.push(currentMatch.uuid);
                }
                matchResults[id] = {
                    currentMatch,
                    originalMatch: currentMatch,
                    status: "UNVERIFIED",
                    probableMatches,
                    originalMatches,
                };
            });
            state.probableMatches = matchResults;
        },
        "/searchUsers/pending": (state, { payload }) => {
            state.isFetchingSearchRecords = true;
        },
        "/searchUsers/fulfilled": (state, { payload }) => {
            state.searchRecords = payload;
            state.isFetchingSearchRecords = false;
        },
        "/saveMatchedRecords/pending": (state, { payload }) => {
            state.isRecordMerging = true;
        },
        "/saveMatchedRecords/fulfilled": (state, { payload }) => {
            state.isRecordMerging = false;
        },
        "/getStudentApplicationExternal/pending": (state, { payload }) => {
            state.isFetchingStudentResponseData = true;
        },
        "/getStudentApplicationExternal/fulfilled": (state, { payload }) => {
            state.studentResponse =
                payload.uuid === undefined ? {} : payload;
            state.studentResponseSnapshot = state.studentResponse;
            state.isFetchingStudentResponseData = false;
        },
        "/updateStudentApplicationResponseExternal/pending": (state, { payload }) => {
            state.isFetchingData = true;
        },
        "/updateStudentApplicationResponseExternal/fulfilled": (state, { payload }) => {
            state.studentResponse = payload;
            state.isFetchingData = false;
            state.showMessage = true;
        },
        "/exportInternalApp/pending": (state, { payload }) => { },
        "/exportInternalApp/fulfilled": (state, { payload }) => {
            if (payload === null || payload === void 0 ? void 0 : payload.uuid) {
                state.applicationExtracts = payload;
            }
            else {
                state.internalAppExportDetailsCSV = payload;
            }
        },
        "/exportInternalApp/rejected": (state, { payload }) => {
            state.exportInternalAppRejected = true;
        },
        "/exportExternalApp/pending": (state, { payload }) => { },
        "/exportExternalApp/fulfilled": (state, { payload }) => {
            if (payload === null || payload === void 0 ? void 0 : payload.uuid) {
                state.applicationExtracts = payload;
            }
            else {
                state.internalAppExportDetailsCSV = payload;
            }
        },
        "/exportExternalApp/rejected": (state, { payload }) => {
            state.exportExternalAppRejected = true;
        },
        "/getZipFileByExtractId/pending": (state) => {
            state.isFetchingExtracts = true;
        },
        "/getZipFileByExtractId/fulfilled": (state, { payload }) => {
            state.isFetchingExtracts = false;
            state.applicationExtracts = payload;
        },
    },
});
const notifications = (state, payload, message, type) => {
    let notification = {
        id: (Math.floor(Math.random() * 100) + 1).toString(),
        message: (payload ? payload.title : "") + message,
        link: "",
    };
    let notificationList = JSON.parse(state.snackNotifications);
    notificationList.unshift(notification);
    state.snackNotifications = JSON.stringify(notificationList);
};
export default applicationSlice.reducer;
export const { closeSnackbar, setComponentDetails, resetApplicationTemplateComponentsList, updateComponentDetails, removeComponent, setIsEditComponentClicked, setApplicationTemplate, resetFlags, updateStudentApplication, setSnackNotifications, resetApplicationsList, removeFileFromApplicationSubmissionList, resetAwaitingReviewSubmissionList, saveApplicationSchedule, updateApplicationSchedule, resetApplicationSchedule, removeSchedule, resetApplicationObj, initApplicationSchedule, placeComponentToNewPosition, setBlockMovement, setAriaMoveText, placeComponentToNewPositionKeyboard, setComponentsListData, copyUrlSnack, setProbableMatchRecord, updateCurrentMatchResultForStudentApplication, mergeRecordSnack, swapRecordSnack, noRecordMergedSnack, mergeSingleRecordSnack, updateStudentMatchVerifiedStatus, } = applicationSlice.actions;
export const applicationSelector = (state) => state.applicationSliceData;
const refreshProbableMatchesForList = (probableMatches) => {
    const userToExcludeList = [];
    Object.keys(probableMatches).map((match) => {
        var _a, _b;
        probableMatches[match].probableMatches = (_a = probableMatches[match].originalMatches) === null || _a === void 0 ? void 0 : _a.filter((u) => !userToExcludeList.includes(u.uuid));
        const uuid = (_b = probableMatches[match].currentMatch) === null || _b === void 0 ? void 0 : _b.uuid;
        if (uuid) {
            userToExcludeList.push(uuid);
        }
    });
    return probableMatches;
};
