import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { WmButton } from "@watermarkinsights/ripple-react";
import LOGO from "../../../images/icons/favicon-96.png";
import { applicationSelector, exportExternalApp, exportInternalApp, getZipFileByExtractId, } from "../ApplicationSlice";
export const ApplicationExportPage = () => {
    const dispatch = useDispatch();
    const currentTabOpener = useRef(window.opener);
    const { applicationUuid, applicationType: type = "" } = useParams();
    document.title = "Exports | Watermark";
    const applicationType = type.toUpperCase();
    const preventUnload = useRef(false);
    const { internalAppExportDetailsCSV, exportInternalAppRejected, externalAppExportDetailsCSV, exportExternalAppRejected, applicationExtracts, } = useSelector(applicationSelector);
    const [extractInterval, setExtractInterval] = useState(null);
    const [appTitle, setAppTitle] = useState("");
    const primaryText = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.page.primary.text",
    });
    const errorPrimaryText = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.page.error.primary.text",
    });
    const secondaryText = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.page.secondary.text",
    });
    const errorSecondaryText = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.page.error.secondary.text",
    });
    const helpText = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.page.help.text",
    });
    const buttonText = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.cancel.export.text",
    });
    const handleUnload = () => {
        if (preventUnload.current) {
            return;
        }
        localStorage.removeItem("isExportTabOpen");
        localStorage.removeItem("selectedCohorts");
        localStorage.removeItem("selectedTerms");
        localStorage.removeItem("applicationTitle");
        currentTabOpener.current.postMessage("tabClosed", "*");
    };
    useEffect(() => {
        localStorage.setItem("isExportTabOpen", "true");
        if (window.opener) {
            currentTabOpener.current.postMessage("tabOpen", "*");
        }
        const selectedCohorts = JSON.parse(localStorage.getItem("selectedCohorts") || "[]");
        const selectedTerms = JSON.parse(localStorage.getItem("selectedTerms") || "[]");
        const title = localStorage.getItem("applicationTitle");
        setAppTitle(title !== null ? title : "");
        if (applicationType === "INTERNAL") {
            dispatch(exportInternalApp({
                applicationUuid,
                cohortUuids: selectedCohorts,
            }));
        }
        if (applicationType === "EXTERNAL") {
            dispatch(exportExternalApp({
                applicationUuid,
                termUuids: selectedTerms,
            }));
        }
        window.addEventListener("beforeunload", handleUnload);
        return () => window.removeEventListener("beforeunload", handleUnload);
    }, []);
    useEffect(() => {
        const exportData = () => {
            let dataUrl = null;
            if (applicationType === "INTERNAL") {
                dataUrl = applicationExtracts.preSignedUrl;
            }
            else if (applicationType === "EXTERNAL") {
                dataUrl = applicationExtracts.preSignedUrl;
            }
            if (dataUrl) {
                preventUnload.current = true;
                window.open(dataUrl, "_self");
                setTimeout(() => {
                    preventUnload.current = false;
                    window.close();
                }, 5000);
            }
        };
        if (applicationExtracts.status === "FAILED" ||
            applicationExtracts.status === "CANCELLED") {
            clearInterval(extractInterval);
        }
        else if (applicationExtracts.status == "PENDING") {
            const id = setInterval(() => {
                dispatch(getZipFileByExtractId({
                    applicationUuid,
                    extractUuid: applicationExtracts.uuid,
                }));
            }, 1000);
            setExtractInterval(id);
            return () => {
                clearInterval(extractInterval);
            };
        }
        else if (applicationExtracts.status == "COMPLETED") {
            clearInterval(extractInterval);
            const timerId = setTimeout(exportData, 20);
            return () => clearTimeout(timerId);
        }
    }, [applicationExtracts.status]);
    const downloadCsv = () => {
        //Download Csv
        if (internalAppExportDetailsCSV) {
            // create a blob from csv data
            const blob = new Blob([internalAppExportDetailsCSV], {
                type: "text/csv",
            });
            // generate and download link
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${appTitle}.csv`;
            document.body.appendChild(a);
            a.click();
            // clean up the URL object
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            setTimeout(() => {
                window.close();
            }, 500);
        }
    };
    useEffect(() => {
        if (internalAppExportDetailsCSV) {
            downloadCsv();
        }
    }, [internalAppExportDetailsCSV]);
    const errorState = exportExternalAppRejected ||
        exportInternalAppRejected ||
        applicationExtracts.status === "FAILED";
    return (_jsxs("div", Object.assign({ className: "export-message-page" }, { children: [_jsx("img", { className: "export-message-logo", src: LOGO, alt: "Watermark logo" }), _jsx("span", Object.assign({ className: `primary-text${errorState ? " error" : ""}` }, { children: errorState ? errorPrimaryText : primaryText })), _jsx("div", Object.assign({ className: `secondary-text-container ${errorState ? "error-text" : ""}` }, { children: _jsx("span", Object.assign({ className: "secondary-text" }, { children: errorState ? (errorSecondaryText) : (_jsxs(_Fragment, { children: [secondaryText, _jsx("b", { children: helpText })] })) })) })), !errorState && (_jsx(WmButton, Object.assign({ buttonType: "primary", id: "cancel-export", onClick: () => {
                    window.close();
                } }, { children: buttonText })))] })));
};
