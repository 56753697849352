import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import { getAllsectionRequirements, getCountByStatus, } from "../common/commonActions";
import { RequirementAccordion } from "./RequirementAccordion";
export const SectionAccordion = (props) => {
    var _a, _b, _c, _d, _e;
    const { data, resetFilters, allRequirementSubmissions, expandDetails, setExpandDetails, currentCohortStudents, setShowOpenRequirement, setInsightRequirementOverview, requirementClickHandler, OpenStudentReviewHandler, openStudentApplicationReviewHandler, } = props;
    const awaitingReviewText = useIntl().formatMessage({
        id: "app.openRequirement.awaitingReview",
    });
    const expandAllButton = useIntl().formatMessage({
        id: "app.openCohort.expandAll",
    });
    const collapseAllButton = useIntl().formatMessage({
        id: "app.openCohort.collapseAll",
    });
    const handleAccordionClick = (data) => {
        var _a;
        if ((_a = expandDetails[data.uuid]) === null || _a === void 0 ? void 0 : _a.isExpandable) {
            setExpandDetails((prev) => {
                const clonedStructureDetails = structuredClone(prev);
                const newValue = !clonedStructureDetails[data.uuid].isExpanded;
                return handleRecursiveDetails(data.uuid, newValue, clonedStructureDetails);
            });
        }
    };
    const handleRecursiveDetails = (uuid, newValue, clonedStructureDetails) => {
        clonedStructureDetails[uuid].isExpanded = newValue;
        if (clonedStructureDetails[uuid].hasOwnProperty("isExpandAll")) {
            clonedStructureDetails[uuid].isExpandAll = !newValue
                ? newValue
                : !hasCollapseChildren(clonedStructureDetails[uuid].children, clonedStructureDetails);
        }
        const parentUuid = clonedStructureDetails[uuid].parent;
        if (parentUuid) {
            handleRecursiveDetails(parentUuid, clonedStructureDetails[parentUuid].isExpanded, clonedStructureDetails);
        }
        return clonedStructureDetails;
    };
    const hasCollapseChildren = (children, clonedStructureDetails) => {
        return children.some((uuid) => {
            const childDetails = clonedStructureDetails[uuid];
            if (!childDetails.isExpanded) {
                return true;
            }
            if (childDetails.children.length) {
                return hasCollapseChildren(childDetails.children, clonedStructureDetails);
            }
            return false;
        });
    };
    const handleAccordionExpandAllClick = (data) => {
        setExpandDetails((prev) => {
            const clonedSectionDetails = structuredClone(prev);
            const newValue = !clonedSectionDetails[data.uuid].isExpandAll;
            clonedSectionDetails[data.uuid].isExpanded = newValue;
            clonedSectionDetails[data.uuid].isExpandAll = newValue;
            syncChildren(data.uuid, newValue, clonedSectionDetails);
            syncParent(data.uuid, clonedSectionDetails);
            return clonedSectionDetails;
        });
    };
    const syncChildren = (uuid, newValue, clonedSectionDetails) => {
        clonedSectionDetails[uuid].children.forEach((childUuid) => {
            if (clonedSectionDetails[childUuid].isExpandable) {
                clonedSectionDetails[childUuid].isExpanded = newValue;
            }
            if (clonedSectionDetails[childUuid].hasOwnProperty("isExpandAll")) {
                clonedSectionDetails[childUuid].isExpandAll = newValue;
            }
            syncChildren(childUuid, newValue, clonedSectionDetails);
        });
    };
    const syncParent = (uuid, clonedSectionDetails) => {
        const parentUuid = clonedSectionDetails[uuid].parent;
        if (parentUuid) {
            clonedSectionDetails[parentUuid].isExpandAll = !hasCollapseChildren(clonedSectionDetails[parentUuid].children, clonedSectionDetails);
            syncParent(parentUuid, clonedSectionDetails);
        }
    };
    const renderAccordion = () => {
        return (_jsxs("div", Object.assign({ className: "sub-section-container" }, { children: [data.requirements && renderAccordionRequirements(data.requirements), data.subSections &&
                    data.subSections.map((subSection) => (_jsx("div", Object.assign({ className: "sub-section" }, { children: _jsx(SectionAccordion, Object.assign({}, props, { data: subSection })) }), subSection.uuid)))] })));
    };
    const renderAccordionRequirements = (requirements) => {
        return (_jsx("div", Object.assign({ className: "requirement-container" }, { children: requirements.map((requirement) => {
                var _a;
                const requirementSubmissionList = allRequirementSubmissions.filter(({ requirementUuid }) => requirementUuid === requirement.uuid);
                return (_jsx(RequirementAccordion, { currentCohortStudents: currentCohortStudents, requirement: requirement, requirementSubmissionList: requirementSubmissionList, resetFilters: resetFilters, openRequirement: (_a = expandDetails[requirement.uuid]) === null || _a === void 0 ? void 0 : _a.isExpanded, setShowOpenRequirement: setShowOpenRequirement, setInsightRequirementOverview: setInsightRequirementOverview, requirementClickHandler: requirementClickHandler, OpenStudentReviewHandler: OpenStudentReviewHandler, allRequirementSubmissions: allRequirementSubmissions, handleOpenRequirement: () => handleAccordionClick(requirement), openStudentApplicationReviewHandler: openStudentApplicationReviewHandler }, requirement.uuid));
            }) })));
    };
    return (_jsxs("div", Object.assign({ className: "accordion-header-content" }, { children: [_jsxs("div", Object.assign({ className: "accordion-header" }, { children: [_jsxs("div", Object.assign({ className: "accordion-left-section" }, { children: [((_a = expandDetails[data.uuid]) === null || _a === void 0 ? void 0 : _a.isExpandable) ? (_jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "f40a", iconRotate: ((_b = expandDetails[data.uuid]) === null || _b === void 0 ? void 0 : _b.isExpanded) ? 90 : 0, id: data.uuid, onClick: () => handleAccordionClick(data), placeholder: undefined }, { children: _jsx("span", Object.assign({ className: "accordion-label" }, { children: data.title })) }))) : (_jsx("span", Object.assign({ className: "accordion-label" }, { children: data.title }))), ((_c = expandDetails[data.uuid]) === null || _c === void 0 ? void 0 : _c.isExpandable) ? (_jsx(WmButton, Object.assign({ buttonType: "textonly", id: data.uuid, onClick: () => handleAccordionExpandAllClick(data), placeholder: undefined }, { children: ((_d = expandDetails[data.uuid]) === null || _d === void 0 ? void 0 : _d.isExpandAll)
                                    ? collapseAllButton
                                    : expandAllButton }))) : null] })), _jsx("div", Object.assign({ className: "review-text" }, { children: `${getCountByStatus(getAllsectionRequirements(data, allRequirementSubmissions), "AWAITING_REVIEW").toString()} ${awaitingReviewText}` }))] })), ((_e = expandDetails[data.uuid]) === null || _e === void 0 ? void 0 : _e.isExpanded) && _jsx(_Fragment, { children: renderAccordion() })] }), data.uuid));
};
