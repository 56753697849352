var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROOT } from "../../env-config";
import { fetchWrapper } from "../common/fetchWrapper";
import Constants from "../common/constants/applicationConstants.json";
import { isNullOrEmpty } from "../../static/js/commons";
export const isCollapseAll = (timelineData) => {
    let count = 0;
    const areAllSectionExpanded = timelineData.sections.every((sec) => (!sec.requirements.length && !sec.subSections.length) ||
        sec.expandCohortSection === true);
    if (areAllSectionExpanded) {
        timelineData.sections.forEach((sec) => {
            const isCollapse = sec.subSections.some((subSec) => subSec.requirements.length && subSec.expandCohortSubSection === false);
            if (isCollapse) {
                count++;
                return;
            }
        });
        return !count;
    }
    else {
        return false;
    }
};
export const getAllPrograms = createAsyncThunk("/getAllPrograms", ({ orgUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/programs`, {}, null);
    return data ? data : error;
}));
export const getCohortsByRole = createAsyncThunk("/getCohortsByRole", ({ orgUuid, role }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/cohorts?role=${role}`, {}, null);
    let res = yield data;
    if (data) {
        let returnObject = { data: res, role: role };
        return returnObject;
    }
    return error;
}));
export const getDefaultTimeline = createAsyncThunk("/getTimeline", ({ programUuid, programName }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/default-timeline`, {}, {
        programUuid,
        programName,
    });
    return data ? data : error;
}));
export const validateTimelineTitle = createAsyncThunk("/validateTimelineTitle", ({ timelineUuid, title }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/timelines/${timelineUuid}/validate`, {}, {
        title,
    });
    return data ? data : error;
}));
export const updateTimeline = createAsyncThunk("/updateTimeline", ({ timelineUuid, title, status }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_ROOT}api/timelines/${timelineUuid}`, {}, {
        title,
        status,
    });
    return data ? data : error;
}));
export const getTimeline = createAsyncThunk("/getTimeline", (timelineUuid, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/timelines/${timelineUuid}`, {}, null);
    return data ? data : error;
}));
export const getTimelineCopy = createAsyncThunk("/getTimelineCopy", (timelineUuid, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/timeline/${timelineUuid}/copy-plan`, {}, null);
    return data ? data : error;
}));
export const getPrograms = createAsyncThunk("/getPrograms", ({ orgUuid, programUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}`, {}, null);
    return data ? data : error;
}));
export const getAllPlansForProgram = createAsyncThunk("/getAllPlansForProgram", ({ orgUuid, programUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}/plans`, {}, null);
    return data ? data : error;
}));
export const getUsersByOrg = createAsyncThunk("/getUsersByOrg", ({ orgUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/users`, {}, null);
    return data ? data : error;
}));
export const editSection = createAsyncThunk("/editSection", ({ timelineUuid, sectionUuid, title }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_ROOT}api/timelines/${timelineUuid}/sections/${sectionUuid}`, {}, {
        title: title,
    });
    return data ? data : error;
}));
export const deleteSection = createAsyncThunk("/deleteSection", ({ timelineUuid, id }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("DELETE", `${API_ROOT}api/timelines/${timelineUuid}/sections/${id}`, {}, null);
    return data ? data : error;
}));
export const editSubSection = createAsyncThunk("/editSubSection", ({ timelineUuid, sectionUuid, title }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_ROOT}api/timelines/${timelineUuid}/sub-sections/${sectionUuid}`, {}, {
        title: title,
    });
    return data ? data : error;
}));
export const deleteSubSection = createAsyncThunk("/deleteSubSection", ({ timelineUuid, id }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("DELETE", `${API_ROOT}api/timelines/${timelineUuid}/sub-sections/${id}`, {}, null);
    return data ? data : error;
}));
export const addSectionToTimeline = createAsyncThunk("/addSectionToTimeline", ({ timelineUuid, title, sortOrder }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/timelines/${timelineUuid}/sections`, {}, {
        timelineUuid: timelineUuid,
        title: title,
        sortOrder: sortOrder,
    });
    return data ? data : error;
}));
export const addSubSectionToTimeline = createAsyncThunk("/addSubSectionToTimeline", ({ timelineUuid, sectionUuid, title, sortOrder }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/timelines/${timelineUuid}/sub-sections`, {}, {
        sectionUuid: sectionUuid,
        title: title,
        sortOrder: sortOrder,
    });
    return data ? data : error;
}));
export const deleteRequirement = createAsyncThunk("/deleteRequirement", ({ timelineUuid, id }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("DELETE", `${API_ROOT}api/timelines/${timelineUuid}/requirements/${id}`, {}, null);
    return data ? data : error;
}));
export const createRequirement = createAsyncThunk("/requirementCreated", ({ timelineUuid, sectionUuid, subSectionUuid, title, description, isStudentFileUploadRequired, sectionType, referenceLinksList, files, sortOrder, applicationUuid, requirementType, dueDate, confirmationCheckboxes, logCategory, courses, threshold, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    let dueDateTimeUTC = null;
    if (!isNullOrEmpty(dueDate)) {
        dueDateTimeUTC = new Date(Date.UTC(dueDate.getUTCFullYear(), dueDate.getUTCMonth(), dueDate.getUTCDate(), dueDate.getUTCHours(), dueDate.getUTCMinutes()));
    }
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/timelines/${timelineUuid}/${sectionType}/${sectionUuid == null ? subSectionUuid : sectionUuid}/requirements`, {}, {
        timelineUuid: timelineUuid,
        sectionUuid: sectionUuid,
        subSectionUuid: subSectionUuid,
        title: title,
        description: description,
        isStudentFileUploadRequired: isStudentFileUploadRequired,
        referenceLinksList: referenceLinksList,
        files: files,
        sortOrder: sortOrder,
        applicationUuid: applicationUuid,
        requirementType: requirementType,
        dueDate: dueDateTimeUTC,
        confirmationCheckboxes: confirmationCheckboxes,
        logCategory,
        courses,
        threshold,
    });
    return data ? data : error;
}));
export const deleteLicensurePlan = createAsyncThunk("/deleteLicensurePlan", ({ programUuid, timelineUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("DELETE", `${API_ROOT}api/program/${programUuid}/timeline/${timelineUuid}/delete-plan`, {}, null);
    return data ? data : error;
}));
/**
  Keeping for future reference.
  Commenting to resolve circular dependency on store which was causing issues in test cases
*/
// export const validateAndUpdateRequirement = (
//   timelineId: string | undefined,
//   requirementID: string,
//   callbackData: any,
//   setAddRequirementPage: any
// ) => {
//   let timelineUuid = timelineId;
//   let requirementUuid = requirementID;
//   let urlToValidate = `${API_ROOT}api/timelines/${timelineUuid}/requirements/${requirementUuid}/validate`;
//   fetchWrapper("GET", urlToValidate, {}, null).then((data: any) => {
//     let [validatePromise, error] = data;
//     validatePromise.then((response: any) => {
//       if (response === true) {
//         //store.dispatch(setRequirementValidation(response));
//         setAddRequirementPage(true);
//       } else {
//         //store.dispatch(updateRequirement(callbackData));
//         setAddRequirementPage(false);
//         // store.dispatch(resetFileNotifications());
//         // store.dispatch(resetNewRequirementFiles([]));
//       }
//     });
//   });
// };
export const updateRequirement = createAsyncThunk("/requirementUpdated", ({ timelineUuid, sectionUuid, subSectionUuid, title, description, isStudentFileUploadRequired, sectionType, uuid, referenceLinksList, files, sortOrder, applicationUuid, requirementType, dueDate, confirmationCheckboxes, logCategory, courses, threshold, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    let dueDateTimeUTC = null;
    if (!isNullOrEmpty(dueDate)) {
        dueDateTimeUTC = new Date(Date.UTC(dueDate.getUTCFullYear(), dueDate.getUTCMonth(), dueDate.getUTCDate(), dueDate.getUTCHours(), dueDate.getUTCMinutes()));
    }
    const [data, error] = yield fetchWrapper("PUT", `${API_ROOT}api/timelines/${timelineUuid}/${sectionType}/${sectionUuid == null ? subSectionUuid : sectionUuid}/requirements/${uuid}`, {}, {
        timelineUuid: timelineUuid,
        sectionUuid: sectionUuid,
        subSectionUuid: subSectionUuid,
        title: title,
        description: description,
        isStudentFileUploadRequired: isStudentFileUploadRequired,
        uuid: uuid,
        referenceLinksList: referenceLinksList,
        files: files,
        sortOrder: sortOrder,
        applicationUuid: applicationUuid,
        requirementType: requirementType,
        dueDate: dueDateTimeUTC,
        confirmationCheckboxes: confirmationCheckboxes,
        logCategory,
        courses,
        threshold,
    });
    return data ? data : error;
}));
export const updateRequirementsBulk = createAsyncThunk("/updateRequirementsBulk", ({ timelineUuid, requestBody }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/timelines/${timelineUuid}/requirements-bulk-update`, {}, requestBody);
    return data ? data : error;
}));
export const updateSubSectionBulk = createAsyncThunk("/updateSubSectionBulk", ({ timelineUuid, requestBody }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/timelines/${timelineUuid}/section-subsection-update`, {}, requestBody);
    return data ? data : error;
}));
export const getTimlinesForProgram = createAsyncThunk("/getTimlinesForProgram", ({ orgUuid, programUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}/timelines`, {}, null);
    return data ? data : error;
}));
export const getRequirementDetails = createAsyncThunk("/getRequirementDetails", ({ requirementUuids }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/requirements/get-details`, {}, {
        requirementUuids,
    });
    return data ? data : error;
}));
export const timelineSliceInitialState = {
    programPlansData: [],
    timelineData: {},
    cacheTimelineData: {},
    currentProgramData: {},
    addSectionModal: false,
    deleteSectionModal: false,
    currentSortOrder: 0,
    renderType: "",
    currentSectionUuid: "",
    currentTimelineUuid: "",
    savedTitle: "",
    snackNotifications: "[]",
    fileNotifications: "[]",
    modalTitle: "",
    titleError: false,
    deletedSubSectionUuid: "",
    ProgramList: [],
    showEditableTimeline: false,
    displayDeleteReqModal: false,
    displayDeletePlanModal: false,
    displayArchivePlanModal: false,
    displayEditReqModal: false,
    currentSubSectionUuid: "",
    currentRequirementUuid: "",
    currentRequirementTitle: "",
    currentRequirementType: "",
    deletedReqIndex: -1,
    deleteReqType: "",
    isFetchingFaculties: false,
    isFetchingPrograms: true,
    isFetchingCurrentProgram: true,
    isFetchingRequirement: false,
    isFetchingTimeline: false,
    facultyMembers: [],
    buttonExpandAll: false,
    isRequirementModifiable: false,
    newRequirementFiles: [],
    uploadInProgress: false,
    lastUploadIndex: -1,
    studentCohorts: [],
    facultyCohorts: [],
    openDeleteReqModal: false,
    openInformationReqModal: false,
    resetNewRequirementFiles: false,
    setDeleteRequirementFiles: [],
    openAddSectionModal: false,
    timelineTitleValid: true,
    buttonAction: {},
    isCopyCompleted: false,
    isFetchingAllPrograms: false,
    blockMovement: false,
    ariaMoveText: "",
    copiedFromPlanUuid: "",
    openDeletePlanModal: false,
    deletedPlanIndex: -1,
    archivedPlanIndex: -1,
    openArchivePlanModal: false,
    referenceLinksList: [],
    isFetchingAllTimelines: false,
    timelineList: [],
};
export const timelineSlice = createSlice({
    name: "timelineSliceData",
    initialState: timelineSliceInitialState,
    reducers: {
        setButtonAction: (state, { payload }) => {
            state.buttonAction.title = payload.title;
            state.buttonAction.action = payload.action;
        },
        setCohortActiveInTimeline: (state, { payload }) => {
            state.timelineData.anyCohortActive = true;
            return state;
        },
        setEditTimeline: (state, { payload }) => {
            state.showEditableTimeline = payload;
            state.cacheTimelineData = state.timelineData;
            return state;
        },
        resetTimelineData: (state) => {
            state.timelineData = {};
            return state;
        },
        setIsExpanded: (state, { payload }) => {
            const copy = Object.assign({}, state.timelineData);
            if (payload.isTypeCohort) {
                copy.sections[payload.index].expandCohortSection = !payload.isExpanded;
                state.buttonExpandAll = isCollapseAll(copy);
                return state;
            }
            else {
                if (payload.isTypeRequirement)
                    copy.sections[payload.index].isRequirementExpanded =
                        !payload.isExpanded;
                else
                    copy.sections[payload.index].isExpanded = !payload.isExpanded;
                return state;
            }
        },
        setSubIsExpanded: (state, { payload }) => {
            const copy = Object.assign({}, state.timelineData);
            const subIndex = copy.sections[payload.index].subSections.findIndex((subsection) => {
                return subsection.uuid === payload.uuid;
            });
            if (subIndex != -1) {
                if (payload.isTypeCohort) {
                    copy.sections[payload.index].subSections[subIndex].expandCohortSubSection = !payload.isExpanded;
                    state.buttonExpandAll = isCollapseAll(copy);
                }
                else {
                    if (payload.isTypeRequirement) {
                        copy.sections[payload.index].subSections[subIndex].isRequirementExpanded = !payload.isExpanded;
                    }
                    else {
                        copy.sections[payload.index].subSections[subIndex].isExpanded =
                            !payload.isExpanded;
                    }
                }
            }
            return state;
        },
        toggleExpandCollapse: (state, { payload }) => {
            state.buttonExpandAll = payload;
            const timelineSections = state.timelineData.sections.length
                ? state.timelineData.sections.map((section) => {
                    if (section.requirements.length || section.subSections.length)
                        section.expandCohortSection = payload;
                    if (section.subSections.length > 0) {
                        section.subSections.map((subSection) => {
                            if (subSection.requirements.length)
                                subSection.expandCohortSubSection = payload;
                            return subSection;
                        });
                    }
                    return section;
                })
                : [];
            state.timelineData.sections = timelineSections;
            return state;
        },
        setProgramDetails: (state, { payload }) => {
            state.currentProgramData = {
                uuid: payload.uuid,
                title: payload.title,
                institutionName: payload.institutionName,
                timelineUuid: payload.timelineUuid,
                copiedFromTimelineUuid: payload.copiedFromTimelineUuid,
            };
            return state;
        },
        openAddSectionModal: (state, { payload }) => {
            state.currentSortOrder = payload.currentSortOrder;
            state.renderType = payload.renderType;
            state.currentSectionUuid = payload.currentSectionUuid;
            state.modalTitle = payload.title;
            if (payload.addSectionModal) {
                state.savedTitle = payload.title;
            }
            if (!payload.addSectionModal) {
                state.titleError = false;
            }
            state.addSectionModal = payload.addSectionModal;
            return state;
        },
        resetTimelineState: (state, { payload }) => {
            state.timelineData = {};
            return state;
        },
        openDeleteSectionModal: (state, { payload }) => {
            state.currentSortOrder = payload.currentSortOrder;
            state.deleteSectionModal = payload.deleteSectionModal;
            state.renderType = payload.renderType;
            state.currentSectionUuid = payload.currentSectionUuid;
            state.currentTimelineUuid = payload.currentTimelineUuid;
            return state;
        },
        closeAddSectionModal: (state) => {
            state.modalTitle = "";
            state.savedTitle = "";
            state.addSectionModal = false;
            return state;
        },
        closeSnackbar: (state, { payload }) => {
            const snackList = JSON.parse(state.snackNotifications);
            const snackIndex = snackList.findIndex((snack) => snack.id === payload);
            snackList.splice(snackIndex, 1);
            state.snackNotifications = JSON.stringify(snackList);
        },
        closeFileSnackbar: (state, { payload }) => {
            const snackList = JSON.parse(state.fileNotifications);
            const snackIndex = snackList.findIndex((snack) => snack.id === payload);
            snackList.splice(snackIndex, 1);
            state.fileNotifications = JSON.stringify(snackList);
        },
        resetFileNotifications: (state) => {
            state.fileNotifications = JSON.stringify([]);
        },
        resetSnackNotifications: (state) => {
            state.snackNotifications = JSON.stringify([]);
        },
        setModalTitle: (state, { payload }) => {
            let title = payload;
            if (title.trim() !== "")
                state.titleError = false;
            state.modalTitle = payload;
        },
        setTitleError: (state, { payload }) => {
            state.titleError = payload;
        },
        openDeleteReqModal: (state, { payload }) => {
            state.displayDeleteReqModal = payload.displayDeleteReqModal;
            state.currentTimelineUuid = payload.currentTimelineUuid;
            state.currentSectionUuid = payload.currentSectionUuid;
            state.currentSubSectionUuid = payload.currentSubSectionUuid;
            state.currentRequirementUuid = payload.currentRequirementUuid;
            state.currentRequirementTitle = payload.title;
            state.currentRequirementType = payload.requirementType;
            state.deletedReqIndex = -1;
            state.deleteReqType = "";
        },
        openDeletePlanModal: (state, { payload }) => {
            state.displayDeletePlanModal = payload.displayDeletePlanModal;
            state.deletedPlanIndex = -1;
        },
        openArchivePlanModal: (state, { payload }) => {
            state.displayArchivePlanModal = payload.displayArchivePlanModal;
            state.archivedPlanIndex = -1;
        },
        openInformationReqModal: (state, { payload }) => {
            state.displayEditReqModal = payload.displayEditReqModal;
        },
        setRequirementValidation: (state, { payload }) => {
            state.isRequirementModifiable = payload;
        },
        setNewRequirementFiles: (state, { payload }) => {
            state.newRequirementFiles.push(payload);
        },
        setDeleteRequirementFiles: (state, { payload }) => {
            const fileIndex = state.newRequirementFiles.findIndex((file) => file.uuid === payload);
            state.newRequirementFiles.splice(fileIndex, 1);
        },
        resetNewRequirementFiles: (state, { payload }) => {
            state.newRequirementFiles = payload;
        },
        setUpdatedFiles: (state, { payload }) => {
            if (payload.operation === "DELETE") {
                notifications(state, { title: payload.fileName }, " has been deleted.", "FILE");
            }
            else if (payload.operation === "UPLOAD") {
                notifications(state, { title: payload.fileName }, " has been uploaded.", "FILE");
            }
            return state;
        },
        updateActionsByUploadStatus: (state, { payload }) => {
            state.uploadInProgress = payload.uploadInProgress;
            state.lastUploadIndex = payload.lastUploadIndex;
        },
        resetIsCopyCompleted: (state, { payload }) => {
            state.isCopyCompleted = false;
        },
        placeReqToNewPosition: (state, { payload }) => {
            let updatedTimeline = JSON.parse(JSON.stringify(state.timelineData));
            let sourceReq = {};
            let insertingAtEmptyLocation = payload.emptyNodeIdentifier !== "";
            // find Source
            let updatedSections = updatedTimeline.sections.map((tempSection) => {
                let section = Object.assign({}, tempSection);
                let reqIndex = section.requirements.findIndex((elem) => elem.uuid === payload.source);
                if (reqIndex > -1) {
                    // found in section
                    let reqs = [...section.requirements];
                    sourceReq = Object.assign({}, section.requirements[reqIndex]);
                    section.requirements = reqs.filter((elem) => elem.uuid !== payload.source);
                    section.requirements = section.requirements.map((req, index) => {
                        req.sortOrder = index + 1;
                        return req;
                    });
                }
                else {
                    section.subSections = section.subSections.map((subSection) => {
                        let reqIndexInner = subSection.requirements.findIndex((elem) => elem.uuid === payload.source);
                        if (reqIndexInner > -1) {
                            let reqs = [...subSection.requirements];
                            sourceReq = subSection.requirements[reqIndexInner];
                            subSection.requirements = reqs.filter((elem) => elem.uuid !== payload.source);
                            subSection.requirements = subSection.requirements.map((req, index) => {
                                req.sortOrder = index + 1;
                                return req;
                            });
                            return subSection;
                        }
                        else {
                            return subSection;
                        }
                    });
                }
                return section;
            });
            // place in dest
            let inSection = payload.emptyNodeIdentifier === "section";
            let inSubSection = payload.emptyNodeIdentifier === "sub-section";
            updatedSections = updatedSections.map((tempSection) => {
                let section = Object.assign({}, tempSection);
                if (insertingAtEmptyLocation &&
                    inSection &&
                    section.uuid === payload.dest) {
                    sourceReq.sectionUuid = section.uuid;
                    sourceReq.subSectionUuid = null;
                    sourceReq.sortOrder = 1;
                    section.requirements.splice(0, 0, sourceReq);
                    section.requirements = section.requirements.map((req, index) => {
                        req.sortOrder = index + 1;
                        return req;
                    });
                    return section;
                }
                let reqIndex = section.requirements.findIndex((elem) => elem.uuid === payload.dest);
                if (reqIndex > -1) {
                    // found in section
                    sourceReq.sectionUuid = section.uuid;
                    sourceReq.subSectionUuid = null;
                    section.requirements.splice(payload.insertBefore ? reqIndex : ++reqIndex, 0, sourceReq);
                    section.requirements = section.requirements.map((req, index) => {
                        req.sortOrder = index + 1;
                        return req;
                    });
                }
                else {
                    section.subSections = section.subSections.map((subSection) => {
                        if (insertingAtEmptyLocation &&
                            inSubSection &&
                            subSection.uuid === payload.dest) {
                            sourceReq.sectionUuid = null;
                            sourceReq.subSectionUuid = subSection.uuid;
                            sourceReq.sortOrder = 1;
                            subSection.requirements.splice(0, 0, sourceReq);
                            subSection.requirements = subSection.requirements.map((req, index) => {
                                req.sortOrder = index + 1;
                                return req;
                            });
                            return subSection;
                        }
                        let reqIndexInner = subSection.requirements.findIndex((elem) => elem.uuid === payload.dest);
                        if (reqIndexInner > -1) {
                            sourceReq.sectionUuid = null;
                            sourceReq.subSectionUuid = subSection.uuid;
                            subSection.requirements.splice(payload.insertBefore ? reqIndexInner : ++reqIndexInner, 0, sourceReq);
                            subSection.requirements = subSection.requirements.map((req, index) => {
                                req.sortOrder = index + 1;
                                return req;
                            });
                        }
                        return subSection;
                    });
                }
                return section;
            });
            updatedTimeline.sections = updatedSections;
            if (payload.persistInMain) {
                state.timelineData = updatedTimeline;
            }
            else {
                state.cacheTimelineData = updatedTimeline;
            }
        },
        placeReqToNewPositionKeyboard: (state, { payload }) => {
            let { source, keyCode, persistInMain, movedToPosition } = payload;
            let updatedTimeline = JSON.parse(JSON.stringify(state.cacheTimelineData));
            let sourceReq = {};
            let updatedSections = updatedTimeline.sections;
            let modifiedSections = [];
            let oldParams = {};
            // find Source Requirement
            for (let sec of updatedSections) {
                let index = sec.requirements.findIndex((elem) => elem.uuid === source);
                if (index > -1) {
                    sourceReq = sec.requirements[index];
                    oldParams.sourceIndex = index;
                    break;
                }
                for (let subSec of sec.subSections) {
                    index = subSec.requirements.findIndex((elem) => elem.uuid === source);
                    if (index > -1) {
                        sourceReq = subSec.requirements[index];
                        oldParams.sourceIndex = index;
                        break;
                    }
                }
                if (sourceReq.uuid) {
                    break;
                }
            }
            // construct object with old params
            oldParams = {
                uuid: sourceReq.uuid,
                sectionUuid: sourceReq.sectionUuid,
                subSectionUuid: sourceReq.subSectionUuid,
                sortOrder: sourceReq.sortOrder,
                sourceIndex: oldParams.sourceIndex,
            };
            // Identify and construct object with new params
            let nextElementParams = {};
            let prevCanAcceptSection = {};
            let prevCanAcceptSubSection = {};
            let moveInNextElement = false;
            let moveDone = false;
            let canAcceptElements = true;
            let lastCanAcceptType = "";
            for (let sec of updatedSections) {
                canAcceptElements =
                    sec.requirements.length < Constants.maxRequirementsAllowed;
                if (moveDone === true) {
                    break;
                }
                if (sec.uuid === sourceReq.sectionUuid &&
                    (sourceReq.subSectionUuid === null ||
                        sourceReq.subSectionUuid === "" ||
                        sourceReq.subSectionUuid === undefined)) {
                    if (keyCode === 38) {
                        if (sourceReq.sortOrder > 1) {
                            array_move(sourceReq, sec.requirements, oldParams.sourceIndex, oldParams.sourceIndex - 1);
                            moveDone = true;
                        }
                        else {
                            if (prevCanAcceptSubSection.uuid &&
                                lastCanAcceptType === "subsection") {
                                array_move(sourceReq, prevCanAcceptSubSection.requirements, -1, prevCanAcceptSubSection.requirements.length);
                                array_remove(sec.requirements, oldParams.sourceIndex);
                                moveDone = true;
                            }
                            else if (prevCanAcceptSection.uuid &&
                                lastCanAcceptType === "section") {
                                array_move(sourceReq, prevCanAcceptSection.requirements, -1, prevCanAcceptSection.requirements.length);
                                array_remove(sec.requirements, oldParams.sourceIndex);
                                moveDone = true;
                            }
                        }
                    }
                    else if (keyCode === 40) {
                        if (sourceReq.sortOrder < sec.requirements.length) {
                            array_move(sourceReq, sec.requirements, oldParams.sourceIndex, oldParams.sourceIndex + 1);
                            moveDone = true;
                        }
                        else {
                            nextElementParams = {
                                requirements: sec.requirements,
                                index: oldParams.sourceIndex,
                            };
                            moveInNextElement = true;
                        }
                    }
                }
                else if (moveInNextElement && canAcceptElements) {
                    array_remove(nextElementParams.requirements, nextElementParams.index);
                    array_move(sourceReq, sec.requirements, -1, 0);
                    moveDone = true;
                }
                if (canAcceptElements) {
                    prevCanAcceptSection = sec;
                    lastCanAcceptType = "section";
                }
                for (let subSec of sec.subSections) {
                    canAcceptElements =
                        subSec.requirements.length < Constants.maxRequirementsAllowed;
                    if (moveDone === true) {
                        break;
                    }
                    if (sourceReq.subSectionUuid === subSec.uuid) {
                        if (keyCode === 38) {
                            if (sourceReq.sortOrder > 1) {
                                array_move(sourceReq, subSec.requirements, oldParams.sourceIndex, oldParams.sourceIndex - 1);
                                moveDone = true;
                            }
                            else {
                                if (prevCanAcceptSubSection.uuid &&
                                    prevCanAcceptSubSection.sectionUuid === subSec.sectionUuid &&
                                    lastCanAcceptType === "subsection") {
                                    array_move(sourceReq, prevCanAcceptSubSection.requirements, -1, prevCanAcceptSubSection.requirements.length);
                                    array_remove(subSec.requirements, oldParams.sourceIndex);
                                    moveDone = true;
                                }
                                else if (sec.uuid && lastCanAcceptType === "section") {
                                    array_move(sourceReq, sec.requirements, -1, sec.requirements.length);
                                    array_remove(subSec.requirements, oldParams.sourceIndex);
                                    moveDone = true;
                                }
                                else if (prevCanAcceptSection.uuid &&
                                    lastCanAcceptType === "section") {
                                    array_move(sourceReq, prevCanAcceptSection.requirements, -1, prevCanAcceptSection.requirements.length);
                                    array_remove(subSec.requirements, oldParams.sourceIndex);
                                    moveDone = true;
                                }
                            }
                        }
                        else if (keyCode === 40) {
                            if (sourceReq.sortOrder < subSec.requirements.length) {
                                array_move(sourceReq, subSec.requirements, oldParams.sourceIndex, oldParams.sourceIndex + 1);
                                moveDone = true;
                            }
                            else {
                                nextElementParams = {
                                    requirements: subSec.requirements,
                                    index: oldParams.sourceIndex,
                                };
                                moveInNextElement = true;
                            }
                        }
                    }
                    else if (moveInNextElement && canAcceptElements) {
                        array_remove(nextElementParams.requirements, nextElementParams.index);
                        array_move(sourceReq, subSec.requirements, -1, 0);
                        moveDone = true;
                    }
                    if (canAcceptElements) {
                        prevCanAcceptSubSection = subSec;
                        lastCanAcceptType = "subsection";
                    }
                }
                // if(prevCanAcceptSubSection.sectionUuid !== prevCanAcceptSection.uuid) {
                //   prevSubSection = {} as SubSections;
                // }
            }
            let finalRequirement = {};
            let sectionName = "";
            let subsectionName = "";
            // correct sort order
            updatedSections.map((sec) => {
                sec.requirements.map((r, index) => {
                    r.sortOrder = index + 1;
                    r.sectionUuid = sec.uuid;
                    r.subSectionUuid = null;
                    if (r.uuid == source) {
                        finalRequirement = r;
                        if (finalRequirement.sectionUuid == sec.uuid) {
                            sectionName = sec.title;
                        }
                    }
                    return r;
                });
                sec.subSections.map((subSec) => {
                    subSec.requirements.map((r, index) => {
                        r.sortOrder = index + 1;
                        r.subSectionUuid = subSec.uuid;
                        r.sectionUuid = null;
                        if (r.uuid == source) {
                            finalRequirement = r;
                            if (finalRequirement.subSectionUuid == subSec.uuid) {
                                subsectionName = subSec.title;
                                sectionName = sec.title;
                            }
                        }
                        return r;
                    });
                });
                return sec;
            });
            if (subsectionName) {
                state.ariaMoveText =
                    finalRequirement.title +
                        " " +
                        movedToPosition +
                        " " +
                        finalRequirement.sortOrder +
                        " in " +
                        subsectionName +
                        " of " +
                        sectionName;
            }
            else {
                state.ariaMoveText =
                    finalRequirement.title +
                        " " +
                        movedToPosition +
                        " " +
                        finalRequirement.sortOrder +
                        " in " +
                        sectionName;
            }
            updatedTimeline.sections = updatedSections;
            if (payload.persistInMain) {
                state.timelineData = updatedTimeline;
            }
            else {
                state.cacheTimelineData = updatedTimeline;
            }
            state.blockMovement = false;
        },
        commitCacheTimelineData: (state, { payload }) => {
            if (payload.reset) {
                state.cacheTimelineData = state.timelineData;
            }
            else {
                state.timelineData = state.cacheTimelineData;
            }
        },
        setBlockMovement: (state, { payload }) => {
            state.blockMovement = payload.val;
        },
        setAriaMoveText: (state, { payload }) => {
            state.ariaMoveText = payload.text;
        },
        placeSubSectionToNewPosition: (state, { payload }) => {
            let updatedTimeline = JSON.parse(JSON.stringify(state.timelineData));
            let sourceSubSection = {};
            let sectionIndex = updatedTimeline.sections.findIndex((elem) => elem.uuid === payload.source);
            if (sectionIndex > -1) {
                let sourceIndex = updatedTimeline.sections.findIndex((elem) => elem.uuid === payload.source);
                const sectionElement = updatedTimeline.sections.splice(sourceIndex, 1)[0];
                let destIndex = updatedTimeline.sections.findIndex((elem) => elem.uuid === payload.dest);
                if (payload.dest === "section-top") {
                    destIndex = 0;
                }
                updatedTimeline.sections.splice(payload.insertBefore ? destIndex : ++destIndex, 0, sectionElement);
                updatedTimeline.sections = updatedTimeline.sections.map((tempSection, index) => {
                    tempSection.sortOrder = index + 1;
                    return tempSection;
                });
                state.timelineData = updatedTimeline;
            }
            else {
                let updatedSections = updatedTimeline.sections.map((tempSection) => {
                    let section = Object.assign({}, tempSection);
                    let subSectionIndex = section.subSections.findIndex((elem) => elem.uuid === payload.source);
                    if (subSectionIndex > -1) {
                        // found in section
                        let subSections = [...section.subSections];
                        sourceSubSection = Object.assign({}, section.subSections[subSectionIndex]);
                        section.subSections = subSections.filter((elem) => elem.uuid !== payload.source);
                        section.subSections = section.subSections.map((subSection, index) => {
                            subSection.sortOrder = index + 1;
                            return subSection;
                        });
                    }
                    return section;
                });
                updatedSections = updatedSections.map((tempSection) => {
                    let section = Object.assign({}, tempSection);
                    if (section.uuid === payload.dest) {
                        sourceSubSection.sectionUuid = section.uuid;
                        sourceSubSection.sortOrder = 1;
                        section.subSections.splice(0, 0, sourceSubSection);
                        section.subSections = section.subSections.map((subSection, index) => {
                            subSection.sortOrder = index + 1;
                            return subSection;
                        });
                        return section;
                    }
                    let subSectionIndex = section.subSections.findIndex((elem) => elem.uuid === payload.dest);
                    if (subSectionIndex > -1) {
                        // found in section
                        sourceSubSection.sectionUuid = section.uuid;
                        section.subSections.splice(payload.insertBefore ? subSectionIndex : ++subSectionIndex, 0, sourceSubSection);
                        section.subSections = section.subSections.map((subSection, index) => {
                            subSection.sortOrder = index + 1;
                            return subSection;
                        });
                    }
                    return section;
                });
                updatedTimeline.sections = updatedSections;
                state.timelineData = updatedTimeline;
            }
        },
        setCacheTimelineData: (state, { payload }) => {
            state.cacheTimelineData = payload.timelineData;
        },
        placeSecSubsecToNewPositionKeyboard: (state, { payload }) => {
            let { source, keyCode, isSection, movedToPosition } = payload;
            let updatedTimeline = JSON.parse(JSON.stringify(state.cacheTimelineData));
            let updatedSections = updatedTimeline.sections;
            let sourceSection = {};
            let sourceSubSection = {};
            let nextElementParams = {};
            let prevCanAcceptSection = {};
            let moveInNextElement = false;
            let canAcceptElements = true;
            let moveDone = false;
            if (isSection) {
                sourceSection = updatedSections.filter((elem) => elem.uuid === source)[0];
                if (keyCode === 38 &&
                    updatedSections.findIndex((elem) => elem.uuid === sourceSection.uuid) > 0) {
                    array_move(sourceSection, updatedSections, sourceSection.sortOrder - 1, sourceSection.sortOrder - 2);
                }
                else if (keyCode === 40 &&
                    updatedSections.findIndex((elem) => elem.uuid === sourceSection.uuid) <=
                        updatedSections.length - 1) {
                    array_move(sourceSection, updatedSections, sourceSection.sortOrder - 1, sourceSection.sortOrder);
                }
            }
            else {
                for (let sec of updatedSections) {
                    if (moveDone) {
                        break;
                    }
                    canAcceptElements =
                        sec.subSections.length < Constants.maxSubSectionAllowed;
                    let index = sec.subSections.findIndex((elem) => elem.uuid === source);
                    if (index > -1) {
                        sourceSubSection = sec.subSections[index];
                        if (keyCode === 38) {
                            if (sourceSubSection.sortOrder > 1) {
                                array_move(sourceSubSection, sec.subSections, sourceSubSection.sortOrder - 1, sourceSubSection.sortOrder - 2);
                                moveDone = true;
                            }
                            else {
                                if (prevCanAcceptSection.uuid) {
                                    array_move(sourceSubSection, prevCanAcceptSection.subSections, -1, prevCanAcceptSection.subSections.length);
                                    array_remove(sec.subSections, index);
                                    moveDone = true;
                                }
                            }
                        }
                        else if (keyCode === 40) {
                            if (sourceSubSection.sortOrder < sec.subSections.length) {
                                array_move(sourceSubSection, sec.subSections, sourceSubSection.sortOrder - 1, sourceSubSection.sortOrder);
                                moveDone = true;
                            }
                            else {
                                nextElementParams = {
                                    subSections: sec.subSections,
                                    index: index,
                                };
                                moveInNextElement = true;
                            }
                        }
                    }
                    else if (moveInNextElement && canAcceptElements) {
                        array_move(sourceSubSection, sec.subSections, -1, 0);
                        array_remove(nextElementParams.subSections, nextElementParams.index);
                        moveDone = true;
                    }
                    if (canAcceptElements) {
                        prevCanAcceptSection = sec;
                    }
                }
            }
            let finalSection = {};
            let finalSubSection = {};
            let sectionName = "";
            // correct sort order and references
            updatedSections.map((sec, index) => {
                sec.sortOrder = index + 1;
                sec.subSections.map((subSec, innerIndex) => {
                    subSec.sortOrder = innerIndex + 1;
                    subSec.sectionUuid = sec.uuid;
                    if (subSec.uuid == source) {
                        finalSubSection = subSec;
                        sectionName = sec.title;
                    }
                    return subSec;
                });
                if (sec.uuid == source) {
                    finalSection = sec;
                }
                return sec;
            });
            if (finalSubSection.title) {
                state.ariaMoveText =
                    finalSubSection.title +
                        " " +
                        movedToPosition +
                        " " +
                        finalSubSection.sortOrder +
                        " in " +
                        sectionName;
            }
            else {
                state.ariaMoveText =
                    finalSection.title +
                        " " +
                        movedToPosition +
                        " " +
                        finalSection.sortOrder;
            }
            updatedTimeline.sections = updatedSections;
            if (payload.persistInMain) {
                state.timelineData = updatedTimeline;
            }
            else {
                state.cacheTimelineData = updatedTimeline;
            }
            state.blockMovement = false;
        },
        resetIsFetchingCurrentProgram: (state, { payload }) => {
            state.isFetchingCurrentProgram = true;
        },
        addReferenceLink: (state, { payload }) => {
            state.referenceLinksList.push(payload);
            notifications(state, {}, "Link has been added.", "LINK");
        },
        updateReferenceLink: (state, { payload }) => {
            const updatedLinkObj = state.referenceLinksList.splice(payload.updateIndex, 1);
            state.referenceLinksList.splice(payload.updateIndex, 0, Object.assign(Object.assign({}, updatedLinkObj[0]), { url: payload.url, displayText: payload.displayText }));
            notifications(state, {}, "Link has been updated.", "LINK");
        },
        resetReferenceLinksList: (state, { payload }) => {
            state.referenceLinksList = payload;
        },
        removeReferenceLink: (state, { payload }) => {
            state.referenceLinksList.splice(payload.id, 1);
            notifications(state, {}, "Link has been removed.", "LINK");
        },
        removeStudentAckSnack: (state) => {
            notifications(state, {}, "Student acknowledgment has been removed.", "LINK");
        },
    },
    extraReducers: {
        "/getAllPrograms/pending": (state, { payload }) => {
            state.isFetchingPrograms = true;
        },
        "/getAllPrograms/fulfilled": (state, { payload }) => {
            state.ProgramList = payload;
            state.isFetchingPrograms = false;
        },
        "/getCohortsByRole/pending": (state, { payload }) => {
            state.isFetchingPrograms = true;
        },
        "/getCohortsByRole/fulfilled": (state, { payload }) => {
            state.isFetchingPrograms = false;
            if (payload.role === "student") {
                state.studentCohorts = payload.data;
            }
            else if (payload.role === "faculty") {
                state.facultyCohorts = payload.data;
            }
        },
        "/getPrograms/pending": (state, { payload }) => {
            state.isFetchingCurrentProgram = true;
        },
        "/getPrograms/fulfilled": (state, { payload }) => {
            state.isFetchingCurrentProgram = false;
            state.currentProgramData = Object.assign(Object.assign({}, state.currentProgramData), { uuid: payload.uuid, title: payload.name });
        },
        "/getAllPlansForProgram/pending": (state, { payload }) => {
            state.isFetchingAllPrograms = true;
        },
        "/getAllPlansForProgram/fulfilled": (state, { payload }) => {
            state.isFetchingAllPrograms = false;
            let programPlans = [...payload];
            programPlans.map((plan) => {
                var _a;
                (_a = plan === null || plan === void 0 ? void 0 : plan.sections) === null || _a === void 0 ? void 0 : _a.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
            });
            state.programPlansData = programPlans;
        },
        "/getTimlinesForProgram/pending": (state, { payload }) => {
            state.isFetchingAllTimelines = true;
        },
        "/getTimlinesForProgram/fulfilled": (state, { payload }) => {
            state.isFetchingAllTimelines = false;
            let plans = [...payload];
            plans.map((plan) => {
                var _a;
                (_a = plan === null || plan === void 0 ? void 0 : plan.sections) === null || _a === void 0 ? void 0 : _a.sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1));
            });
            state.timelineList = plans;
        },
        "/deleteSection/fulfilled": (state, { payload }) => {
            state.deleteSectionModal = false;
            const timeline = Object.assign({}, state.timelineData);
            const sectionIndex = state.timelineData.sections.findIndex((section) => section.uuid === payload);
            const deletedTitle = timeline.sections[sectionIndex].title;
            timeline.sections.splice(sectionIndex, 1);
            state.timelineData = timeline;
            notifications(state, { title: deletedTitle }, " has been deleted.", "");
            return state;
        },
        "/getUsersByOrg/pending": (state, { payload }) => {
            state.isFetchingFaculties = true;
        },
        "/getUsersByOrg/fulfilled": (state, { payload }) => {
            state.isFetchingFaculties = false;
            state.facultyMembers = payload;
        },
        "/getTimeline/pending": (state, { payload }) => {
            state.isFetchingTimeline = true;
        },
        "/getTimeline/fulfilled": (state, { payload }) => {
            var _a;
            let timeline = Object.assign({}, payload);
            state.buttonExpandAll = false;
            state.isFetchingTimeline = false;
            const timelineSections = ((_a = timeline === null || timeline === void 0 ? void 0 : timeline.sections) === null || _a === void 0 ? void 0 : _a.length)
                ? timeline.sections
                    .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
                    .map((tempSection) => {
                    let section = Object.assign({}, tempSection);
                    section.isExpanded = true;
                    if (section.requirements.length || section.subSections.length)
                        section.expandCohortSection = false;
                    section.isRequirementExpanded = false;
                    if (section.subSections.length > 0) {
                        section.subSections = section.subSections.map((tempSubSection) => {
                            let subSection = Object.assign({}, tempSubSection);
                            subSection.isExpanded = true;
                            if (subSection.requirements.length)
                                subSection.expandCohortSubSection = false;
                            subSection.isRequirementExpanded = false;
                            return subSection;
                        });
                    }
                    return section;
                })
                : [];
            timeline.sections = timelineSections;
            state.timelineData = timeline;
            return state;
        },
        "/getTimelineCopy/pending": (state, { payload }) => {
            state.isFetchingTimeline = true;
            state.isCopyCompleted = false;
        },
        "/getTimelineCopy/fulfilled": (state, { payload }) => {
            var _a;
            let timeline = Object.assign({}, payload);
            state.buttonExpandAll = false;
            const timelineSections = ((_a = timeline === null || timeline === void 0 ? void 0 : timeline.sections) === null || _a === void 0 ? void 0 : _a.length)
                ? timeline.sections
                    .sort((a, b) => (a.sortOrder > b.sortOrder ? 1 : -1))
                    .map((tempSection) => {
                    let section = Object.assign({}, tempSection);
                    section.isExpanded = true;
                    if (section.requirements.length || section.subSections.length)
                        section.expandCohortSection = false;
                    section.isRequirementExpanded = false;
                    if (section.subSections.length > 0) {
                        section.subSections = section.subSections.map((tempSubSection) => {
                            let subSection = Object.assign({}, tempSubSection);
                            subSection.isExpanded = true;
                            if (subSection.requirements.length)
                                subSection.expandCohortSubSection = false;
                            subSection.isRequirementExpanded = false;
                            return subSection;
                        });
                    }
                    return section;
                })
                : [];
            timeline.sections = timelineSections;
            state.timelineData = timeline;
            state.isFetchingTimeline = false;
            state.isCopyCompleted = true;
            state.cacheTimelineData = timeline;
            return state;
        },
        "/addSectionToTimeline/fulfilled": (state, { payload }) => {
            state.modalTitle = "";
            state.savedTitle = "";
            state.currentSectionUuid = payload.uuid;
            state.addSectionModal = false;
            const secIndex = payload.sortOrder - 1;
            payload.subSections = [];
            const sections = [...state.timelineData.sections];
            sections.splice(secIndex, 0, payload);
            const updatedSections = sections.map((section, index) => {
                section.sortOrder = index + 1;
                section.isExpanded = true;
                return section;
            });
            state.timelineData.sections = [...updatedSections];
            notifications(state, payload, " has been added.", "");
            return state;
        },
        "/editSection/fulfilled": (state, { payload }) => {
            state.modalTitle = "";
            state.savedTitle = "";
            state.addSectionModal = false;
            payload.subSections.map((subSection) => {
                subSection.isExpanded = true;
            });
            let updatedSection = Object.assign({}, payload);
            const sections = [...state.timelineData.sections];
            const updatedSections = sections.map((section) => {
                if (section.uuid === updatedSection.uuid) {
                    updatedSection.isExpanded = true;
                    return updatedSection;
                }
                return section;
            });
            state.timelineData.sections = [...updatedSections];
            notifications(state, payload, " has been updated.", "");
            return state;
        },
        "/deleteLicensurePlan/fulfilled": (state, { payload }) => {
            state.displayDeletePlanModal = false;
            const programPlans = [...state.programPlansData];
            const planIndex = state.programPlansData.findIndex((plan) => plan.uuid === payload);
            if (typeof planIndex === "number" && planIndex > -1) {
                state.deletedPlanIndex = planIndex;
            }
            const deletedPlan = programPlans[planIndex].title;
            programPlans.splice(planIndex, 1);
            notifications(state, { title: deletedPlan }, " has been deleted.", "");
            state.programPlansData = programPlans;
            return state;
        },
        "/editSubSection/fulfilled": (state, { payload }) => {
            state.modalTitle = "";
            state.savedTitle = "";
            state.addSectionModal = false;
            let updatedSubSection = payload;
            const sectionIndex = state.timelineData.sections.findIndex((section) => section.uuid === updatedSubSection.sectionUuid);
            const subSections = [
                ...state.timelineData.sections[sectionIndex].subSections,
            ];
            const updatedSection = subSections.map((subSection) => {
                if (subSection.uuid === updatedSubSection.uuid) {
                    updatedSubSection.isExpanded = true;
                    return updatedSubSection;
                }
                subSection.isExpanded = true;
                return subSection;
            });
            state.timelineData.sections[sectionIndex].subSections = [
                ...updatedSection,
            ];
            notifications(state, payload, " has been updated.", "");
            return state;
        },
        "/deleteSubSection/fulfilled": (state, { payload }) => {
            state.deleteSectionModal = false;
            let deletedTitle = "";
            let sortOrder = 0;
            const sections = [...state.timelineData.sections];
            const updatedSections = sections.map((section) => {
                const subSectionIndex = section.subSections.findIndex((subSection) => subSection.uuid === payload);
                if (subSectionIndex != -1) {
                    state.deletedSubSectionUuid = section.uuid;
                    deletedTitle = section.subSections[subSectionIndex].title;
                    sortOrder = section.subSections[subSectionIndex].sortOrder;
                    section.subSections.splice(subSectionIndex, 1);
                }
                return section;
            });
            state.timelineData.sections = [...updatedSections];
            state.currentSortOrder = sortOrder;
            notifications(state, { title: deletedTitle }, " has been deleted.", "");
            return state;
        },
        "/addSubSectionToTimeline/fulfilled": (state, { payload }) => {
            state.modalTitle = "";
            state.savedTitle = "";
            payload.isExpanded = true;
            state.addSectionModal = false;
            const sectionIndex = state.timelineData.sections.findIndex((section) => section.uuid === state.currentSectionUuid);
            state.timelineData.sections[sectionIndex].subSections.push(payload);
            notifications(state, payload, " has been added.", "");
            return state;
        },
        "/deleteRequirement/fulfilled": (state, { payload }) => {
            state.displayDeleteReqModal = false;
            const timeline = Object.assign({}, state.timelineData);
            const section = state.timelineData.sections.find((section) => section.uuid === state.currentSectionUuid);
            if (state.currentSubSectionUuid === "") {
                // remove from section's requirement as requirement is directly under a section
                const reqIndex = section === null || section === void 0 ? void 0 : section.requirements.findIndex((req) => req.uuid === payload);
                if (typeof reqIndex === "number" && reqIndex > -1) {
                    state.deleteReqType = "section";
                    state.deletedReqIndex = reqIndex;
                    section === null || section === void 0 ? void 0 : section.requirements.splice(reqIndex, 1);
                }
            }
            else {
                // remove from sub section's requirement as requirement is under a sub section
                const subSection = section === null || section === void 0 ? void 0 : section.subSections.find((subSec) => subSec.uuid === state.currentSubSectionUuid);
                const reqIndex = subSection === null || subSection === void 0 ? void 0 : subSection.requirements.findIndex((req) => req.uuid === payload);
                if (typeof reqIndex === "number" && reqIndex > -1) {
                    state.deleteReqType = "sub-section";
                    state.deletedReqIndex = reqIndex;
                    subSection === null || subSection === void 0 ? void 0 : subSection.requirements.splice(reqIndex, 1);
                }
            }
            notifications(state, { title: state.currentRequirementTitle }, " has been deleted.", "");
            return state;
        },
        "/requirementCreated/fulfilled": (state, { payload }) => {
            const sections = [...state.timelineData.sections];
            if (payload.subSectionUuid == null) {
                const updatedSections = sections.map((section) => {
                    if (section.uuid === payload.sectionUuid) {
                        section.requirements.push(payload);
                    }
                    return section;
                });
                state.timelineData.sections = [...updatedSections];
            }
            else {
                const updatedSections = sections.map((section) => {
                    section.subSections.map((subSection) => {
                        if (subSection.uuid === payload.subSectionUuid) {
                            subSection.requirements.push(payload);
                        }
                        return subSection;
                    });
                    return section;
                });
                state.timelineData.sections = [...updatedSections];
            }
            notifications(state, { title: payload.title }, " has been added.", "");
        },
        "/requirementUpdated/pending": (state) => {
            state.isFetchingRequirement = true;
        },
        "/requirementUpdated/fulfilled": (state, { payload }) => {
            const sections = [...state.timelineData.sections];
            state.isFetchingRequirement = false;
            if (payload.subSectionUuid == null) {
                const updatedSections = sections.map((section) => {
                    if (section.uuid === payload.sectionUuid) {
                        let updatedRequirement = section.requirements.map((requirement) => {
                            if (requirement.uuid == payload.uuid) {
                                requirement = payload;
                            }
                            return requirement;
                        });
                        section.requirements = updatedRequirement;
                    }
                    return section;
                });
                state.timelineData.sections = [...updatedSections];
            }
            else {
                const updatedSections = sections.map((section) => {
                    section.subSections.map((subSection) => {
                        if (subSection.uuid === payload.subSectionUuid) {
                            let updatedRequirement = subSection.requirements.map((requirement) => {
                                if (requirement.uuid == payload.uuid) {
                                    requirement = payload;
                                }
                                return requirement;
                            });
                            subSection.requirements = updatedRequirement;
                        }
                        return subSection;
                    });
                    return section;
                });
                state.timelineData.sections = [...updatedSections];
            }
            notifications(state, { title: payload.title }, " has been updated.", "");
        },
        "/validateTimelineTitle/fulfilled": (state, { payload }) => {
            state.timelineTitleValid = payload;
        },
        "/updateTimeline/fulfilled": (state, { payload }) => {
            var _a;
            state.timelineData.title = payload.title;
            state.displayArchivePlanModal = false;
            let programPlans = [...state.programPlansData];
            let status = payload.status;
            const planIndex = state.programPlansData.findIndex((plan) => plan.uuid === payload.uuid);
            if (typeof planIndex === "number" && planIndex > -1) {
                state.archivedPlanIndex = planIndex;
            }
            const oldStatus = (_a = programPlans[planIndex]) === null || _a === void 0 ? void 0 : _a.status;
            if (payload.status === "ARCHIVED") {
                notifications(state, { title: payload.title }, " has been archived.", "");
            }
            else if (oldStatus == "ARCHIVED" && payload.status != "ARCHIVED") {
                notifications(state, { title: payload.title }, " has been restored.", "");
            }
            programPlans = programPlans.map((plan) => {
                if (plan.uuid == payload.uuid) {
                    return Object.assign(Object.assign({}, plan), { status: payload.status });
                }
                return plan;
            });
            state.programPlansData = programPlans;
        },
        "/getRequirementDetails/pending": (state, { payload }) => { },
        "/getRequirementDetails/fulfilled": (state, { payload }) => {
            let timelineModified = Object.assign({}, state.timelineData);
            const processRequirement = (r) => {
                let rd = payload.filter((rd) => rd.requirementUuid == r.uuid);
                if (rd && rd.length) {
                    r.anyFileSubmitted = rd[0].isAnyFileSubmitted;
                    r.acknowledgementSelected = rd[0].isAcknowledgementSelected;
                    rd[0].isHistoryExists;
                    r.reviewed = rd[0].isReviewed;
                    r.studentFileUploaded = rd[0].isStudentFileUploaded;
                }
                return r;
            };
            timelineModified.sections.map((sec) => {
                sec.requirements = sec.requirements.map((r) => processRequirement(r));
                sec.subSections = sec.subSections.map((subSec) => {
                    subSec.requirements.map((r) => processRequirement(r));
                    return subSec;
                });
                return sec;
            });
            state.timelineData.sections = timelineModified.sections;
            state.timelineData = timelineModified;
        },
    },
});
const notifications = (state, payload, message, type) => {
    let notification = {
        id: (Math.floor(Math.random() * 100) + 1).toString(),
        message: type === "LINK" ? message : payload.title + message,
        link: "",
    };
    if (type === "FILE" || type === "LINK") {
        let notificationList = JSON.parse(state.fileNotifications);
        notificationList.unshift(notification);
        state.fileNotifications = JSON.stringify(notificationList);
    }
    else {
        let notificationList = JSON.parse(state.snackNotifications);
        notificationList.unshift(notification);
        state.snackNotifications = JSON.stringify(notificationList);
    }
};
function array_move(element, arr, fromIndex, toIndex) {
    if (fromIndex > -1) {
        arr.splice(fromIndex, 1);
    }
    arr.splice(toIndex, 0, element);
}
function array_remove(sourceArr, index) {
    sourceArr.splice(index, 1);
}
export const downloadFile = (DownloadFileUuid, newRequirementFiles) => {
    let fileUuid = DownloadFileUuid;
    let uploadedFile = newRequirementFiles.filter((file) => file.uuid === fileUuid)[0];
    let getUrlForDownload = `${API_ROOT}api/files/${fileUuid}/sign-url-download`;
    fetchWrapper("POST", getUrlForDownload, {}, {
        file_uuid: fileUuid,
        file_path: uploadedFile.file_path,
    }).then((data) => {
        let [downloadUrlPromise, error] = data;
        if (downloadUrlPromise) {
            downloadUrlPromise.then((response) => {
                window.open(response.url);
            });
        }
        else {
            throw Error(error);
        }
    });
};
export default timelineSlice.reducer;
export const { setIsExpanded, setSubIsExpanded, toggleExpandCollapse, setProgramDetails, openAddSectionModal, resetTimelineState, openDeleteSectionModal, closeSnackbar, closeFileSnackbar, resetFileNotifications, closeAddSectionModal, setModalTitle, setTitleError, setEditTimeline, setCohortActiveInTimeline, openDeleteReqModal, openInformationReqModal, setRequirementValidation, setUpdatedFiles, setNewRequirementFiles, setDeleteRequirementFiles, resetNewRequirementFiles, updateActionsByUploadStatus, placeReqToNewPosition, placeSubSectionToNewPosition, resetTimelineData, setButtonAction, resetIsCopyCompleted, placeReqToNewPositionKeyboard, commitCacheTimelineData, setBlockMovement, setAriaMoveText, setCacheTimelineData, placeSecSubsecToNewPositionKeyboard, resetIsFetchingCurrentProgram, openDeletePlanModal, openArchivePlanModal, addReferenceLink, updateReferenceLink, resetReferenceLinksList, removeReferenceLink, resetSnackNotifications, removeStudentAckSnack, } = timelineSlice.actions;
export const timelineSelector = (state) => state.timelineSliceData;
