import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SideFlyout } from "../../common/component/SideFlyout";
import { useIntl } from "react-intl";
import { CohortFlyoutContent } from "./CohortFlyoutContent";
import { useState } from "react";
import { CohortFlyoutFooter } from "./CohortFlyoutFooter";
export const CohortFlyout = ({ closeFlyout, applicationObject, cohortList, }) => {
    const cohortModalHeader = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.flyout.header",
    });
    const prefixRequiredText = useIntl().formatMessage({
        id: "app.cohort.requiredText.prefix",
    });
    const suffixRequiredText = useIntl().formatMessage({
        id: "app.cohort.requiredText.suffix",
    });
    const itemsPerPage = 10;
    const [selectedCohorts, setSelectedCohorts] = useState([]);
    const [renderFilteredCohorts, setRenderFilteredCohorts] = useState((cohortList === null || cohortList === void 0 ? void 0 : cohortList.length) > 0 ? cohortList : []);
    const [searchText, setSearchText] = useState("");
    let [currentPage, setCurrentPage] = useState(1);
    const [showError, setShowError] = useState(false);
    let [cohorts, setCohorts] = useState(renderFilteredCohorts.slice(0, itemsPerPage));
    const [isAllSelected, setIsAllSelected] = useState(false);
    const handlePageChange = (event) => {
        event.preventDefault();
        const newPage = event.target.currentPage;
        setCurrentPage(event.target.currentPage);
        const currentPageCohorts = renderFilteredCohorts === null || renderFilteredCohorts === void 0 ? void 0 : renderFilteredCohorts.slice((newPage - 1) * itemsPerPage, newPage * itemsPerPage > renderFilteredCohorts.length
            ? renderFilteredCohorts.length
            : newPage * itemsPerPage);
        setCohorts(currentPageCohorts);
        const selectedCohortsUUids = selectedCohorts.map((ct) => ct.uuid);
        const isAllCurrentPageCohortsSelected = currentPageCohorts.every((cohort) => selectedCohortsUUids.includes(cohort.uuid));
        setIsAllSelected(isAllCurrentPageCohortsSelected);
    };
    const isCohortSelected = (cohort) => {
        return (!!cohort &&
            Array.isArray(selectedCohorts) &&
            selectedCohorts.some(({ uuid }) => uuid === cohort.uuid));
    };
    const handleSearchTextChange = (text) => {
        const filteredCohorts = cohortList.filter((ct) => {
            return ct.title.toLowerCase().includes(text.toLowerCase());
        });
        setSearchText(text);
        setCurrentPage(1);
        setRenderFilteredCohorts(filteredCohorts);
        const currentPageCohorts = filteredCohorts.slice(0, itemsPerPage);
        setCohorts(currentPageCohorts);
        const selectedCohortsUUids = selectedCohorts.map((ct) => ct.uuid);
        const isAllCurrentPageCohortsSelected = currentPageCohorts.every((cohort) => selectedCohortsUUids.includes(cohort.uuid));
        setIsAllSelected(isAllCurrentPageCohortsSelected);
    };
    const handleChange = (cohort) => {
        setShowError(false);
        const newSelectedCohorts = isCohortSelected(cohort)
            ? selectedCohorts.filter((ct) => ct.uuid !== cohort.uuid)
            : [...selectedCohorts, cohort];
        setSelectedCohorts(newSelectedCohorts);
        const selectedCohortsUUids = newSelectedCohorts.map((cohort) => cohort.uuid);
        const isAllCurrentPageCohortsSelected = cohorts.every((cohort) => selectedCohortsUUids.includes(cohort.uuid));
        setIsAllSelected(isAllCurrentPageCohortsSelected);
    };
    const handleAllSelectionChange = (isAllSelected) => {
        let newSelectedCohorts = [...selectedCohorts];
        const selectedUUids = newSelectedCohorts.map((cohort) => cohort.uuid);
        cohorts.forEach((ct) => {
            const isCurrenPageItemSelected = selectedUUids.includes(ct.uuid);
            if (isCurrenPageItemSelected && !isAllSelected) {
                newSelectedCohorts = newSelectedCohorts.filter((selectedCohort) => selectedCohort.uuid !== ct.uuid);
            }
            else if (!isCurrenPageItemSelected && isAllSelected) {
                newSelectedCohorts.push(ct);
            }
        });
        setSelectedCohorts(newSelectedCohorts);
        setIsAllSelected(isAllSelected);
        setShowError(false);
    };
    const handleExportClick = () => {
        if (selectedCohorts.length > 0 && applicationObject.type) {
            closeFlyout();
            localStorage.setItem("applicationTitle", applicationObject.title);
            localStorage.setItem("selectedCohorts", JSON.stringify(selectedCohorts.map((cohorts) => cohorts.uuid)));
            // open a new tab
            window.open(`/applications/export/${applicationObject.uuid}/${applicationObject.type.toLowerCase()}`, "_blank");
        }
        else {
            setShowError(true);
        }
    };
    return (_jsxs(SideFlyout, Object.assign({ flyoutId: "cohort-flyout", focusAfterClosed: "export-btn", ariaLabel: "Select cohort" }, { children: [_jsx(SideFlyout.Header, { modalHeader: cohortModalHeader, modalSubheader: applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.title, modalRequiredPrefix: prefixRequiredText, modalRequiredSuffix: suffixRequiredText, closeFlyout: closeFlyout, flyoutFrom: "application" }), _jsx(CohortFlyoutContent, { searchText: searchText, handleSearchTextChange: handleSearchTextChange, showError: showError, renderFilteredCohorts: renderFilteredCohorts, isAllSelected: isAllSelected, handleAllSelectionChange: handleAllSelectionChange, handleChange: handleChange, cohorts: cohorts, setCohorts: setCohorts, isCohortSelected: isCohortSelected, currentPage: currentPage, itemsPerPage: itemsPerPage, handlePageChange: handlePageChange, cohortList: cohortList }), _jsx(CohortFlyoutFooter, { closeFlyout: closeFlyout, handleExportClick: handleExportClick })] })));
};
