import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import { WmButton } from "@watermarkinsights/ripple-react";
import { closeFlyout, openFlyout, } from "../../static/js/commons";
import translate from "../../i18n/translate";
import { useSelector } from "react-redux";
import { userSelector } from "../../features/login/UserSlice";
const SelectRequirementType = ({ requirementType, setSelectRequirementType, setRequirementType, addRequirementHandler, addAppHandler, addTimeLogHandler, }) => {
    var _a, _b;
    const { userInfo } = useSelector(userSelector);
    const flyoutCloseToolTip = useIntl().formatMessage({
        id: "app.cohort.secondaryButtonText",
    });
    useEffect(() => {
        setTimeout(() => openFlyout("requirement-type-flyout", "", requirementType), 200);
    }, []);
    const isApplicationsAllowed = (_b = (_a = userInfo === null || userInfo === void 0 ? void 0 : userInfo.sllUser) === null || _a === void 0 ? void 0 : _a.available_apps) === null || _b === void 0 ? void 0 : _b.includes("applications");
    const closeFlyoutHandler = (event) => {
        event.preventDefault();
        setSelectRequirementType(false);
        closeFlyout();
    };
    const renderFlyoutHeader = () => {
        return (_jsx("section", Object.assign({ className: "immersive-header header select-type-header" }, { children: _jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered header-section" }, { children: [_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsx(WmButton, { className: "close-btn", buttonType: "navigational", icon: "close", tooltip: flyoutCloseToolTip, tooltipPosition: "bottom", id: "flyout-close", class: "hydrated", onClick: (event) => closeFlyoutHandler(event), placeholder: undefined }), _jsx("h2", Object.assign({ className: "header-title" }, { children: translate("app.licensure.plan.select.requirement.type.flyout.header") }))] })), _jsx(WmButton, Object.assign({ className: "close-btn", buttonType: "primary", id: "flyout-close-btn", onClick: (event) => closeFlyoutHandler(event), placeholder: undefined }, { children: translate("app.studentWorkflow.flyout.closeButton") }))] })) })));
    };
    const renderMainContent = () => {
        return (_jsx("div", Object.assign({ className: "immersive-container select-type-body" }, { children: _jsxs("div", Object.assign({ className: "paper-card card-component" }, { children: [_jsxs("div", Object.assign({ className: "card-header" }, { children: [_jsx("h4", Object.assign({ className: "card-header-title" }, { children: translate("app.licensure.plan.select.requirement.type.label") })), _jsxs("div", Object.assign({ className: "required-fields" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] }))] })), _jsxs("div", Object.assign({ className: "card-body" }, { children: [_jsxs("div", Object.assign({ className: `select-type-container ${isApplicationsAllowed ? "" : "no-application"}`, onClick: () => {
                                    setRequirementType("stdReq");
                                    addRequirementHandler();
                                    setSelectRequirementType(false);
                                }, onKeyDown: (event) => {
                                    if (event.key === "Enter" || event.key === " ") {
                                        setRequirementType("stdReq");
                                        addRequirementHandler();
                                        setSelectRequirementType(false);
                                    }
                                }, id: "stdReq", tabIndex: 0 }, { children: [_jsx("span", { className: "select-type-icon mdi mdi-format-list-checks" }), _jsx("h5", Object.assign({ className: "select-type-title" }, { children: translate("global.standard") })), _jsx("p", Object.assign({ className: "select-type-description" }, { children: translate("app.licensure.plan.select.requirement.type.standard.description") }))] })), isApplicationsAllowed && (_jsxs("div", Object.assign({ className: "select-type-container", onClick: () => {
                                    setRequirementType("appReq");
                                    addAppHandler();
                                    setSelectRequirementType(false);
                                }, onKeyDown: (event) => {
                                    if (event.key === "Enter" || event.key === " ") {
                                        setRequirementType("appReq");
                                        addAppHandler();
                                        setSelectRequirementType(false);
                                    }
                                }, id: "appReq", tabIndex: 0 }, { children: [_jsx("span", { className: "select-type-icon mdi mdi-file-document" }), _jsx("h5", Object.assign({ className: "select-type-title" }, { children: translate("app.viewTimeline.dragNdrop.applicationText") })), _jsx("p", Object.assign({ className: "select-type-description" }, { children: translate("app.licensure.plan.select.requirement.type.application.description") }))] }))), _jsxs("div", Object.assign({ className: `select-type-container ${isApplicationsAllowed ? "" : "no-application"}`, onClick: () => {
                                    setRequirementType("timeLogReq");
                                    addTimeLogHandler();
                                    setSelectRequirementType(false);
                                }, onKeyDown: (event) => {
                                    if (event.key === "Enter" || event.key === " ") {
                                        setRequirementType("timeLogReq");
                                        addTimeLogHandler();
                                        setSelectRequirementType(false);
                                    }
                                }, id: "timeLogReq", tabIndex: 0 }, { children: [_jsx("span", { className: "select-type-icon mdi mdi-timetable" }), _jsx("h5", Object.assign({ className: "select-type-title" }, { children: translate("app.licensure.plan.select.requirement.type.time.log") })), _jsx("p", Object.assign({ className: "select-type-description" }, { children: translate("app.licensure.plan.select.requirement.type.time.log.description") }))] }))] }))] })) })));
    };
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "requirement-type-flyout", tabIndex: -1, "aria-label": "Select Requirement Type" }, { children: [_jsx("div", { className: "overlay" }), _jsxs("div", Object.assign({ className: "container" }, { children: [renderFlyoutHeader(), renderMainContent()] }))] })));
};
export default SelectRequirementType;
