var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Flyout from "../common/component/Flyout";
import { useEffect, useState, useRef } from "react";
import Inputfield from "../common/component/InputField";
import translate from "../../i18n/translate";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { createRequirement, resetNewRequirementFiles, resetFileNotifications, closeFileSnackbar, updateRequirement, resetSnackNotifications, } from "./TimelineSlice";
import { WmButton } from "@watermarkinsights/ripple-react";
import DiscardModal from "../common/component/DiscardWmModal";
import { closeFlyout, focusButtonComponent, isNullOrEmpty, openFlyout, } from "../../static/js/commons.js";
import { isStudentSubmissionExistForRequirement, resetIsSubmissionExistForRequirement, getLogCategories, cohortSelector, resetLogCategory, setLogCategory, } from "../cohorts/CohortSlice";
import { isEqual } from "lodash";
import SelectLogCategories from "./SelectLogCategories";
import DeleteModal from "../common/component/DeleteWmModal";
import Loading from "../../features/common/component/Loading";
export default function TimeLogRequirements({ addTimeLogPage, setAddTimeLogPage, requirementId, timelineUuid, sectionType, currentRequirement, setSelectRequirementType, setCurrentRequirement, }) {
    const dispatch = useDispatch();
    const { logCategory, logCategories, isFetchingLogCategories } = useSelector(cohortSelector);
    let [enteredTitle, setEnteredTitle] = useState("");
    let [enteredThreshold, setEnteredThreshold] = useState(0);
    let [enteredDescription, setenteredDescription] = useState("");
    let [titleError, settitleError] = useState(false);
    let [thresholdError, setThresholdError] = useState(false);
    let [logCategoryError, setLogCategoryError] = useState(false);
    let [conditionError, setConditionError] = useState(false);
    let [studentConfirmationError, setStudentConfirmationError] = useState(false);
    let [isFocused, setFocused] = useState(false);
    let [errorCount, setErrorCount] = useState(0);
    let [validationCount, setValidationCount] = useState(0);
    const [openDiscardModal, setOpenDiscardModal] = useState(false);
    const [openChangeReqTypeModal, setOpenChangeReqTypeModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    let [isContentModified, setIsContentModified] = useState(false);
    const [openLogCategorySelection, setOpenLogCategorySelection] = useState(false);
    const [selectedLogCategory, setSelectedLogCategory] = useState({});
    const inputRef = useRef();
    const inputThresholdRef = useRef();
    useEffect(() => {
        if (addTimeLogPage) {
            inputRef.current.focus();
            dispatch(getLogCategories());
        }
    }, [addTimeLogPage]);
    useEffect(() => {
        if (logCategories === null || logCategories === void 0 ? void 0 : logCategories.length) {
            if (currentRequirement && currentRequirement.logCategory) {
                dispatch(setLogCategory(Object.assign(Object.assign({}, logCategories.filter((l) => l.uuid === currentRequirement.logCategory.sllId)[0]), { courses: currentRequirement.courses })));
            }
            if (currentRequirement.threshold) {
                setEnteredThreshold(currentRequirement.threshold);
            }
        }
    }, [logCategories]);
    const closeFlyoutHandler = (event) => {
        var _a, _b, _c;
        event.preventDefault();
        if (isContentModified ||
            !isEqual(logCategory.uuid, (_a = currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.logCategory) === null || _a === void 0 ? void 0 : _a.sllId) ||
            !isEqual(enteredThreshold, currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.threshold) ||
            !isEqual((_b = logCategory === null || logCategory === void 0 ? void 0 : logCategory.courses) === null || _b === void 0 ? void 0 : _b.map((c) => c.platformUuid), (_c = currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.courses) === null || _c === void 0 ? void 0 : _c.map((c) => c.platformUuid))) {
            setOpenDiscardModal(true);
        }
        else {
            dispatch(resetNewRequirementFiles([]));
            dispatch(resetFileNotifications());
            setAddTimeLogPage(false);
            setCurrentRequirement && focusButtonComponent("edit-req");
            closeFlyout();
            if (currentRequirement.uuid === "") {
                setSelectRequirementType && setSelectRequirementType(true);
                setTimeout(() => {
                    var _a;
                    (_a = document.getElementById("timeLogReq")) === null || _a === void 0 ? void 0 : _a.focus();
                });
            }
        }
    };
    const updateTitleHandler = (event) => {
        event.preventDefault();
        if (currentRequirement.uuid === "") {
            if (event.target.value !== "") {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        else {
            if (event.target.value !== currentRequirement.title) {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        setEnteredTitle(event.target.value);
        if (validationCount > 0 && titleError) {
            setErrorCount((prev) => prev - 1);
            settitleError(false);
        }
    };
    const finishedSnackHandler = (event) => {
        event.preventDefault();
        dispatch(closeFileSnackbar(event.detail.id));
    };
    const focusHandler = (isfocus) => {
        setFocused(isfocus);
    };
    const updateDesciptionHandler = (event) => {
        event.preventDefault();
        if (currentRequirement.uuid === "") {
            if (event.target.value !== "") {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        else {
            if (event.target.value !== currentRequirement.description) {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        setenteredDescription(event.target.value);
    };
    const closeErrorToolbar = () => {
        setErrorCount(0);
    };
    const createTimeLogHandler = (event) => {
        let count = 0;
        if (isNullOrEmpty(enteredTitle.trim())) {
            settitleError(true);
            count++;
        }
        if (!(logCategory === null || logCategory === void 0 ? void 0 : logCategory.uuid)) {
            setLogCategoryError(true);
            count++;
            if (!isNullOrEmpty(enteredTitle.trim())) {
                focusButtonComponent("add-log-category-btn");
            }
        }
        if (enteredThreshold <= 0) {
            setThresholdError(true);
            count++;
            if (!isNullOrEmpty(enteredTitle.trim()) && (logCategory === null || logCategory === void 0 ? void 0 : logCategory.uuid)) {
                inputThresholdRef.current.focus();
            }
        }
        if (count === 0) {
            let uuid = null;
            if (currentRequirement.uuid === "") {
                uuid = requirementId.substring(23, requirementId.length);
            }
            else {
                uuid =
                    sectionType === "sections"
                        ? currentRequirement.sectionUuid
                        : currentRequirement.subSectionUuid;
            }
            const data = Object.assign(Object.assign({}, currentRequirement), { timelineUuid: timelineUuid, sectionUuid: sectionType === "sections" ? uuid : null, subSectionUuid: sectionType === "sections" ? null : uuid, title: enteredTitle, description: enteredDescription, isStudentFileUploadRequired: false, sectionType: sectionType, uuid: currentRequirement.uuid, referenceLinksList: [], files: [], sortOrder: currentRequirement.sortOrder, applicationUuid: null, requirementType: "TIME_LOG", dueDate: null, confirmationCheckboxes: [], threshold: enteredThreshold, courses: [...logCategory.courses.map((o) => o.platformUuid)], logCategory: {
                    sllId: logCategory.uuid,
                    title: logCategory.title,
                    deleted: logCategory.deleted,
                } });
            if (currentRequirement.uuid === "") {
                dispatch(createRequirement(data));
                setAddTimeLogPage(false);
                setCurrentRequirement && focusButtonComponent("edit-req");
                closeFlyout();
                dispatch(resetSnackNotifications());
            }
            else {
                if (!isEqual(data, Object.assign(Object.assign({}, currentRequirement), { sectionType }))) {
                    dispatch(updateRequirement(data)).then((res) => {
                        setCurrentRequirement && setCurrentRequirement(res.payload);
                    });
                }
                setAddTimeLogPage(false);
                setCurrentRequirement && focusButtonComponent("edit-req");
                closeFlyout();
                dispatch(resetSnackNotifications());
            }
        }
        setValidationCount((prev) => prev + 1);
    };
    const closeDiscardModalHandler = (event) => {
        var _a;
        event.preventDefault();
        setOpenDiscardModal(false);
        (_a = document.getElementById("input-requirement")) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const closeChangeReqTypeModalHandler = (event) => {
        var _a;
        event.preventDefault();
        setOpenChangeReqTypeModal(false);
        (_a = document.getElementById("input-requirement")) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const discardChanges = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        dispatch(resetLogCategory());
        setAddTimeLogPage(false);
        setCurrentRequirement && focusButtonComponent("edit-req");
        closeFlyout();
        if (currentRequirement.uuid === "") {
            setSelectRequirementType && setSelectRequirementType(true);
            setTimeout(() => {
                var _a;
                (_a = document.getElementById("timeLogReq")) === null || _a === void 0 ? void 0 : _a.focus();
            }, 100);
        }
    });
    const changeReqTypeChanges = (event) => __awaiter(this, void 0, void 0, function* () {
        event.preventDefault();
        setEnteredTitle("");
        setenteredDescription("");
        setSelectRequirementType && setSelectRequirementType(true);
        setAddTimeLogPage(false);
        closeFlyout();
        setTimeout(() => {
            var _a;
            (_a = document.getElementById("timeLogReq")) === null || _a === void 0 ? void 0 : _a.focus();
        }, 10);
    });
    useEffect(() => {
        if (!isNullOrEmpty(currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.uuid)) {
            dispatch(isStudentSubmissionExistForRequirement({
                requirementUuid: currentRequirement.uuid,
            }));
        }
        else {
            dispatch(resetIsSubmissionExistForRequirement());
            dispatch(resetLogCategory());
        }
        setEnteredTitle(currentRequirement.title);
        setenteredDescription(currentRequirement.description);
        setTimeout(() => {
            openFlyout("add-timelog-requirement-flyout", currentRequirement.uuid === "" ? "timeLogReq" : "", "input-requirement");
        }, 200);
    }, []);
    useEffect(() => {
        if (titleError) {
            inputRef.current.focus();
        }
    }, [titleError, validationCount]);
    useEffect(() => {
        var _a;
        if (conditionError && !titleError) {
            (_a = document.getElementById("condition-card")) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [conditionError, validationCount]);
    useEffect(() => {
        var _a;
        if (studentConfirmationError && !titleError) {
            (_a = document.getElementById("student-confirmation")) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [studentConfirmationError, validationCount]);
    const deleteModalInfo = useIntl().formatMessage({
        id: "app.timelog.log.category.delete.warning.text",
    });
    const modalHeading = useIntl().formatMessage({
        id: "app.timelog.log.category.delete.text",
    });
    const deleteLabel = useIntl().formatMessage({
        id: "app.viewTimeline.delete.requirement",
    });
    const thresholdLabel = useIntl().formatMessage({
        id: "app.timelog.set.threshold.min.required.hours",
    });
    const discardModalHeading = useIntl().formatMessage({
        id: "app.requirement.discardModal.heading",
    });
    const discardModalContent = useIntl().formatMessage({
        id: "app.requirement.discardModal.content",
    });
    const discardModalAriaLabelValue = useIntl().formatMessage({
        id: "app.requirement.discardModal.ariaLabelValue",
    });
    const discardModalPrimaryText = useIntl().formatMessage({
        id: "app.requirement.discardModal.primaryText",
    });
    const discardModalSecondaryText = useIntl().formatMessage({
        id: "app.requirement.discardModal.secondayText",
    });
    const reqFlyoutHeadingText = useIntl().formatMessage({
        id: "app.licensure.plan.select.requirement.type.create.requirement",
    }, {
        type: "Time Log",
    });
    const reqTypeHeading = useIntl().formatMessage({
        id: "app.licensure.plan.select.requirement.type.standard.req.type",
    }, {
        type: "Time Log",
    });
    const editTimeLogHeadingText = useIntl().formatMessage({
        id: "app.timelog.requirement.heading",
    });
    const mandatoryFieldInfo = useIntl().formatMessage({
        id: "app.timelog.requirement.mandatory.field.info",
    });
    const changeReqTypeModalHeading = useIntl().formatMessage({
        id: "app.timelog.changeReqTypeModal.heading",
    });
    const changeReqTypeModalContent = useIntl().formatMessage({
        id: "app.timelog.changeReqTypeModal.content",
    });
    const changeReqTypeModalPrimaryText = useIntl().formatMessage({
        id: "app.timelog.changeReqTypeModal.primaryText",
    });
    const renderBreadCrum = () => {
        return (currentRequirement.uuid === "" && (_jsx("div", Object.assign({ className: "go-back-link program-header flex-row -justified -verticallycentered" }, { children: _jsxs("div", Object.assign({ className: "bread-crumbs" }, { children: [_jsx(WmButton, Object.assign({ "button-type": "textonly", id: "go-back", className: "link", onClick: () => {
                            setAddTimeLogPage(false);
                            closeFlyout();
                            setSelectRequirementType && setSelectRequirementType(true);
                            setTimeout(() => {
                                var _a;
                                (_a = document.getElementById("timeLogReq")) === null || _a === void 0 ? void 0 : _a.focus();
                            });
                        }, placeholder: undefined }, { children: _jsx("span", { children: translate("app.licensure.plan.select.requirement.type.label") }) })), _jsx("span", { children: reqTypeHeading })] })) }))));
    };
    const mainContent = () => {
        var _a;
        return (_jsxs("div", { children: [errorCount > 0 && validationCount > 0 ? (_jsxs("div", Object.assign({ className: "error-toolbar flex-row -justified -verticallycentered" }, { children: [_jsx("div", {}), _jsx("div", Object.assign({ className: "error-details", "aria-live": "assertive", "aria-atomic": true, role: "alert" }, { children: errorCount > 1
                                ? errorCount +
                                    " errors found, please check all required fields."
                                : translate("app.requirement.error_toolbar") })), _jsx(WmButton, { buttonType: "navigational", tooltip: "Close banner", icon: "f156", class: "cancel hydrated", id: "close-error", "custom-background": "dark", tooltipPosition: "bottom-left", onClick: closeErrorToolbar, placeholder: undefined })] }))) : (""), _jsxs("div", Object.assign({ className: errorCount > 0 && validationCount > 0
                        ? "flyout-content -errors flyout-data"
                        : "flyout-content no-errors flyout-data" }, { children: [_jsxs("div", Object.assign({ className: "paper-card section-card edit -card-width requirement-card" }, { children: [_jsxs("div", Object.assign({ className: "header section-header flex-row -justified -verticallycentered" }, { children: [_jsx("h2", Object.assign({ className: "title" }, { children: translate("app.timelog.requirement.details_block_title") })), currentRequirement.uuid == "" && (_jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "f3eb", id: "change-requirement-type", onClick: () => {
                                                setOpenChangeReqTypeModal(true);
                                            }, placeholder: undefined }, { children: translate("app.timelog.requirement.change.req.type") })))] })), _jsxs("div", Object.assign({ className: "body" }, { children: [_jsx(Inputfield, { titleError: titleError, dataLabel: "Title", modalTitle: enteredTitle, updateTitleHandler: updateTitleHandler, inputRef: inputRef, modalFor: "requirement", modalId: "requirement", required: true }), _jsxs("div", Object.assign({ className: "description-field" }, { children: [_jsx("label", Object.assign({ className: "textfieldlabel ", htmlFor: "requirement-description" }, { children: translate("global.description") })), _jsx("div", Object.assign({ className: isFocused ? "textfield is-focused" : "textfield" }, { children: _jsxs("div", Object.assign({ className: "charcount -textarea" }, { children: [_jsx("textarea", { className: "textfieldinput autosize-textarea", id: "requirement-description", "aria-required": "true", value: enteredDescription, maxLength: 500, "aria-describedby": "field-description", onChange: updateDesciptionHandler, onFocus: () => focusHandler(true), onBlur: () => focusHandler(false), "aria-label": "Description" }), _jsx("div", Object.assign({ className: "count", "aria-live": "polite", "aria-atomic": true, "aria-label": enteredDescription.length < 500
                                                                    ? "Character Entered"
                                                                    : "Character Limit has been reached" }, { children: String(enteredDescription.length) + "/500" })), _jsx("span", Object.assign({ id: "field-description", className: "max-count-label" }, { children: "Description has character limit of 500" }))] })) })), _jsx("div", Object.assign({ className: "help-text no-sections-info" }, { children: translate("app.requirement.description_info") }))] }))] }))] })), _jsxs("div", Object.assign({ className: conditionError
                                ? "paper-card section-card edit -card-width condition-card condition-card-error "
                                : "paper-card section-card edit -card-width condition-card", tabIndex: 0, id: "condition-card", "aria-describedby": "condition-error" }, { children: [_jsx("div", Object.assign({ className: "header section-header flex-row -justified -verticallycentered" }, { children: _jsxs("div", { children: [_jsx("h2", Object.assign({ className: conditionError
                                                    ? "title error heading-title"
                                                    : "title heading-title" }, { children: translate("app.requirement.condition_block_title") })), _jsx("div", { children: translate("app.requirement.condition_block_sub_title") })] }) })), _jsxs("div", Object.assign({ className: "body" }, { children: [_jsxs("div", Object.assign({ className: "condition-types" }, { children: [_jsx("h5", Object.assign({ className: "subsection-label condition-name" }, { children: translate("app.timelog.add.log.category.heading") })), _jsx("p", Object.assign({ className: "subsection-info" }, { children: translate("app.timelog.add.log.category.heading.description") })), _jsxs("div", { children: [_jsxs("label", Object.assign({ htmlFor: "add-log-category-btn", className: `add-log-category-label ${logCategoryError ? "error" : ""}` }, { children: [translate("app.timelog.add.log.category.btn.label"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), logCategoryError && (_jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), logCategoryError && (_jsx("p", Object.assign({ className: "add-log-category-error" }, { children: translate("app.timelog.add.log.category.error.text") }))), logCategory.title ? (_jsx("div", Object.assign({ className: "log-category-list" }, { children: _jsx("div", Object.assign({ className: "log-category-list-item flex-row -justified -vertically-centered", tabIndex: 0, id: "log-category-" + logCategory.uuid }, { children: _jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: "log-category-details" }, { children: [_jsxs("div", Object.assign({ className: "log-category-name" }, { children: [_jsx("span", Object.assign({ className: "log-category-title" }, { children: logCategory.title })), _jsx("span", Object.assign({ className: "log-category-info" }, { children: logCategory.courses.length > 1
                                                                                                ? translate("app.timelog.log.category.course.count.text", {
                                                                                                    count: logCategory.courses.length,
                                                                                                })
                                                                                                : translate("app.timelog.log.category.course.one.text") }))] })), _jsx("ul", Object.assign({ className: "log-category-courses-list" }, { children: (_a = logCategory === null || logCategory === void 0 ? void 0 : logCategory.courses) === null || _a === void 0 ? void 0 : _a.map((course) => {
                                                                                        return (_jsx("li", Object.assign({ className: "log-category-courses-list-item" }, { children: course.name }), course.uuid));
                                                                                    }) }))] })), _jsxs("div", Object.assign({ className: "button-collection" }, { children: [_jsx(WmButton, { id: "edit-log-category-btn", buttonType: "icononly", icon: "f3eb", tooltip: "Edit", onClick: () => {
                                                                                        setSelectedLogCategory(logCategory);
                                                                                        setOpenLogCategorySelection(true);
                                                                                    }, placeholder: undefined }), _jsx(WmButton, { id: "delete-log-category-btn", buttonType: "icononly", icon: "f1c0", tooltip: "Delete", onClick: () => {
                                                                                        setOpenDeleteModal(true);
                                                                                    }, placeholder: undefined })] }))] }) }), logCategory.uuid) }))) : (_jsx(WmButton, Object.assign({ buttonType: "secondary", icon: "f419", id: "add-log-category-btn", className: "log-category-btn", onClick: () => {
                                                                setLogCategoryError(false);
                                                                setOpenLogCategorySelection(true);
                                                            }, placeholder: undefined }, { children: translate("app.timelog.add.log.category.btn.text", {
                                                                action: "Add",
                                                            }) })))] })] })), _jsxs("div", Object.assign({ className: "condition-types" }, { children: [_jsx("h5", Object.assign({ className: "subsection-label condition-name" }, { children: translate("app.timelog.set.threshold.heading") })), _jsx("p", Object.assign({ className: "subsection-info" }, { children: translate("app.timelog.set.threshold.heading.description") })), _jsx(Inputfield, { fieldType: "number", titleError: thresholdError, dataLabel: thresholdLabel, modalTitle: enteredThreshold.toString(), minValue: 0, updateTitleHandler: (event) => {
                                                        setThresholdError(false);
                                                        setEnteredThreshold(event.target.value);
                                                    }, inputRef: inputThresholdRef, modalFor: "threshold", modalId: "threshold", required: true, customError: thresholdError, customErrorMessage: "", disabled: false })] }))] }))] }))] })), _jsx(DiscardModal, { isOpen: openDiscardModal, onPrimaryClick: (event) => discardChanges(event), onSecondaryClick: (event) => closeDiscardModalHandler(event), onClose: (event) => closeDiscardModalHandler(event), modalContent: discardModalContent, modalHeading: discardModalHeading, ariaLabelValue: discardModalAriaLabelValue, modalId: "discard-modal", contentId: "content-discard-modal", primaryText: discardModalPrimaryText, secondayText: discardModalSecondaryText }), _jsx(DiscardModal, { isOpen: openChangeReqTypeModal, onPrimaryClick: (event) => changeReqTypeChanges(event), onSecondaryClick: (event) => closeChangeReqTypeModalHandler(event), onClose: (event) => closeChangeReqTypeModalHandler(event), modalContent: changeReqTypeModalContent, modalHeading: changeReqTypeModalHeading, ariaLabelValue: changeReqTypeModalHeading, modalId: "change-req-type-modal", contentId: "content-change-req-type-modal", primaryText: changeReqTypeModalPrimaryText, secondayText: discardModalSecondaryText }), _jsx(DeleteModal, { isOpen: openDeleteModal, onPrimaryClick: (event) => {
                        event.preventDefault();
                        setOpenDeleteModal(false);
                        dispatch(resetLogCategory());
                        focusButtonComponent("add-log-category-btn");
                    }, onSecondaryClick: (event) => {
                        event.preventDefault();
                        setOpenDeleteModal(false);
                        focusButtonComponent("delete-log-category-btn");
                    }, modalContent: deleteModalInfo, modalHeading: modalHeading, ariaLabelValue: deleteLabel, modalId: "delete-modal", contentId: "content-delete-modal" })] }));
    };
    return isFetchingLogCategories ? (_jsx(Loading, {})) : (_jsxs("div", { children: [_jsx(Flyout, { id: "add-timelog-requirement-flyout", mainContent: mainContent(), closeFlyoutHandler: (event) => closeFlyoutHandler(event), heading: currentRequirement.uuid === ""
                    ? reqFlyoutHeadingText
                    : editTimeLogHeadingText, subHeading: mandatoryFieldInfo, primaryButton: translate("app.requirement.save"), primaryActionHandler: (event) => createTimeLogHandler(event), breadCrum: renderBreadCrum(), showSecondaryButton: false, secondaryButton: translate("app.cohort.secondaryButtonText"), secondaryButtonActionHandler: () => { }, disableActionButtons: false, showStatus: "" }), _jsx("div", { className: "sr-only", id: "dialog-top", tabIndex: 0 }), _jsx("div", { className: "sr-only", id: "dialog-wrapper-flyout-top", tabIndex: 0 }), openLogCategorySelection && (_jsx(SelectLogCategories, { setOpenLogCategorySelection: setOpenLogCategorySelection, selectedLogCategory: selectedLogCategory, setSelectedLogCategory: setSelectedLogCategory })), _jsx("div", { className: "sr-only", id: "dialog-wrapper-flyout-bottom", tabIndex: 0 }), _jsx("div", { className: "sr-only", id: "dialog-bottom", tabIndex: 0 })] }));
}
