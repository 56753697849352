import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { SideFlyout } from "../../common/component/SideFlyout";
import { useIntl } from "react-intl";
export const CohortFlyoutFooter = ({ closeFlyout, handleExportClick, }) => {
    const primaryButtonText = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.flyout.content.cohort.export",
    });
    const secondaryButtonText = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.flyout.content.cohort.cancel",
    });
    return (_jsx(SideFlyout.Footer, { children: _jsx("div", Object.assign({ style: {
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
            } }, { children: _jsxs("div", Object.assign({ className: "button-container" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "cancel-btn", onClick: closeFlyout }, { children: secondaryButtonText })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "apply-btn", onClick: handleExportClick }, { children: primaryButtonText }))] })) })) }));
};
