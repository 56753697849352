var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Flyout from "../common/component/Flyout";
import { useEffect, useState, useRef } from "react";
import Inputfield from "../common/component/InputField";
import translate from "../../i18n/translate";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { createRequirement, timelineSelector, resetNewRequirementFiles, resetFileNotifications, closeFileSnackbar, updateRequirement, addReferenceLink, resetReferenceLinksList, removeReferenceLink, updateReferenceLink, resetSnackNotifications, removeStudentAckSnack, } from "./TimelineSlice";
import { WmButton, WmUploader, WmSnackbar, WmToggletip, WmModal, WmModalHeader, WmModalFooter, WmActionMenu, WmMenuitem, WmDatepicker, WmTimepicker, WmFile, } from "@watermarkinsights/ripple-react";
import InformationModal from "../common/component/InformationWmModal";
import DeleteModal from "../common/component/DeleteWmModal";
import DiscardModal from "../common/component/DiscardWmModal";
import useFileupload from "../../features/common/hooks/useFileUpload";
import InputField from "../common/component/InputField";
import { focusActionMenuComponent, focusButtonComponent, isNullOrEmpty, urlPatternValidation, } from "../../static/js/commons.js";
import ComponentTextAreaField from "../programApplications/template-components/ComponentTextAreaField";
import { isStudentSubmissionExistForRequirement, cohortSelector, resetIsSubmissionExistForRequirement, } from "../cohorts/CohortSlice";
import { isEqual } from "lodash";
import { format } from "date-fns";
// commented certain lines of code for hiding reference link related functionality until further intimation from product team
export default function Requirements({ addRequirement, setAddRequirementPage, requirementId, timelineUuid, sectionType, currentRequirement, setSelectRequirementType, }) {
    var _a;
    const dispatch = useDispatch();
    let [enteredTitle, setenteredTitle] = useState("");
    let [enteredDescription, setenteredDescription] = useState("");
    let [titleError, settitleError] = useState(false);
    let [conditionError, setConditionError] = useState(false);
    let [studentConfirmationError, setStudentConfirmationError] = useState(false);
    let [isFocused, setFocused] = useState(false);
    let [isAckFocused, setAckFocused] = useState(false);
    let [studentUploadReqCheckbox, setStudentUploadReqCheckbox] = useState(false);
    let [studentSignCheckbox, setStudentSignCheckbox] = useState(false);
    // let [referenceLink, setReferenceLink] = useState<string>("");
    let [errorCount, setErrorCount] = useState(0);
    let [validationCount, setValidationCount] = useState(0);
    const [openDeleteFileModal, setOpenDeleteFileModal] = useState(false);
    const [deleteFileUuid, setDeleteFileUuid] = useState("");
    let [newlyAddedReqPerSelection, setNewlyAddedReqPerSelection] = useState([]);
    let [isFilesChanged, setIsFilesChanged] = useState(false);
    const [openDiscardModal, setOpenDiscardModal] = useState(false);
    let [isContentModified, setIsContentModified] = useState(false);
    const [count, setCount] = useState(0);
    const [isEditLink, setIsEditLink] = useState(false);
    const [updateIndex, setUpdateIndex] = useState();
    const [focusId, setFocusId] = useState("");
    const [openAddLinkModal, setOpenAddLinkModal] = useState(false);
    const [linkInput, setLinkInput] = useState("");
    const [linkDisplayText, setLinkDisplayText] = useState("");
    const [invalidLinkError, setInvalidLinkError] = useState(false);
    const [isLinkEmpty, setIsLinkEmpty] = useState(false);
    const maxRefLinksAllowed = 25;
    const { isRequirementModifiable, newRequirementFiles, fileNotifications, referenceLinksList, } = useSelector(timelineSelector);
    const { isSubmissionExistForRequirement } = useSelector(cohortSelector);
    const elementRef = useRef();
    const inputRef = useRef();
    const inputLinkRef = useRef();
    const inputLinkDisplayRef = useRef();
    let currentReferenceLinksList = currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.referenceLinksList;
    const checkboxTextErrorText = useIntl().formatMessage({
        id: "app.requirement.acknowledgement.description",
    });
    const [checkBoxList, setCheckBoxList] = useState(((_a = currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.requirementConfirmationCheckboxes) === null || _a === void 0 ? void 0 : _a.length) > 0
        ? currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.requirementConfirmationCheckboxes
        : []);
    const [openChangeReqTypeModal, setOpenChangeReqTypeModal] = useState(false);
    const changeReqTypeModalHeading = useIntl().formatMessage({
        id: "app.timelog.changeReqTypeModal.heading",
    });
    const changeReqTypeModalContent = useIntl().formatMessage({
        id: "app.timelog.changeReqTypeModal.content",
    });
    const changeReqTypeModalPrimaryText = useIntl().formatMessage({
        id: "app.timelog.changeReqTypeModal.primaryText",
    });
    const getUTCDate = (date) => {
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    };
    const deleteCheckbox = (event, index) => {
        event.preventDefault();
        let options = [...checkBoxList];
        options.splice(index, 1);
        dispatch(removeStudentAckSnack());
        setCheckBoxList(options.map((opt, i) => {
            return Object.assign(Object.assign({}, opt), { sortOrder: i });
        }));
        if (options.length > 0) {
            focusButtonComponent("remove-option-btn-" + (index - 1));
        }
        else {
            focusButtonComponent("add-ack-checkbox-btn");
        }
    };
    const addCheckbox = (event) => {
        event.preventDefault();
        setCheckBoxList([
            ...checkBoxList,
            {
                uuid: "",
                text: "",
                sortOrder: checkBoxList.length,
                isEmpty: false,
            },
        ]);
        setTimeout(() => {
            const ele = document.getElementById("input-ack-" + checkBoxList.length);
            ele === null || ele === void 0 ? void 0 : ele.scrollIntoView();
            ele === null || ele === void 0 ? void 0 : ele.focus();
        }, 10);
    };
    useEffect(() => {
        if (addRequirement) {
            inputRef.current.focus();
        }
    }, [addRequirement]);
    useEffect(() => {
        dispatch(resetReferenceLinksList(currentReferenceLinksList));
    }, [currentRequirement]);
    const closeFlyoutHandler = (event) => {
        var _a;
        event.preventDefault();
        if (isContentModified) {
            setOpenDiscardModal(true);
        }
        else {
            dispatch(resetNewRequirementFiles([]));
            dispatch(resetFileNotifications());
            setAddRequirementPage(false);
            if (currentRequirement.uuid === "") {
                setSelectRequirementType(true);
                (_a = document.getElementById("standard-req")) === null || _a === void 0 ? void 0 : _a.focus();
            }
        }
    };
    const updateTitleHandler = (event) => {
        event.preventDefault();
        if (currentRequirement.uuid === "") {
            if (event.target.value !== "") {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        else {
            if (event.target.value !== currentRequirement.title) {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        setenteredTitle(event.target.value);
        if (validationCount > 0 && titleError) {
            setErrorCount((prev) => prev - 1);
            settitleError(false);
        }
    };
    const finishedSnackHandler = (event) => {
        event.preventDefault();
        dispatch(closeFileSnackbar(event.detail.id));
    };
    const renderInformationModal = (isOpen) => {
        let modalHeading = "Requirement can not be edited";
        let modalContent = "This Requirement can not be edited because it has received submissions from students in its associated cohorts.";
        let ariaLabelValue = "Delete Requirement";
        return (_jsx(InformationModal, { isOpen: isOpen, onPrimaryClick: () => setAddRequirementPage(false), modalContent: modalContent, modalHeading: modalHeading, ariaLabelValue: ariaLabelValue, modalId: "information-modal", contentId: "content-information-modal" }));
    };
    const focusHandler = (isfocus) => {
        setFocused(isfocus);
    };
    const ackFocusHandler = (isfocus) => {
        setAckFocused(isfocus);
    };
    const updateDesciptionHandler = (event) => {
        event.preventDefault();
        if (currentRequirement.uuid === "") {
            if (event.target.value !== "") {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        else {
            if (event.target.value !== currentRequirement.description) {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        setenteredDescription(event.target.value);
    };
    const studentUploadReqCheckboxHanlder = (value) => {
        setStudentUploadReqCheckbox(value);
        if (currentRequirement.uuid === "") {
            if (value !== null) {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        else {
            if (value !== currentRequirement.isStudentFileUploadRequired) {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
    };
    const closeErrorToolbar = () => {
        setErrorCount(0);
    };
    const dateValidationHandler = (errCount) => {
        var _a, _b;
        setIsDateEmpty(dueDate.trim() === "");
        if (dueDate.trim() === "" || isPastDate) {
            errCount += 1;
            if (enteredTitle.trim() !== "") {
                (_a = document.getElementById("due-date-picker")) === null || _a === void 0 ? void 0 : _a.focus();
            }
        }
        setIsTimeEmpty(dueTime.trim() === "");
        if (dueTime.trim() === "" || isPastTime) {
            errCount += 1;
            if (enteredTitle.trim() !== "" && dueDate.trim() !== "" && !isPastDate) {
                (_b = document.getElementById("due-time-picker")) === null || _b === void 0 ? void 0 : _b.focus();
            }
        }
        return errCount;
    };
    const createRequirementHandler = (event) => {
        let count = 0;
        if (isNullOrEmpty(enteredTitle.trim())) {
            settitleError(true);
            count++;
        }
        let error = false;
        const checkBoxes = checkBoxList === null || checkBoxList === void 0 ? void 0 : checkBoxList.map((chkboxItem) => {
            var _a;
            if (isNullOrEmpty((_a = chkboxItem.text) === null || _a === void 0 ? void 0 : _a.trim())) {
                error = true;
                count++;
                return Object.assign(Object.assign({}, chkboxItem), { isEmpty: true });
            }
            else {
                return Object.assign(Object.assign({}, chkboxItem), { isEmpty: false });
            }
        });
        setCheckBoxList(checkBoxes);
        if (error) {
            if (!isNullOrEmpty(enteredTitle.trim())) {
                let tempIndex = checkBoxes.findIndex((checkBox) => checkBox.isEmpty === true);
                setTimeout(() => {
                    var _a;
                    (_a = document.getElementById("input-ack-" + tempIndex)) === null || _a === void 0 ? void 0 : _a.focus();
                }, 10);
            }
        }
        if (count === 0) {
            let uuid = null;
            if (currentRequirement.uuid === "") {
                uuid = requirementId.substring(23, requirementId.length);
            }
            else {
                uuid =
                    sectionType === "sections"
                        ? currentRequirement.sectionUuid
                        : currentRequirement.subSectionUuid;
            }
            const dueDateVal = new Date(dueDate + "T00:00:00");
            const dueDateTime = new Date(dueDateVal.getFullYear(), dueDateVal.getMonth(), dueDateVal.getDate(), isNullOrEmpty(dueTime) ? 0 : parseInt(dueTime.slice(0, 2)), isNullOrEmpty(dueTime) ? 0 : parseInt(dueTime.slice(3)));
            const data = Object.assign(Object.assign({}, currentRequirement), { timelineUuid: timelineUuid, sectionUuid: sectionType === "sections" ? uuid : null, subSectionUuid: sectionType === "sections" ? null : uuid, title: enteredTitle, description: enteredDescription, isStudentFileUploadRequired: studentUploadReqCheckbox, sectionType: sectionType, uuid: currentRequirement.uuid, referenceLinksList: referenceLinksList, files: attachedFiles, sortOrder: currentRequirement.sortOrder, applicationUuid: null, requirementType: "REQUIREMENT", dueDate: isDueDateVisible ? dueDateTime : null, confirmationCheckboxes: checkBoxList });
            if (currentRequirement.uuid === "") {
                dispatch(createRequirement(data));
                setAddRequirementPage(false);
                dispatch(resetFileNotifications());
                dispatch(resetSnackNotifications());
                dispatch(resetNewRequirementFiles([]));
                dispatch(resetReferenceLinksList([]));
            }
            else {
                if (!isEqual(data, Object.assign(Object.assign({}, currentRequirement), { dueDate: !isNullOrEmpty(currentRequirement.dueDate)
                        ? new Date(currentRequirement.dueDate)
                        : null, sectionType, confirmationCheckboxes: currentRequirement.requirementConfirmationCheckboxes }))) {
                    dispatch(updateRequirement(data));
                }
                setAddRequirementPage(false);
                dispatch(resetFileNotifications());
                dispatch(resetSnackNotifications());
                dispatch(resetNewRequirementFiles([]));
                dispatch(resetReferenceLinksList([]));
            }
        }
        setValidationCount((prev) => prev + 1);
    };
    // Keeping this (evaluteEditCommand) function in case it is required in future
    // Commented it as it was using validateAndUpdateRequirement, which in turn was using store. to resolve circular dependency, commented the called and this function
    // const evaluteEditCommand = (
    //   data: any,
    //   currentRequirement: initialRequirementStateProps
    // ) => {
    //   let editCommand;
    //   let requirementUuid = currentRequirement.uuid;
    //   if (
    //     data.title === currentRequirement.title &&
    //     data.description === currentRequirement.description
    //   ) {
    //     if (
    //       data.isStudentFileUploadRequired ===
    //         currentRequirement.isStudentFileUploadRequired &&
    //       data.allowStudentConfirmation ===
    //         currentRequirement.allowStudentConfirmation &&
    //       data.studentConfirmationLabel ===
    //         currentRequirement.studentConfirmationLabel &&
    //       // data.referenceLink === currentRequirement.referenceLink &&
    //       !isFilesChanged
    //     ) {
    //       editCommand = "";
    //       setAddRequirementPage(false);
    //       dispatch(resetFileNotifications());
    //     } else {
    //       editCommand = validateAndUpdateRequirement(
    //         timelineUuid,
    //         requirementUuid,
    //         data,
    //         setAddRequirementPage
    //       );
    //     }
    //   } else {
    //     editCommand = validateAndUpdateRequirement(
    //       timelineUuid,
    //       requirementUuid,
    //       data,
    //       setAddRequirementPage
    //     );
    //   }
    //   return editCommand;
    // };
    const closeDeleteModalHandler = (event) => {
        event.preventDefault();
        setDeleteFileUuid("");
        setOpenDeleteFileModal(false);
    };
    const closeDiscardModalHandler = (event) => {
        var _a;
        event.preventDefault();
        setOpenDiscardModal(false);
        (_a = document.getElementById("input-requirement")) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const discardChanges = (event) => __awaiter(this, void 0, void 0, function* () {
        var _b;
        event.preventDefault();
        setIsFilesChanged(false);
        dispatch(resetNewRequirementFiles([]));
        dispatch(resetFileNotifications());
        setAddRequirementPage(false);
        if (currentRequirement.uuid === "") {
            setSelectRequirementType(true);
            (_b = document.getElementById("standard-req")) === null || _b === void 0 ? void 0 : _b.focus();
        }
    });
    const deleteFileAction = (event) => {
        event.preventDefault();
        // Don't hard delete files, let them be orphan and cleaned by Orphan file cleanup job
        // deleteFile(newRequirementFiles, deleteFileUuid);
        onWmUploaderDeleteFile({
            detail: deleteFileUuid,
            preventDefault: () => { },
        });
        setIsFilesChanged(true);
        setOpenDeleteFileModal(false);
        setIsContentModified(true);
    };
    useEffect(() => {
        if (!isNullOrEmpty(currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.uuid)) {
            dispatch(isStudentSubmissionExistForRequirement({
                requirementUuid: currentRequirement.uuid,
            }));
        }
        else {
            dispatch(resetIsSubmissionExistForRequirement());
        }
        setenteredTitle(currentRequirement.title);
        setenteredDescription(currentRequirement.description);
        setStudentUploadReqCheckbox(currentRequirement.isStudentFileUploadRequired);
    }, []);
    useEffect(() => {
        if (titleError) {
            inputRef.current.focus();
        }
    }, [titleError, validationCount]);
    useEffect(() => {
        var _a;
        if (conditionError && !titleError) {
            (_a = document.getElementById("condition-card")) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [conditionError, validationCount]);
    useEffect(() => {
        var _a;
        if (studentConfirmationError && !titleError) {
            (_a = document.getElementById("student-confirmation")) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [studentConfirmationError, validationCount]);
    const toggletipFileUpload = useIntl().formatMessage({
        id: "app.requirement.toggletip_file_upload",
    });
    const toggletipStudentAck = useIntl().formatMessage({
        id: "app.requirement.toggletip_student_ack",
    });
    const modalContent = useIntl().formatMessage({
        id: "app.requirement.deleteModalContent",
    });
    const modalHeading = useIntl().formatMessage({
        id: "app.requirement.deleteModalHeading",
    });
    const uploaderInfo = useIntl().formatMessage({
        id: "app.requirement.uploadInfo",
    });
    const uploaderButtonText = useIntl().formatMessage({
        id: "app.requirement.uploadNewDocument",
    });
    const uploaderEmptyStateText = useIntl().formatMessage({
        id: "app.requirement.emptyStateText",
    });
    const acknowledgementLabelDesc = useIntl().formatMessage({
        id: "app.requirement.acknowledgement.description",
    });
    const referenceLinkDesc = useIntl().formatMessage({
        id: "app.requirement.referencelink.description",
    });
    const toggleTipFileUploadCheckBoxLabel = useIntl().formatMessage({
        id: "app.requirement.toggletip.fileupload.label",
    });
    const toggleTipCheckBoxLabel = useIntl().formatMessage({
        id: "app.requirement.toggletip.checkbox.label",
    });
    const ackTextLabel = useIntl().formatMessage({
        id: "app.requirement.acknowlegdementText.label",
    });
    // const referenceLinkLabel = useIntl().formatMessage({
    //   id: "app.requirement.referenceLink.label",
    // });
    // const referenceLinkPlaceholder = useIntl().formatMessage({
    //   id: "app.requirement.referenceLink.placeholder",
    // });
    const discardModalHeading = useIntl().formatMessage({
        id: "app.requirement.discardModal.heading",
    });
    const discardModalContent = useIntl().formatMessage({
        id: "app.requirement.discardModal.content",
    });
    const discardModalAriaLabelValue = useIntl().formatMessage({
        id: "app.requirement.discardModal.ariaLabelValue",
    });
    const discardModalPrimaryText = useIntl().formatMessage({
        id: "app.requirement.discardModal.primaryText",
    });
    const discardModalSecondaryText = useIntl().formatMessage({
        id: "app.requirement.discardModal.secondayText",
    });
    let uploaderRef = useRef();
    const [attachedFiles, deletedFiles, uploadInProgress, , onWmUploaderFilesSelected, onWmUploaderDownloadFile, onWmUploaderDeleteFile, setAttachedFiles, , currentFiles, rejectedFiles, setRejectedFiles,] = useFileupload({
        app: "GATEWAYS",
        type: "REQUIREMENT",
        initialAttachments: currentRequirement === null || currentRequirement === void 0 ? void 0 : currentRequirement.files,
        componentRef: uploaderRef,
    });
    const [uploaderComponentFiles, setUploaderComponentFiles] = useState([]);
    useEffect(() => {
        const uploaderFiles = attachedFiles.map((file) => {
            let progressIndicator = uploadInProgress && currentFiles.indexOf(file.uuid) > -1
                ? { progress: 0 }
                : { progress: 100 };
            let uploaderFile = Object.assign({ id: file.uuid, name: file.file_name.substr(0, file.file_name.lastIndexOf(".")), type: file.file_name.substr(file.file_name.lastIndexOf(".") + 1), lastUpdated: file.inserted_at, fileActions: uploadInProgress ? "download" : "download delete", errorMessage: file.errorMessage }, progressIndicator);
            return uploaderFile;
        });
        setUploaderComponentFiles(uploaderFiles);
    }, [attachedFiles]); //uploadInProgress
    const deleteFileHandler = (event, fileId) => {
        setDeleteFileUuid(fileId);
        setOpenDeleteFileModal(true);
        setIsContentModified(true);
    };
    const addLinkModalHeading = useIntl().formatMessage({
        id: "app.requirement.reference.links.add.btn",
    });
    const editLinkModalHeading = useIntl().formatMessage({
        id: "app.requirement.reference.links.edit.link",
    });
    const addLinkModalSubHeading = useIntl().formatMessage({
        id: "app.viewTimeline.modal.modalSubHeading",
    });
    const addLinkModalPrimaryText = useIntl().formatMessage({
        id: "app.requirement.add",
    });
    const editLinkModalPrimaryText = useIntl().formatMessage({
        id: "app.requirement.save",
    });
    const urlRequiredErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.insertComponent.resource.link.error.message.blank",
    });
    const urlInvalidErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.insertComponent.resource.link.error.message.invalid",
    });
    useEffect(() => {
        if (focusId != "") {
            if (referenceLinksList.length) {
                focusActionMenuComponent(focusId);
            }
            else {
                focusButtonComponent("add-links-btn");
            }
        }
    }, [referenceLinksList]);
    const closeAddLinkModal = () => {
        setOpenAddLinkModal(false);
        setIsLinkEmpty(false);
        setInvalidLinkError(false);
        setLinkDisplayText("");
        setLinkInput("");
        setIsEditLink(false);
        if (isEditLink && referenceLinksList.length > 0) {
            focusActionMenuComponent(focusId);
        }
        else {
            focusButtonComponent("add-links-btn");
        }
    };
    const addLinkHandler = () => {
        var _a;
        if (linkInput.trim() === "") {
            setIsLinkEmpty(true);
            setInvalidLinkError(false);
        }
        else if (!urlPatternValidation(linkInput)) {
            setIsLinkEmpty(false);
            setInvalidLinkError(true);
        }
        else {
            if (isEditLink) {
                dispatch(updateReferenceLink({
                    updateIndex,
                    url: linkInput,
                    displayText: linkDisplayText,
                }));
                setIsEditLink(false);
            }
            else {
                dispatch(addReferenceLink({
                    url: linkInput,
                    displayText: linkDisplayText,
                }));
            }
            setOpenAddLinkModal(false);
            setLinkInput("");
            setLinkDisplayText("");
        }
        if (!urlPatternValidation(linkInput) || linkInput.trim() === "") {
            (_a = document.getElementById("input-url")) === null || _a === void 0 ? void 0 : _a.focus();
        }
    };
    const validateURLHandler = (event) => {
        const { value } = event.target;
        if (value) {
            setInvalidLinkError(!urlPatternValidation(value));
        }
        else {
            setInvalidLinkError(false);
        }
    };
    const discardReqTypeChanges = (event) => __awaiter(this, void 0, void 0, function* () {
        var _c;
        event.preventDefault();
        setIsFilesChanged(false);
        dispatch(resetNewRequirementFiles([]));
        dispatch(resetFileNotifications());
        setAddRequirementPage(false);
        if (currentRequirement.uuid === "") {
            setSelectRequirementType(true);
            (_c = document.getElementById("standard-req")) === null || _c === void 0 ? void 0 : _c.focus();
        }
    });
    const closeChangeReqTypeModalHandler = (event) => {
        var _a;
        event.preventDefault();
        setOpenChangeReqTypeModal(false);
        (_a = document.getElementById("input-requirement")) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const renderAddLinkModal = (isOpen) => {
        if (!isOpen) {
            return;
        }
        return (_jsxs(WmModal, Object.assign({ open: isOpen, elementToFocus: "input-url", id: "add-link-modal", onWmModalCloseTriggered: () => closeAddLinkModal(), onWmModalPrimaryTriggered: () => addLinkHandler(), onWmModalSecondaryTriggered: () => closeAddLinkModal(), "aria-label": isEditLink ? editLinkModalHeading : addLinkModalHeading, uid: "" }, { children: [_jsx(WmModalHeader, { heading: isEditLink ? editLinkModalHeading : addLinkModalHeading, subheading: addLinkModalSubHeading }), _jsx("div", Object.assign({ id: "content-add-link-modal", className: "content" }, { children: _jsxs("div", Object.assign({ className: "modal-body" }, { children: [_jsxs("div", Object.assign({ className: "url-input-field" }, { children: [_jsxs("label", Object.assign({ className: "textfieldlabel " +
                                            (isLinkEmpty || invalidLinkError ? "alert-label" : ""), htmlFor: "input-url" }, { children: ["URL", _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), (isLinkEmpty || invalidLinkError) && (_jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), _jsxs("div", Object.assign({ className: "textfield " +
                                            (isLinkEmpty || invalidLinkError ? "alert-box" : "") }, { children: [_jsx("div", Object.assign({ className: "charcount" }, { children: _jsx("input", { className: "textfieldinput " +
                                                        (isLinkEmpty || invalidLinkError ? "invalid" : ""), id: "input-url", type: "text", required: true, "aria-required": true, value: linkInput, onChange: (event) => {
                                                        event.preventDefault();
                                                        setLinkInput(event.target.value);
                                                        if (event.target.value.trim() != "") {
                                                            setIsLinkEmpty(false);
                                                        }
                                                    }, onBlur: validateURLHandler, ref: inputLinkRef, "aria-labelledby": "link-error" }) })), _jsx("div", Object.assign({ className: "error-info" }, { children: _jsx("div", Object.assign({ className: "error input-error", id: "link-error" }, { children: invalidLinkError
                                                        ? urlInvalidErrorMessage
                                                        : isLinkEmpty
                                                            ? urlRequiredErrorMessage
                                                            : "" })) }))] }))] })), _jsx("div", Object.assign({ className: "display-txt-field" }, { children: _jsx(InputField, { titleError: false, dataLabel: "Display Text", modalTitle: linkDisplayText, updateTitleHandler: (event) => {
                                        event.preventDefault();
                                        setLinkDisplayText(event.target.value);
                                    }, inputRef: inputLinkDisplayRef, modalFor: "display-text", modalId: "display-text", maxLength: 140 }) })), _jsx("span", Object.assign({ className: "info-text" }, { children: translate("app.programApplications.component.link.resource.display.info.text") }))] })) })), _jsx(WmModalFooter, { primaryText: isEditLink ? editLinkModalPrimaryText : addLinkModalPrimaryText, secondaryText: discardModalSecondaryText })] })));
    };
    const removeLinkHandler = (index) => {
        dispatch(removeReferenceLink({ id: index }));
        if (index === 0) {
            focusActionMenuComponent("link-menu-" + index);
        }
        else {
            focusActionMenuComponent("link-menu-" + (index - 1));
        }
        if ((referenceLinksList === null || referenceLinksList === void 0 ? void 0 : referenceLinksList.length) === 1) {
            focusButtonComponent("add-links-btn");
        }
    };
    const editLinkHandler = (linkObj, index) => {
        setOpenAddLinkModal(true);
        setIsEditLink(true);
        setLinkInput(linkObj.url);
        setLinkDisplayText(linkObj.displayText);
        setUpdateIndex(index);
        setFocusId("link-menu-" + index);
    };
    const getLinkUrl = (url) => {
        let link = url.indexOf("://") != -1 && url.substring(0, url.indexOf("://")) != null
            ? url
            : "http://" + url;
        return link;
    };
    const renderReferenceLinks = () => {
        return (_jsx("div", Object.assign({ className: "reference-links-list" }, { children: referenceLinksList === null || referenceLinksList === void 0 ? void 0 : referenceLinksList.map((link, index) => {
                return (_jsxs("div", Object.assign({ className: "reference-link-item flex-row -justified -verticallycentered" }, { children: [_jsxs("div", Object.assign({ className: "reference-link-container" }, { children: [_jsx("div", Object.assign({ className: "reference-link-display-text" }, { children: _jsxs("a", Object.assign({ href: getLinkUrl(link.url), className: "reference-anchor-link", target: "_blank" }, { children: [link.displayText ? link.displayText : link.url, _jsx("span", { className: "mdi mdi-open-in-new open-icon" })] })) })), _jsx("div", Object.assign({ className: "reference-link-url" }, { children: link.url.length > 57 ? (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "firstPart" }, { children: link.url.slice(0, link.url.length - 11) })), _jsx("div", Object.assign({ className: "lastPart" }, { children: link.url.slice(-9) }))] })) : (link.url) }))] })), _jsxs(WmActionMenu, Object.assign({ "action-menu-type": "icon", tooltip: "Options", id: "link-menu-" + index }, { children: [_jsx(WmMenuitem, Object.assign({ icon: "f3eb", id: "edit-link-" + index, onClick: () => editLinkHandler(link, index) }, { children: translate("app.viewTimeline.edit.requirement") })), _jsx(WmMenuitem, Object.assign({ icon: "f1c0", id: "remove-link-" + index, onClick: () => removeLinkHandler(index) }, { children: translate("app.requirement.remove") }))] }))] }), index));
            }) })));
    };
    const [isDueDateVisible, setIsDueDateVisible] = useState(false);
    const savedDueDate = new Date(currentRequirement.dueDate);
    const [dueDate, setDueDate] = useState(currentRequirement.dueDate
        ? savedDueDate.getFullYear() +
            "-" +
            (savedDueDate.getMonth() < 10
                ? "0" + (savedDueDate.getMonth() + 1)
                : savedDueDate.getMonth()) +
            "-" +
            (savedDueDate.getDate() < 10
                ? "0" + savedDueDate.getDate()
                : savedDueDate.getDate())
        : "");
    const [dueTime, setDueTime] = useState(currentRequirement.dueDate
        ? (savedDueDate.getHours() < 10
            ? "0" + savedDueDate.getHours()
            : savedDueDate.getHours()) +
            "" +
            (savedDueDate.getMinutes() < 10
                ? "0" + savedDueDate.getMinutes()
                : savedDueDate.getMinutes())
        : "");
    let currentRequirementTime = currentRequirement.dueDate
        ? (savedDueDate.getHours() < 10
            ? "0" + savedDueDate.getHours()
            : savedDueDate.getHours()) +
            "" +
            (savedDueDate.getMinutes() < 10
                ? "0" + savedDueDate.getMinutes()
                : savedDueDate.getMinutes())
        : "";
    let currentRequirementDate = currentRequirement.dueDate
        ? savedDueDate.getFullYear() +
            "-" +
            (savedDueDate.getMonth() < 10
                ? "0" + (savedDueDate.getMonth() + 1)
                : savedDueDate.getMonth()) +
            "-" +
            (savedDueDate.getDate() < 10
                ? "0" + savedDueDate.getDate()
                : savedDueDate.getDate())
        : "";
    const [isDateEmpty, setIsDateEmpty] = useState(false);
    const [isTimeEmpty, setIsTimeEmpty] = useState(false);
    const [isPastDate, setIsPastDate] = useState(false);
    const [isPastTime, setIsPastTime] = useState(false);
    const dueDatePickerLabel = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.date.label",
    });
    const dueTimePickerLabel = useIntl().formatMessage({
        id: "app.programApplications.distribute.application.schedule.time.label",
    });
    const emptyDateErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.schedule.empty.date",
    });
    const emptyTimeErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.schedule.empty.time",
    });
    const pastDateErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.schedule.past.date",
    });
    const pastTimeErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.schedule.past.time",
    });
    const reqFlyoutHeadingText = useIntl().formatMessage({
        id: "app.licensure.plan.select.requirement.type.create.requirement",
    }, {
        type: "Standard",
    });
    const reqTypeHeading = useIntl().formatMessage({
        id: "app.licensure.plan.select.requirement.type.standard.req.type",
    }, {
        type: "Standard",
    });
    const currentDate = new Date();
    const currentDateObj = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    const currentDateTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes());
    const getDueDateTimeValue = (dueDateVal, dueTime) => {
        return new Date(dueDateVal.getFullYear(), dueDateVal.getMonth(), dueDateVal.getDate(), isNullOrEmpty(dueTime) ? 0 : parseInt(dueTime.slice(0, 2)), isNullOrEmpty(dueTime) ? 0 : parseInt(dueTime.slice(3)));
    };
    const validateSelectedDate = (event) => {
        if (currentRequirement.uuid === "") {
            if (event.target.value !== "") {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        else {
            if (event.target.value !== currentRequirementDate) {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        if (!isNullOrEmpty(event.target.value)) {
            setDueDate(event.target.value);
            setIsDateEmpty(false);
            const dueDateVal = new Date(event.target.value + "T00:00:00");
            const dueDateObj = new Date(dueDateVal.getFullYear(), dueDateVal.getMonth(), dueDateVal.getDate());
            setIsPastDate(dueDateObj < currentDateObj);
            if (dueTime.trim() !== "") {
                let dueDateTime = getDueDateTimeValue(dueDateVal, dueTime);
                if (event.target.value !== currentRequirementDate) {
                    setIsPastTime(dueDateTime.getTime() < currentDateTime.getTime());
                }
                else {
                    setIsPastDate(false);
                    setIsPastTime(false);
                }
            }
        }
        else {
            setDueDate("");
        }
    };
    const validateSelectedTime = (event) => {
        let newTime = currentRequirementTime.substring(0, 2) +
            ":" +
            currentRequirementTime.substring(2, 4);
        if (currentRequirement.uuid === "") {
            if (event.target.value !== "") {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        else {
            if (event.target.value !== newTime) {
                setIsContentModified(true);
            }
            else {
                setIsContentModified(false);
            }
        }
        if (!isNullOrEmpty(event.target.value)) {
            setDueTime(event.target.value);
            setIsTimeEmpty(false);
            const dueDateVal = new Date(dueDate + "T00:00:00");
            const dueDateTime = getDueDateTimeValue(dueDateVal, event.target.value);
            if (currentRequirement.uuid !== "") {
                if (event.target.value !== newTime) {
                    setIsPastTime(dueDateTime.getTime() < currentDateTime.getTime());
                }
                else {
                    setIsPastTime(false);
                }
            }
            else {
                setIsPastTime(dueDateTime.getTime() < currentDateTime.getTime());
            }
        }
        else {
            setDueTime("");
        }
    };
    const renderDueDateField = () => {
        return (_jsxs("div", Object.assign({ className: "due-date-field" }, { children: [_jsxs("div", Object.assign({ className: "due-date-toggle" }, { children: [_jsx("h5", Object.assign({ className: "due-date-label" }, { children: translate("app.requirement.due.date.header.label") })), _jsxs("span", Object.assign({ className: "toggle-switch" }, { children: [_jsx("input", { id: "chk-due-date", type: "checkbox", checked: isDueDateVisible, onChange: () => setIsDueDateVisible(!isDueDateVisible) }), _jsx("label", Object.assign({ className: "switchtoggle", htmlFor: "chk-due-date", "aria-label": "Due Date" }, { children: !isDueDateVisible ? (_jsx("span", Object.assign({ className: "toggle-text" }, { children: translate("app.licensurePlans.toggle.off") }))) : (_jsx("span", Object.assign({ className: "toggle-text on" }, { children: translate("app.licensurePlans.toggle.on") }))) }))] }))] })), isDueDateVisible && (_jsxs(_Fragment, { children: [_jsx("p", Object.assign({ className: "due-date-info" }, { children: translate("app.requirement.due.date.field.info.text") })), _jsxs("div", Object.assign({ className: "due-date-picker" }, { children: [_jsxs("label", Object.assign({ className: `textfieldlabel ${isDateEmpty || isPastDate ? "alert-label" : ""}`, htmlFor: "due-date-picker" }, { children: [translate("app.programApplications.distribute.application.schedule.date.label"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), (isDateEmpty || isPastDate) && (_jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), _jsx(WmDatepicker, { id: "due-date-picker", label: dueDatePickerLabel, labelPosition: "none", value: dueDate, onInput: (event) => validateSelectedDate(event), onChange: (event) => validateSelectedDate(event), errorMessage: isDateEmpty
                                        ? emptyDateErrorMessage
                                        : isPastDate
                                            ? pastDateErrorMessage
                                            : "" })] })), _jsxs("div", Object.assign({ className: "due-time-picker" }, { children: [_jsxs("label", Object.assign({ className: `textfieldlabel ${isTimeEmpty || isTimeEmpty ? "alert-label" : ""}`, htmlFor: "due-time-picker" }, { children: [translate("app.programApplications.distribute.application.schedule.time.label"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), (isTimeEmpty || isTimeEmpty) && (_jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), _jsx(WmTimepicker, { labelPosition: "none", label: dueTimePickerLabel, id: "due-time-picker", value: dueTime, onInput: (event) => validateSelectedTime(event), onChange: (event) => validateSelectedTime(event), errorMessage: isTimeEmpty
                                        ? emptyTimeErrorMessage
                                        : isPastTime
                                            ? pastTimeErrorMessage
                                            : "" })] }))] }))] })));
    };
    const renderBreadCrum = () => {
        return (currentRequirement.uuid === "" && (_jsx("div", Object.assign({ className: "go-back-link program-header flex-row -justified -verticallycentered" }, { children: _jsxs("div", Object.assign({ className: "bread-crumbs" }, { children: [_jsx(WmButton, Object.assign({ "button-type": "textonly", id: "go-back", className: "link", onClick: () => {
                            setAddRequirementPage(false);
                            setSelectRequirementType(true);
                        } }, { children: _jsx("span", { children: translate("app.licensure.plan.select.requirement.type.label") }) })), _jsx("span", { children: reqTypeHeading })] })) }))));
    };
    const mainContent = () => {
        return (_jsxs("div", Object.assign({ className: "" }, { children: [errorCount > 0 && validationCount > 0 ? (_jsxs("div", Object.assign({ className: "error-toolbar flex-row -justified -verticallycentered" }, { children: [_jsx("div", {}), _jsx("div", Object.assign({ className: "error-details", "aria-live": "assertive", "aria-atomic": true, role: "alert" }, { children: errorCount > 1
                                ? errorCount +
                                    " errors found, please check all required fields."
                                : translate("app.requirement.error_toolbar") })), _jsx(WmButton, { buttonType: "navigational", tooltip: "Close banner", icon: "f156", class: "cancel hydrated", id: "close-error", "custom-background": "dark", tooltipPosition: "bottom-left", onClick: closeErrorToolbar })] }))) : (""), _jsxs("div", Object.assign({ className: errorCount > 0 && validationCount > 0
                        ? "flyout-content -errors flyout-data"
                        : "flyout-content no-errors flyout-data" }, { children: [_jsxs("div", Object.assign({ className: "paper-card section-card edit -card-width requirement-card" }, { children: [_jsxs("div", Object.assign({ className: "header section-header flex-row -justified -verticallycentered" }, { children: [_jsx("h2", Object.assign({ className: "title" }, { children: translate("app.requirement.details_block_title") })), currentRequirement.uuid == "" && (_jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "f3eb", id: "change-requirement-type", onClick: () => {
                                                setOpenChangeReqTypeModal(true);
                                            } }, { children: translate("app.timelog.requirement.change.req.type") })))] })), _jsxs("div", Object.assign({ className: "body" }, { children: [_jsx(Inputfield, { titleError: titleError, dataLabel: "Title", modalTitle: enteredTitle, updateTitleHandler: updateTitleHandler, inputRef: inputRef, modalFor: "requirement", modalId: "requirement", required: true }), _jsxs("div", Object.assign({ className: "description-field" }, { children: [_jsx("label", Object.assign({ className: "textfieldlabel ", htmlFor: "requirement-description" }, { children: translate("global.description") })), _jsx("div", Object.assign({ className: isFocused ? "textfield is-focused" : "textfield" }, { children: _jsxs("div", Object.assign({ className: "charcount -textarea" }, { children: [_jsx("textarea", { className: "textfieldinput autosize-textarea", id: "requirement-description", "aria-required": "true", value: enteredDescription, maxLength: 500, "aria-describedby": "field-description", onChange: updateDesciptionHandler, onFocus: () => focusHandler(true), onBlur: () => focusHandler(false), "aria-label": "Description" }), _jsx("div", Object.assign({ className: "count", "aria-live": "polite", "aria-atomic": true, "aria-label": enteredDescription.length < 500
                                                                    ? "Character Entered"
                                                                    : "Character Limit has been reached" }, { children: String(enteredDescription.length) + "/500" })), _jsx("span", Object.assign({ id: "field-description", className: "max-count-label" }, { children: "Description has character limit of 500" }))] })) })), _jsx("div", Object.assign({ className: "help-text no-sections-info" }, { children: translate("app.requirement.description_info") }))] }))] }))] })), _jsxs("div", Object.assign({ className: "paper-card section-card edit -card-width requirement-card reference-materials-card" }, { children: [_jsxs("div", Object.assign({ className: "header section-header" }, { children: [_jsx("h2", Object.assign({ className: "title" }, { children: translate("app.requirement.reference.materials.label") })), _jsx("p", Object.assign({ className: "info-text" }, { children: translate("app.requirement.reference.materials.info.text") }))] })), _jsxs("div", Object.assign({ className: "reference-subsection reference-links-subsection" }, { children: [_jsx("h5", Object.assign({ className: "reference-label" }, { children: translate("app.requirement.reference.links.label") })), referenceLinksList.length > 0 && renderReferenceLinks(), referenceLinksList.length < maxRefLinksAllowed && (_jsx(WmButton, Object.assign({ buttonType: "secondary", icon: "F419", id: "add-links-btn", onClick: () => {
                                                setOpenAddLinkModal(true);
                                                setFocusId("link-menu-" + referenceLinksList.length);
                                            } }, { children: translate("app.requirement.reference.links.add.btn") })))] })), _jsxs("div", Object.assign({ className: "reference-subsection" }, { children: [_jsx("h5", Object.assign({ className: "reference-label" }, { children: translate("app.requirement.reference.files.label") })), _jsxs(WmUploader, Object.assign({ icon: "f3e2", fileTypes: "pdf doc docx jpg jpeg png ppt pptx xls xlsx csv", maxFiles: 5, maxSize: "50 MB", showInfo: "time", id: "upload-template-documents", buttonText: uploaderButtonText, onWmUploaderFilesSelected: (event) => {
                                                onWmUploaderFilesSelected(event);
                                                setIsContentModified(true);
                                            }, ref: uploaderRef }, { children: [rejectedFiles === null || rejectedFiles === void 0 ? void 0 : rejectedFiles.map((file) => {
                                                    return (_jsx(WmFile, { id: file.uuid, name: file.file_name.substr(0, file.file_name.lastIndexOf(".")), type: file.file_name.substr(file.file_name.lastIndexOf(".") + 1), onWmFileErrorCleared: (event) => {
                                                            setRejectedFiles(rejectedFiles.filter((val) => val.uuid != file.uuid));
                                                        }, errorMessage: file.errorMessage }, file.uuid));
                                                }), uploaderComponentFiles &&
                                                    (uploaderComponentFiles === null || uploaderComponentFiles === void 0 ? void 0 : uploaderComponentFiles.map((file) => {
                                                        return (_jsx(WmFile, { id: file.id, name: file.name, type: file.type, lastUpdated: !isNullOrEmpty(file === null || file === void 0 ? void 0 : file.lastUpdated)
                                                                ? format(getUTCDate(new Date(file.lastUpdated)), "MM/dd/yyyy h:mm a")
                                                                : "", onWmFileDownload: (event) => onWmUploaderDownloadFile(event), onWmFileDelete: (event) => {
                                                                event.preventDefault();
                                                                deleteFileHandler(event, file.id);
                                                            }, progress: file.progress }, file.id));
                                                    }))] }), uploaderComponentFiles + "str" + count)] }))] })), _jsxs("div", Object.assign({ className: conditionError
                                ? "paper-card section-card edit -card-width condition-card condition-card-error "
                                : "paper-card section-card edit -card-width condition-card", tabIndex: 0, id: "condition-card", "aria-describedby": "condition-error" }, { children: [_jsx("div", Object.assign({ className: "header section-header flex-row -justified -verticallycentered" }, { children: _jsxs("div", { children: [_jsxs("h2", Object.assign({ className: conditionError
                                                    ? "title error heading-title"
                                                    : "title heading-title" }, { children: [translate("app.requirement.condition_block_title"), " "] })), _jsx("div", { children: translate("app.requirement.condition_block_sub_title") })] }) })), currentRequirement.studentFileUploaded ||
                                    currentRequirement.acknowledgementSelected ? (_jsx("div", Object.assign({ className: "condition-msg" }, { children: translate("app.requirement.condition_message") }))) : (""), _jsxs("div", Object.assign({ className: "body" }, { children: [_jsxs("div", Object.assign({ className: "condition-types" }, { children: [_jsx("h5", Object.assign({ className: "subsection-label condition-name" }, { children: translate("app.requirement.file_upload_option") })), _jsxs("div", Object.assign({ className: "checkbox-option toggletip-container" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "student-allowed", checked: studentUploadReqCheckbox, onChange: () => studentUploadReqCheckboxHanlder(!studentUploadReqCheckbox), disabled: uploadInProgress || currentRequirement.studentFileUploaded }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "student-allowed" }, { children: translate("app.requirement.checkbox_label_student") })), _jsx(WmToggletip, { className: "toggle-tip", label: toggleTipFileUploadCheckBoxLabel, tooltip: toggletipFileUpload, tooltipPosition: "top-right" })] }))] })), _jsxs("div", Object.assign({ className: "condition-types" }, { children: [_jsx("h5", Object.assign({ className: "subsection-label condition-name" }, { children: translate("app.requirement.studentAcknowledgement") })), _jsx("p", Object.assign({ className: "student-ack-checkbox-info" }, { children: translate("app.requirement.checkbox_label_student_sign") })), _jsx("div", Object.assign({ className: "student-ack-box-list" }, { children: checkBoxList === null || checkBoxList === void 0 ? void 0 : checkBoxList.map((elem, index) => {
                                                        return (_jsxs("div", Object.assign({ className: "student-ack-box-item" }, { children: [_jsx(ComponentTextAreaField, { fieldLabel: ackTextLabel, fieldId: "input-ack-" + index, textareaError: elem.isEmpty, fieldValue: elem.text, maxLength: 1000, onChangeHandler: (event) => {
                                                                        event.preventDefault();
                                                                        const list = [...checkBoxList];
                                                                        list[index] = Object.assign(Object.assign({}, list[index]), { text: event.target.value
                                                                                .toString()
                                                                                .substring(0, 1000), sortOrder: index, isEmpty: false });
                                                                        setCheckBoxList(list);
                                                                    }, isDisabled: isSubmissionExistForRequirement, errorMessage: checkboxTextErrorText }), checkBoxList.length > 0 &&
                                                                    !isSubmissionExistForRequirement && (_jsx(WmButton, { id: `remove-option-btn-${index}`, className: `remove-option-btn ${elem.isEmpty ? "error-option" : ""}`, "button-type": "icononly", tooltip: "Remove Option", tooltipPosition: "top", icon: "f1c0", onClick: (event) => {
                                                                        deleteCheckbox(event, index);
                                                                    } }))] }), elem.uuid + elem.sortOrder));
                                                    }) })), checkBoxList.length < 10 &&
                                                    !(isSubmissionExistForRequirement && checkBoxList.length > 0) ? (_jsx(WmButton, Object.assign({ buttonType: "secondary", icon: "f419", id: "add-ack-checkbox-btn", class: "add-checkbox-btn", onClick: (event) => {
                                                        addCheckbox(event);
                                                    } }, { children: translate("app.requirement.checkbox.button.text") }))) : null] }))] })), _jsx("div", Object.assign({ className: conditionError ? "error-info condition-error" : "error-info" }, { children: _jsx("div", Object.assign({ className: "error input-error", id: "condition-error", "aria-live": "polite", "aria-atomic": true }, { children: conditionError
                                            ? translate("app.requirement.condition_alert")
                                            : "" })) }))] }))] })), renderInformationModal(isRequirementModifiable), renderAddLinkModal(openAddLinkModal), _jsx(DeleteModal, { isOpen: openDeleteFileModal, onPrimaryClick: (event) => deleteFileAction(event), onSecondaryClick: (event) => closeDeleteModalHandler(event), modalContent: modalContent, modalHeading: modalHeading, ariaLabelValue: "Delete File", modalId: "delete-modal", contentId: "content-delete-modal" }), _jsx(DiscardModal, { isOpen: openDiscardModal, onPrimaryClick: (event) => discardChanges(event), onSecondaryClick: (event) => closeDiscardModalHandler(event), onClose: (event) => closeDiscardModalHandler(event), modalContent: discardModalContent, modalHeading: discardModalHeading, ariaLabelValue: discardModalAriaLabelValue, modalId: "discard-modal", contentId: "content-discard-modal", primaryText: discardModalPrimaryText, secondayText: discardModalSecondaryText }), _jsx(WmSnackbar, { notifications: fileNotifications, id: "licensure-plans-snack", onWmSnackbarSnackFinished: (event) => finishedSnackHandler(event) }), _jsx(DiscardModal, { isOpen: openChangeReqTypeModal, onPrimaryClick: (event) => discardReqTypeChanges(event), onSecondaryClick: (event) => closeChangeReqTypeModalHandler(event), onClose: (event) => closeChangeReqTypeModalHandler(event), modalContent: changeReqTypeModalContent, modalHeading: changeReqTypeModalHeading, ariaLabelValue: changeReqTypeModalHeading, modalId: "change-req-type-modal", contentId: "content-change-req-type-modal", primaryText: changeReqTypeModalPrimaryText, secondayText: discardModalSecondaryText })] })));
    };
    return (_jsx(Flyout, { id: "add-requirement-flyout", mainContent: mainContent(), closeFlyoutHandler: (event) => closeFlyoutHandler(event), heading: currentRequirement.uuid === ""
            ? reqFlyoutHeadingText
            : "Edit Requirement", subHeading: "Fields marked with * are required.", primaryButton: translate("app.requirement.save"), primaryActionHandler: (event) => createRequirementHandler(event), breadCrum: renderBreadCrum(), showSecondaryButton: false, secondaryButton: translate("app.cohort.secondaryButtonText"), secondaryButtonActionHandler: () => { }, disableActionButtons: uploadInProgress, showStatus: "" }));
}
