import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useIntl } from "react-intl";
import translate from "../../i18n/translate";
import { getI18nValueByKey } from "../../features/common/commonActions";
import { RecordMatchingTagList } from "./data/RecordMatchingTagList";
import { useDispatch } from "react-redux";
import { setProbableMatchRecord, updateStudentMatchVerifiedStatus, } from "./ApplicationSlice";
const StudentMatchRecord = ({ studentRecord, studentMatchDetails, studentMatchStatus, isUserUpdated = false, setOpenSystemAdminFor, selectedSubmissionCallback, }) => {
    const { firstName, lastName, isMerged, admissionTerm, email } = studentRecord;
    const dispatch = useDispatch();
    const firstNameLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.first.name.label",
    });
    const lastNameLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.last.name.label",
    });
    const emailLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.university.email.label",
    });
    const termLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.app.term.label",
    });
    const universityIdLabel = useIntl().formatMessage({
        id: "app.programApplications.external.application.university.id.label",
    });
    const swapTooltip = useIntl().formatMessage({
        id: "app.programApplications.external.application.swap.system.admin.match.label",
    });
    const verfiedTooltip = useIntl().formatMessage({
        id: "app.programApplications.external.application.verified.toggletip.text",
    });
    const verifiedText = useIntl().formatMessage({
        id: "app.programApplications.external.application.verified.text",
    });
    const headerData = [
        { className: "name-header", label: firstNameLabel },
        { className: "name-header", label: lastNameLabel },
        { className: "email-header", label: emailLabel },
        { className: "term-header", label: termLabel },
        //{ className: "id-header", label: universityIdLabel },
    ];
    return (_jsxs("div", Object.assign({ className: "student-match-record-card", tabIndex: 0 }, { children: [_jsxs("div", Object.assign({ className: "student-match-record-header flex-row -justified -verticallycentered" }, { children: [_jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsx("h3", Object.assign({ className: "student-match-record-title" }, { children: `${lastName}, ${firstName}` })), _jsx("span", Object.assign({ className: `student-match-record-tag ${!studentMatchDetails
                                    ? "no-match"
                                    : isUserUpdated
                                        ? "updated-match"
                                        : ""} ` }, { children: getI18nValueByKey(!studentMatchDetails
                                    ? "NO_MATCH"
                                    : isUserUpdated
                                        ? "UPDATED_MATCH"
                                        : "", RecordMatchingTagList) }))] })), studentMatchStatus === "VERIFIED" ? (_jsxs("div", Object.assign({ className: "main-container", tabIndex: 0 }, { children: [_jsxs("div", Object.assign({ className: "verified-container" }, { children: [_jsx("span", { className: "status-symbol mdi mdi-check-circle" }), _jsx("span", Object.assign({ className: "verified-text" }, { children: verifiedText }))] })), _jsx("div", Object.assign({ className: "toolTip-container" }, { children: _jsx("span", Object.assign({ className: "toolTip-text" }, { children: verfiedTooltip })) }))] }))) : (_jsx(WmButton, Object.assign({ buttonType: "secondary", icon: !studentMatchDetails ? "f349" : "", id: "search-system-admin", className: "", onClick: () => {
                            if (!studentMatchDetails) {
                                dispatch(setProbableMatchRecord(studentMatchDetails));
                                selectedSubmissionCallback(studentRecord);
                                setOpenSystemAdminFor("search");
                            }
                            else if (!isMerged) {
                                dispatch(updateStudentMatchVerifiedStatus({
                                    studentApplicationUuid: studentRecord.studentApplicationUuid,
                                    status: "VERIFIED",
                                }));
                            }
                        }, placeholder: undefined }, { children: translate(!studentMatchDetails
                            ? "app.programApplications.external.application.search.system.admin.record"
                            : !isMerged
                                ? "app.programApplications.external.application.verify.text"
                                : "") })))] })), _jsxs("div", Object.assign({ className: "student-match-record-content" }, { children: [_jsxs("div", Object.assign({ className: "student-match-row-header" }, { children: [_jsx("div", { className: "student-match-row" }), _jsx("div", Object.assign({ className: "student-match-row" }, { children: translate("app.programApplications.external.application.app.info.label") })), _jsx("div", Object.assign({ className: "student-match-row" }, { children: translate("app.programApplications.external.application.system.admin.match.label") }))] })), _jsxs("table", Object.assign({ className: "data-table", role: "grid", "aria-readonly": true, tabIndex: 0 }, { children: [_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsxs("tr", Object.assign({ className: "dataheaderrow", role: "row" }, { children: [headerData.map((header) => (_jsx("th", Object.assign({ className: header.className, tabIndex: 0, role: "columnheader", scope: "col", "data-th": header.label }, { children: header.label }), header.label))), _jsx("th", { className: "btn-header" })] }), "headers") })), _jsxs("tbody", Object.assign({ className: "databody" }, { children: [_jsx("tr", Object.assign({ className: "datarow" }, { children: [firstName, lastName, email, admissionTerm, ""].map((prop, index) => (_jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: prop !== null && prop !== void 0 ? prop : "-" }), index + prop))) }), studentRecord === null || studentRecord === void 0 ? void 0 : studentRecord.studentUuid), _jsxs("tr", Object.assign({ className: "datarow" }, { children: [[
                                                studentMatchDetails === null || studentMatchDetails === void 0 ? void 0 : studentMatchDetails.firstName,
                                                studentMatchDetails === null || studentMatchDetails === void 0 ? void 0 : studentMatchDetails.lastName,
                                                studentMatchDetails === null || studentMatchDetails === void 0 ? void 0 : studentMatchDetails.email,
                                                "-",
                                            ].map((prop, index) => (_jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: prop !== null && prop !== void 0 ? prop : "-" }), index))), _jsx("td", Object.assign({ role: "gridcell", className: "td-content" }, { children: studentMatchDetails && (_jsx(WmButton, { id: "probable-match-" + (studentMatchDetails === null || studentMatchDetails === void 0 ? void 0 : studentMatchDetails.uuid), buttonType: "icononly", tooltip: swapTooltip, icon: "f4e1", onClick: () => {
                                                        dispatch(setProbableMatchRecord(studentMatchDetails));
                                                        selectedSubmissionCallback(studentRecord);
                                                        setOpenSystemAdminFor("swap");
                                                    }, placeholder: undefined })) }))] }), studentMatchDetails === null || studentMatchDetails === void 0 ? void 0 : studentMatchDetails.uuid)] }))] }), JSON.stringify(studentMatchDetails && studentMatchDetails))] }))] })));
};
export default StudentMatchRecord;
