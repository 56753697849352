var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import { useState } from "react";
import TextAreaField from "../common/component/TextAreaField";
import ViewMore from "../common/component/ViewMore";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { saveInternalAdminNote } from "./ApplicationSlice";
import { isNullOrEmpty } from "../../static/js/commons";
import { format } from "date-fns";
import { getStudentIdentifierForBackend } from "../../features/common/commonActions";
const AdminInternalNotes = ({ submissionObj, applicationUuid, isConnectedToArchivedLP, }) => {
    var _a, _b;
    const [showAddNoteInput, setShowAddNoteInput] = useState(false);
    const [noteInputText, setNoteInputText] = useState("");
    const [expandIndex, setExpandIndex] = useState(null);
    const dispatch = useDispatch();
    const noteLabel = useIntl().formatMessage({
        id: "global.note",
    });
    const addNotePlaceHolder = useIntl().formatMessage({
        id: "app.programApplications.student.review.submission.internal.reference.add.note.info.text",
    });
    const [disableAddButton, setDisableAddButton] = useState(false);
    const addNoteHandler = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        if (disableAddButton)
            return;
        setDisableAddButton(true);
        if (!isNullOrEmpty(noteInputText.trim())) {
            yield dispatch(saveInternalAdminNote({
                applicationUuid: applicationUuid,
                studentUuid: getStudentIdentifierForBackend(submissionObj),
                studentApplicationUuid: submissionObj.studentApplicationUuid,
                text: noteInputText,
            })).then((res) => {
                var _a;
                const noteData = res === null || res === void 0 ? void 0 : res.payload;
                (_a = document.getElementById("note-" + (noteData === null || noteData === void 0 ? void 0 : noteData.uuid))) === null || _a === void 0 ? void 0 : _a.focus();
            });
        }
        else {
            setTimeout(() => {
                var _a;
                (_a = document.getElementById("add-note-btn")) === null || _a === void 0 ? void 0 : _a.focus();
            }, 10);
        }
        setNoteInputText("");
        setShowAddNoteInput(false);
        setDisableAddButton(false);
    });
    return (_jsxs("div", Object.assign({ className: "notes-content" }, { children: [!showAddNoteInput ? (_jsxs(_Fragment, { children: [!isConnectedToArchivedLP ? (_jsx(WmButton, Object.assign({ buttonType: "secondary", icon: "f419", id: "add-note-btn", onClick: () => {
                            setShowAddNoteInput(true);
                            setTimeout(() => {
                                var _a;
                                (_a = document.getElementById("add-note-input")) === null || _a === void 0 ? void 0 : _a.focus();
                            }, 10);
                        }, placeholder: undefined }, { children: translate("app.programApplications.student.review.submission.internal.reference.add.note.label") }))) : null, _jsx("p", Object.assign({ className: "add-note-info" }, { children: addNotePlaceHolder }))] })) : (_jsxs(_Fragment, { children: [_jsx(TextAreaField, { fieldLabel: noteLabel, fieldId: "add-note-input", isRequired: false, textareaError: false, fieldValue: noteInputText, onChangeHandler: (event) => {
                            event.preventDefault();
                            setNoteInputText(event.target.value);
                        }, placeHolder: addNotePlaceHolder, maxLength: 1000 }), _jsxs("div", Object.assign({ className: "add-note-btns" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "close-flyout-button", onClick: () => {
                                    setShowAddNoteInput(false);
                                    setNoteInputText("");
                                    setTimeout(() => {
                                        var _a;
                                        (_a = document.getElementById("add-note-btn")) === null || _a === void 0 ? void 0 : _a.focus();
                                    }, 10);
                                }, placeholder: undefined }, { children: translate("app.cohort.secondaryButtonText") })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "change-statuses-button", onClick: addNoteHandler, disabled: disableAddButton, placeholder: undefined }, { children: translate("app.requirement.add") }))] }))] })), ((_a = submissionObj === null || submissionObj === void 0 ? void 0 : submissionObj.adminInternalNotes) === null || _a === void 0 ? void 0 : _a.length) > 0 && (_jsx("ul", Object.assign({ className: "internal-notes-list" }, { children: (_b = submissionObj === null || submissionObj === void 0 ? void 0 : submissionObj.adminInternalNotes) === null || _b === void 0 ? void 0 : _b.map((note, index) => {
                    var _a, _b;
                    return (!isNullOrEmpty(note.text) && (_jsxs("li", Object.assign({ id: "note-" + note.uuid, className: "note-item" }, { children: [_jsxs("span", Object.assign({ className: "note-item-label" }, { children: [noteLabel, ":"] })), _jsx(ViewMore, Object.assign({ truncationLimit: 170, showContent: expandIndex === index, setIndex: () => {
                                    if (expandIndex === index) {
                                        setExpandIndex(null);
                                    }
                                    else
                                        setExpandIndex(index);
                                } }, { children: note === null || note === void 0 ? void 0 : note.text })), _jsx("p", Object.assign({ className: "note-data" }, { children: translate("app.programApplications.student.review.submission.internal.reference.note.data", {
                                    firstName: (_a = note === null || note === void 0 ? void 0 : note.createdByObject) === null || _a === void 0 ? void 0 : _a.firstName,
                                    lastName: (_b = note === null || note === void 0 ? void 0 : note.createdByObject) === null || _b === void 0 ? void 0 : _b.lastName,
                                    date: format(new Date(note === null || note === void 0 ? void 0 : note.createdAt), "P"),
                                    time: format(new Date(note === null || note === void 0 ? void 0 : note.createdAt), "p"),
                                }) }))] }), note.uuid)));
                }) })))] })));
};
export default AdminInternalNotes;
