import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SideFlyout } from "../../common/component/SideFlyout";
import { useIntl } from "react-intl";
import { ExportSettingsFlyoutContent } from "./ExportSettingsFlyoutContent";
import { ExportSettingsFlyoutFooter } from "./ExportSettingsFlyoutFooter";
import { WmButton } from "@watermarkinsights/ripple-react";
import { useLocation } from "react-router-dom";
import LZString from "lz-string";
export const ExportSettingsFlyout = ({ currentCohort, timelineOverview, selectedStudentsUuid, openStudent, openRequirement, countRequirementSelection, exportType, exportSettings, setExportType, setExportSettings, setOpenStudent, setOpenRequirement, closeFlyout, handlePrevClick, redirectToStepOne, allRequirementSubmissions, }) => {
    const location = useLocation();
    const modalSubHeader = useIntl().formatMessage({
        id: "app.cohort.export.flyout.subheader",
    });
    const modalInfoText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.export.settings.content.header",
    });
    const breadCrumbPrimaryText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.student.breadcrumb.select.requirement",
    });
    const breadCrumbSecondaryText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.student.breadcrumb.select.students",
    });
    const remainingBreadCrumbText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.remaining.breadCrumb.text",
    });
    const generateReqInfo = (req) => ({
        uuid: req.uuid,
        title: req.title,
        description: req.description,
        requirementType: req.requirementType,
    });
    const startExportHandler = () => {
        var _a;
        const flatRequirementDetails = timelineOverview.sections.reduce((flatRequirementDetails, section) => {
            let sectionWithNoReq = false;
            const requirements = [];
            section.requirements.forEach((requirement) => {
                if (requirement.isSelected) {
                    requirements.push(generateReqInfo(requirement));
                }
            });
            if (requirements.length) {
                flatRequirementDetails.push({
                    sectionName: section.title,
                    requirements,
                });
            }
            else {
                sectionWithNoReq = true;
            }
            section.subSections.forEach((subsection) => {
                const subSectionRequirements = [];
                subsection.requirements.forEach((requirement) => {
                    if (requirement.isSelected) {
                        subSectionRequirements.push(generateReqInfo(requirement));
                    }
                });
                if (subSectionRequirements.length) {
                    if (sectionWithNoReq) {
                        flatRequirementDetails.push({
                            sectionName: section.title,
                            requirements: [],
                        });
                        sectionWithNoReq = false;
                    }
                    flatRequirementDetails.push({
                        sectionName: section.title,
                        subSectionName: subsection.title,
                        requirements: subSectionRequirements,
                    });
                }
            });
            return flatRequirementDetails;
        }, []);
        const selectedStudentsSubmissions = allRequirementSubmissions.filter((student) => selectedStudentsUuid.includes(student.userUuid));
        localStorage.removeItem("lpReqExportPayload");
        const compressedString = LZString.compress(JSON.stringify({
            flatRequirementDetails,
            selectedStudentsSubmissions,
            progUuid: (_a = location.state) === null || _a === void 0 ? void 0 : _a.programUuid,
            cohortUuid: currentCohort.uuid,
            cohortTitle: currentCohort.title,
            timelineTitle: timelineOverview.title,
            exportType,
            exportSettings,
        }));
        localStorage.setItem("lpReqExportPayload", compressedString);
        if (["CSV", "PDF"].includes(exportType)) {
            window.open("/licensurePlans/requirements/export/", "_blank");
        }
        closeFlyout();
    };
    const renderBreadCrumb = () => {
        return (_jsxs("div", Object.assign({ className: "breadCrumb-container" }, { children: [_jsx("div", { children: _jsx(WmButton, Object.assign({ buttonType: "textonly", id: "select-requirement-breadcrumb", onClick: redirectToStepOne, placeholder: undefined }, { children: breadCrumbPrimaryText })) }), _jsx("div", { children: ">" }), _jsx("div", { children: _jsx(WmButton, Object.assign({ buttonType: "textonly", id: "select-student-breadcrumb", onClick: handlePrevClick, placeholder: undefined }, { children: breadCrumbSecondaryText })) }), _jsx("div", { children: ">" }), _jsx("div", Object.assign({ className: "secondary-text" }, { children: remainingBreadCrumbText }))] })));
    };
    return (_jsxs(SideFlyout, Object.assign({ flyoutId: "export-settings-flyout", focusAfterClosed: "export-btn", ariaLabel: "Select export Settings" }, { children: [_jsx(SideFlyout.Header, { modalHeader: currentCohort.title + ": " + timelineOverview.title, modalSubheader: modalSubHeader, modalInfoText: modalInfoText, closeFlyout: closeFlyout, breadCrumb: renderBreadCrumb() }), _jsx(ExportSettingsFlyoutContent, { currentCohort: currentCohort, selectedStudentsUuid: selectedStudentsUuid, timelineOverview: timelineOverview, openStudent: openStudent, openRequirement: openRequirement, countRequirementSelection: countRequirementSelection, exportType: exportType, exportSettings: exportSettings, setExportType: setExportType, setExportSettings: setExportSettings, redirectToStepOne: redirectToStepOne, setOpenStudent: setOpenStudent, setOpenRequirement: setOpenRequirement, handlePrevClick: handlePrevClick }), _jsx(ExportSettingsFlyoutFooter, { startExportHandler: startExportHandler, handlePrevClick: handlePrevClick })] })));
};
