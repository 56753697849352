import { jsx as _jsx } from "react/jsx-runtime";
import { WmFile, WmFileList, WmUploader, } from "@watermarkinsights/ripple-react";
import useFileupload from "../../../features/common/hooks/useFileUpload";
import { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { updateStudentApplication } from "../ApplicationSlice";
import { useDispatch } from "react-redux";
import translate from "../../../i18n/translate";
import { isNullOrEmpty } from "../../../static/js/commons";
import { format } from "date-fns";
import { generateUTCDate } from "../../../features/common/commonActions";
export default function FileUploadComponent({ id, componentObj, isStudentView, isEditable, response, setFileProcessingInProgress, errorMap, setErrorMap, applicationTemplateUuid, isExternal, isResubmission = false, }) {
    const [uploaderComponentFiles, setUploaderComponentFiles] = useState([]);
    const [extraFileError, setExtraFileError] = useState(false);
    const attachFilesLabel = useIntl().formatMessage({
        id: "app.programApplications.component.attachment.attach.files.label",
    });
    const attachEmptyStateText = useIntl().formatMessage({
        id: "app.requirement.emptyStateText",
    });
    const requiredErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.fileupload.required.error.message",
    });
    const extraFileErrorMessage = useIntl().formatMessage({
        id: "app.programApplications.student.application.fileupload.extra.file.error.message",
    });
    const dispatch = useDispatch();
    let uploaderRef = useRef();
    const [attachedFiles, deletedFiles, uploadInProgress, deleteInProgress, onWmUploaderFilesSelected, onWmUploaderDownloadFile, onWmUploaderDeleteFile, , , currentFiles,] = useFileupload({
        app: "APPLICATIONS",
        type: "STUDENT_SUBMISSION",
        initialAttachments: response === null || response === void 0 ? void 0 : response.files,
        componentRef: uploaderRef,
        useBase64Format: isExternal,
    });
    const firstUpdate = useRef(true);
    useEffect(() => {
        const uploaderFiles = attachedFiles.map((file, i) => {
            let id = isStudentView ? file.uuid : componentObj.uuid + file.uuid;
            let progressIndicator = (uploadInProgress || deleteInProgress) && currentFiles.indexOf(id) > -1
                ? { progress: 0 }
                : { progress: 100 };
            let uploaderFile = Object.assign({ id: id != null ? id : null, name: file.file_name.substr(0, file.file_name.lastIndexOf(".")), type: file.file_name.substr(file.file_name.lastIndexOf(".") + 1), lastUpdated: file.inserted_at, fileActions: uploadInProgress || deleteInProgress || !isEditable
                    ? isExternal && isResubmission && id.length == 36
                        ? ""
                        : "download"
                    : isExternal && isResubmission && id.length == 36
                        ? "delete"
                        : "download delete" }, progressIndicator);
            return uploaderFile;
        });
        setUploaderComponentFiles(uploaderFiles);
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        dispatch(updateStudentApplication({
            attachedFiles,
            deletedFiles,
            templateUuid: applicationTemplateUuid == undefined ? "" : applicationTemplateUuid,
            templateComponentUuid: componentObj.uuid,
        }));
        setFileProcessingInProgress &&
            setFileProcessingInProgress(uploadInProgress || deleteInProgress);
    }, [attachedFiles, deletedFiles, uploadInProgress, deleteInProgress]);
    const [count, setCount] = useState(0);
    return (_jsx("div", Object.assign({ className: "file-upload-component" }, { children: isEditable ? (_jsx(WmUploader, Object.assign({ id: id, className: "file-uploader", icon: "f3e2", label: componentObj.componentLabel, buttonText: attachFilesLabel, maxFiles: componentObj.contentLimit, maxSize: "50 MB", requiredField: componentObj.isMandatory, fileTypes: "docx doc pdf jpg png gif pptx ppt xlsx xls csv txt log xml", onWmUploaderFilesSelected: (event) => {
                if (event.target.maxFiles &&
                    Array.from(event.target.querySelectorAll("wm-file")).filter((file) => !(file === null || file === void 0 ? void 0 : file.errorMessage)).length +
                        event.detail.length >
                        event.target.maxFiles) {
                    setExtraFileError(true);
                }
                else {
                    setExtraFileError(false);
                }
                if (isStudentView) {
                    if (setErrorMap) {
                        setErrorMap(componentObj, applicationTemplateUuid == undefined
                            ? ""
                            : applicationTemplateUuid);
                    }
                    onWmUploaderFilesSelected(event);
                }
                else {
                    setCount((prev) => prev + 1);
                }
            }, ref: uploaderRef, errorMessage: (errorMap === null || errorMap === void 0 ? void 0 : errorMap.get(componentObj.componentType.toLowerCase() +
                (applicationTemplateUuid == undefined
                    ? ""
                    : applicationTemplateUuid + componentObj.uuid)))
                ? uploaderComponentFiles.length === 0
                    ? requiredErrorMessage
                    : ""
                : extraFileError
                    ? extraFileErrorMessage
                    : "", placeholder: undefined }, { children: uploaderComponentFiles === null || uploaderComponentFiles === void 0 ? void 0 : uploaderComponentFiles.map((file) => {
                return (_jsx(WmFile, { id: file.id, name: file.name, type: file.type, lastUpdated: !isNullOrEmpty(file === null || file === void 0 ? void 0 : file.lastUpdated)
                        ? format(generateUTCDate(new Date(file.lastUpdated)), "MM/dd/yyyy h:mm a")
                        : "", onWmFileDownload: (event) => isStudentView && onWmUploaderDownloadFile(event), onWmFileDelete: (event) => {
                        isStudentView && onWmUploaderDeleteFile(event);
                        const deleteFile = (fileUuid, files) => {
                            setUploaderComponentFiles(files.filter((f) => f.id !== fileUuid));
                        };
                        !isStudentView &&
                            deleteFile(event.detail, uploaderComponentFiles);
                    }, progress: file.progress, fileActions: file.fileActions, placeholder: undefined }, file.id));
            }) }), JSON.stringify(uploaderComponentFiles) + count)) : uploaderComponentFiles.length > 0 ? (_jsx(WmFileList, Object.assign({ "show-info": "size", placeholder: undefined }, { children: uploaderComponentFiles === null || uploaderComponentFiles === void 0 ? void 0 : uploaderComponentFiles.map((file) => {
                return (
                // file deepcode ignore ReactMissingArrayKeys: <please specify a reason of ignoring this>
                _jsx(WmFile, { id: file.id, name: file.name, type: file.type, lastUpdated: !isNullOrEmpty(file === null || file === void 0 ? void 0 : file.lastUpdated)
                        ? format(generateUTCDate(new Date(file.lastUpdated)), "MM/dd/yyyy h:mm a")
                        : "", fileActions: file.fileActions, onWmFileDownload: (event) => {
                        onWmUploaderDownloadFile(event);
                    }, placeholder: undefined }));
            }) }))) : (_jsx("span", Object.assign({ className: "empty-response-text" }, { children: translate("app.programApplications.student.review.submission.no.files.uploaded") }))) })));
}
