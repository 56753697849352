var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmNavigator } from "@watermarkinsights/ripple-react";
import { userSelector } from "../../login/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import "../sidenav/SideNav.scss";
import { WmNavigationHamburger } from "@watermarkinsights/ripple-react";
import { logoutGateways, logoutSll, logoutProgramApplications, } from "../../login/UserSlice";
export default function Header({ activeItem }) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const { userInfo, userInfoProgApp, redirectTo } = useSelector(userSelector);
    const userObj = activeItem === "gateways" ? userInfo : userInfoProgApp;
    const currentInstitute = (_b = (_a = userObj === null || userObj === void 0 ? void 0 : userObj.sllUser) === null || _a === void 0 ? void 0 : _a.institutions) === null || _b === void 0 ? void 0 : _b.find((inst) => { var _a; return (inst === null || inst === void 0 ? void 0 : inst.platform_uuid) === ((_a = userObj.sllUser) === null || _a === void 0 ? void 0 : _a.institution_uuid); });
    const user = activeItem === "gateways"
        ? {
            sllUser: userInfo === null || userInfo === void 0 ? void 0 : userInfo.sllUser,
            user: userInfo === null || userInfo === void 0 ? void 0 : userInfo.gatewaysUser,
        }
        : {
            sllUser: userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.sllUser,
            user: userInfoProgApp === null || userInfoProgApp === void 0 ? void 0 : userInfoProgApp.applicationsUser,
        };
    const dispatch = useDispatch();
    const logout = () => __awaiter(this, void 0, void 0, function* () {
        if (localStorage.getItem("x-xsrf-token") &&
            localStorage.getItem("application-xsrf-token")) {
            dispatch(logoutGateways()).then(() => dispatch(logoutProgramApplications()).then(({ payload }) => {
                var _a, _b;
                localStorage.clear();
                dispatch(logoutSll({ url: (_b = (_a = user === null || user === void 0 ? void 0 : user.sllUser) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.logout })).then(() => {
                    let url = payload;
                    window.open(url, "_self");
                });
            }));
        }
        else if (localStorage.getItem("x-xsrf-token")) {
            dispatch(logoutGateways()).then(({ payload }) => {
                var _a, _b;
                localStorage.clear();
                dispatch(logoutSll({ url: (_b = (_a = user === null || user === void 0 ? void 0 : user.sllUser) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.logout })).then(() => {
                    let url = payload;
                    window.open(url, "_self");
                });
            });
        }
        else if (localStorage.getItem("application-xsrf-token")) {
            dispatch(logoutProgramApplications()).then(({ payload }) => {
                var _a, _b;
                localStorage.clear();
                dispatch(logoutSll({ url: (_b = (_a = user === null || user === void 0 ? void 0 : user.sllUser) === null || _a === void 0 ? void 0 : _a.navigation) === null || _b === void 0 ? void 0 : _b.logout })).then(() => {
                    let url = payload;
                    window.open(url, "_self");
                });
            });
        }
    });
    return (_jsxs("header", Object.assign({ className: "top-bar", "data-testid": "header-watermark" }, { children: [_jsx("div", Object.assign({ className: "hamburger-header" }, { children: _jsx(WmNavigationHamburger, { "nav-id": "main-nav" }) })), _jsx("h1", Object.assign({ className: "logo sll-logo" }, { children: _jsx("a", Object.assign({ href: ((_c = user === null || user === void 0 ? void 0 : user.user) === null || _c === void 0 ? void 0 : _c.sllRole) !== "admin"
                        ? ((_d = user === null || user === void 0 ? void 0 : user.user) === null || _d === void 0 ? void 0 : _d.associatedToCourseSection)
                            ? (_g = (_f = (_e = user === null || user === void 0 ? void 0 : user.sllUser) === null || _e === void 0 ? void 0 : _e.navigation) === null || _f === void 0 ? void 0 : _f.in_progress) === null || _g === void 0 ? void 0 : _g.in_progress
                            : (_k = (_j = (_h = user === null || user === void 0 ? void 0 : user.sllUser) === null || _h === void 0 ? void 0 : _h.navigation) === null || _j === void 0 ? void 0 : _j.in_progress) === null || _k === void 0 ? void 0 : _k.timeline
                        : (_m = (_l = user === null || user === void 0 ? void 0 : user.sllUser) === null || _l === void 0 ? void 0 : _l.navigation) === null || _m === void 0 ? void 0 : _m.academics }, { children: _jsx("img", { src: "/images/student_learning_licensure_logo.svg", alt: "Watermark" }) })) })), _jsx("div", Object.assign({ className: "user-institution" }, { children: currentInstitute === null || currentInstitute === void 0 ? void 0 : currentInstitute.name })), _jsx("div", Object.assign({ className: "menus", role: "application" }, { children: _jsx(WmNavigator, { id: "navigator-example", "user-name": `${(_o = user === null || user === void 0 ? void 0 : user.user) === null || _o === void 0 ? void 0 : _o.firstName} ${(_p = user === null || user === void 0 ? void 0 : user.user) === null || _p === void 0 ? void 0 : _p.lastName}`, email: (_q = user === null || user === void 0 ? void 0 : user.user) === null || _q === void 0 ? void 0 : _q.email, "auth-type": "2", products: "[]", onWmNavigatorLogout: logout }) }))] })));
}
