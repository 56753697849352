import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SideFlyout } from "../../common/component/SideFlyout";
import { useIntl } from "react-intl";
import { TermFlyoutContent } from "./TermFlyoutContent";
import { TermFlyoutFooter } from "./TermFlyoutFooter";
import { useState } from "react";
export const TermFlyout = ({ closeFlyout, applicationObject, termsList, }) => {
    const termModalHeader = useIntl().formatMessage({
        id: "app.programApplications.internal.application.export.flyout.header",
    });
    const prefixRequiredText = useIntl().formatMessage({
        id: "app.cohort.requiredText.prefix",
    });
    const suffixRequiredText = useIntl().formatMessage({
        id: "app.cohort.requiredText.suffix",
    });
    const itemsPerPage = 10;
    const [selectedTerms, setSelectedTerms] = useState([]);
    const [renderFilteredTerms, setRenderFilteredTerms] = useState((termsList === null || termsList === void 0 ? void 0 : termsList.length) > 0 ? termsList : []);
    const [searchText, setSearchText] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [showError, setShowError] = useState(false);
    const [terms, setTerms] = useState(renderFilteredTerms.slice(0, itemsPerPage));
    const [isAllSelected, setIsAllSelected] = useState(false);
    const handlePageChange = (event) => {
        event.preventDefault();
        const newPage = event.target.currentPage;
        setCurrentPage(event.target.currentPage);
        const currentPageTerms = renderFilteredTerms === null || renderFilteredTerms === void 0 ? void 0 : renderFilteredTerms.slice((newPage - 1) * itemsPerPage, newPage * itemsPerPage > renderFilteredTerms.length
            ? renderFilteredTerms.length
            : newPage * itemsPerPage);
        setTerms(currentPageTerms);
        const selectedTermsUUids = selectedTerms.map((term) => term.uuid);
        const isAllCurrentPageTermsSelected = currentPageTerms.every((term) => selectedTermsUUids.includes(term.uuid));
        setIsAllSelected(isAllCurrentPageTermsSelected);
    };
    const isTermSelected = (term) => {
        return (!!term &&
            Array.isArray(selectedTerms) &&
            selectedTerms.some(({ uuid }) => uuid === term.uuid));
    };
    const handleSearchTextChange = (text) => {
        const filteredTerms = termsList.filter((t) => {
            return t.name.toLowerCase().includes(text.toLowerCase());
        });
        setSearchText(text);
        setCurrentPage(1);
        setRenderFilteredTerms(filteredTerms);
        const currentPageTerms = filteredTerms.slice(0, itemsPerPage);
        setTerms(currentPageTerms);
        const selectedTermsUUids = selectedTerms.map((term) => term.uuid);
        const isAllCurrentPageTermsSelected = currentPageTerms.every((term) => selectedTermsUUids.includes(term.uuid));
        setIsAllSelected(isAllCurrentPageTermsSelected);
    };
    const handleChange = (term) => {
        setShowError(false);
        const newSelectedTerms = isTermSelected(term)
            ? selectedTerms.filter((st) => st.uuid !== term.uuid)
            : [...selectedTerms, term];
        setSelectedTerms(newSelectedTerms);
        const selectedTermsUUids = newSelectedTerms.map((term) => term.uuid);
        const isAllCurrentPageTermsSelected = terms.every((term) => selectedTermsUUids.includes(term.uuid));
        setIsAllSelected(isAllCurrentPageTermsSelected);
    };
    const handleAllSelectionChange = (isAllSelected) => {
        let newSelectedTerms = [...selectedTerms];
        const selectedUUids = newSelectedTerms.map((term) => term.uuid);
        terms.forEach((term) => {
            const isCurrenPageItemSelected = selectedUUids.includes(term.uuid);
            if (isCurrenPageItemSelected && !isAllSelected) {
                newSelectedTerms = newSelectedTerms.filter((selectedTerm) => selectedTerm.uuid !== term.uuid);
            }
            else if (!isCurrenPageItemSelected && isAllSelected) {
                newSelectedTerms.push(term);
            }
        });
        setSelectedTerms(newSelectedTerms);
        setIsAllSelected(isAllSelected);
        setShowError(false);
    };
    const handleExportClick = () => {
        if (selectedTerms.length > 0 && applicationObject.type) {
            closeFlyout();
            localStorage.setItem("applicationTitle", applicationObject.title);
            localStorage.setItem("selectedTerms", JSON.stringify(selectedTerms.map((terms) => terms.uuid)));
            // open a new tab
            window.open(`/applications/export/${applicationObject.uuid}/${applicationObject.type.toLowerCase()}`, "_blank");
        }
        else {
            setShowError(true);
        }
    };
    return (_jsxs(SideFlyout, Object.assign({ flyoutId: "term-flyout", focusAfterClosed: "export-btn", ariaLabel: "Select Term" }, { children: [_jsx(SideFlyout.Header, { modalHeader: termModalHeader, modalSubheader: applicationObject === null || applicationObject === void 0 ? void 0 : applicationObject.title, modalRequiredPrefix: prefixRequiredText, modalRequiredSuffix: suffixRequiredText, closeFlyout: closeFlyout, flyoutFrom: "application" }), _jsx(TermFlyoutContent, { searchText: searchText, handleSearchTextChange: handleSearchTextChange, showError: showError, renderFilteredTerms: renderFilteredTerms, isAllSelected: isAllSelected, handleAllSelectionChange: handleAllSelectionChange, handleChange: handleChange, terms: terms, setTerms: setTerms, isTermSelected: isTermSelected, currentPage: currentPage, itemsPerPage: itemsPerPage, handlePageChange: handlePageChange, termsList: termsList }), _jsx(TermFlyoutFooter, { closeFlyout: closeFlyout, handleExportClick: handleExportClick })] })));
};
