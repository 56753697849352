var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ROOT } from "../../env-config";
import { fetchWrapper } from "../common/fetchWrapper";
export const getAllCohorts = createAsyncThunk("/getAllCohortsForOrganization", ({ orgUuid, programUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}/cohorts`, {}, null);
    return data ? data : error;
}));
export const connectPlanToCohort = createAsyncThunk("/connectPlanToCohort", ({ organizationUuid, programUuid, timelineUuid, cohortUuid, releaseDate, notif, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${organizationUuid}/programs/${programUuid}/cohorts/${cohortUuid}/publish`, {}, {
        releaseDate: releaseDate,
        sendNotification: notif,
        timelineUuid: timelineUuid,
    });
    // Handle error case
    if (error) {
        return thunkAPI.rejectWithValue("Error");
    }
    return data ? data : error;
}));
export const changeCohortStatus = createAsyncThunk("/changeCohortStatus", ({ organizationUuid, programUuid, cohortUuid, cohortTimelineUuid, status, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_ROOT}api/organizations/${organizationUuid}/programs/${programUuid}/cohorts/${cohortUuid}/status`, {}, {
        uuid: cohortTimelineUuid,
        status: status,
    });
    return data ? data : error;
}));
export const getStudentSubmissions = createAsyncThunk("/getStudentSubmissions", ({ timelineUuid, requirementUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/timelines/${timelineUuid}/requirements/${requirementUuid}/submissions`, {}, null);
    return data ? data : error;
}));
export const updateSubmission = createAsyncThunk("/updateSubmission", ({ note, status, timelineUuid, requirementUuid, submissionUuid, files, studentAck, studentApplicationUuid, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    let filesToSend = files && files.length ? files : [];
    const [data, error] = yield fetchWrapper("PUT", `${API_ROOT}api/timelines/${timelineUuid}/requirements/${requirementUuid}/submissions/${submissionUuid}`, {}, {
        reviewNote: note,
        status: status,
        filesToGenerateActivity: filesToSend,
        confirmationCheckboxes: studentAck,
        studentApplicationUuid,
    });
    return data ? data : error;
}));
export const changeBulkStatus = createAsyncThunk("/changeBulkStatus", ({ orgUuid, programUuid, cohortUuid, note, newStatus, requirementUuid, users, }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}/cohorts/${cohortUuid}/bulk-update`, {}, {
        status: newStatus,
        note: note,
        requirementUuid: requirementUuid,
        users: users,
    });
    return data ? data : error;
}));
export const updateNote = createAsyncThunk("/updateNote", ({ orgUuid, programUuid, cohortUuid, historyUuid, updatedNote }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}/cohorts/${cohortUuid}/history/${historyUuid}`, {}, {
        note: updatedNote,
    });
    return data ? data : error;
}));
export const getFileContents = createAsyncThunk("/getFileContents", ({ fileUuid, fileName }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const token = localStorage.getItem("jwt");
        const response = yield fetch(`${API_ROOT}api/files/${fileUuid}/download`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token ? token : "",
            },
            credentials: "include",
        });
        if (response.status === 200) {
            yield response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
                return "Download Successful";
            });
        }
        else {
            return "Download Failed";
        }
    }
    catch (e) {
        thunkAPI.rejectWithValue(e.response.data);
        return "Download Failed";
    }
}));
export const getSubmissionRecord = createAsyncThunk("/getSubmissionRecord", ({ timelineUuid, requirementUuid, submissionUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/timelines/${timelineUuid}/requirements/${requirementUuid}/submissions/${submissionUuid}`, {}, null);
    return data ? data : error;
}));
export const getCurrentStudentSubmissions = createAsyncThunk("/getCurrentStudentSubmissions", ({ orgUuid, programUuid, cohortUuid, studentUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}/cohorts/${cohortUuid}/student/${studentUuid}/submissions`, {}, null);
    return data ? data : error;
}));
export const getCohort = createAsyncThunk("/getCohort", ({ orgUuid, programUuid, cohortUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}/cohorts/${cohortUuid}`, {}, null);
    return data ? data : error;
}));
// export const uploadToS3AndSaveToDatabase = async (
//   index: number,
//   fileObj: any,
//   currentSubmission: UserRequirementResult
// ) => {
//   let url = `${API_ROOT}api/files/submissions/${currentSubmission.uuid}/sign-url-upload/${fileObj.name}`;
//   await fetchWrapper("GET", url, {}, null).then((data: any) => {
//     let [signedUrlPromise, error] = data;
//     if (signedUrlPromise) {
//       signedUrlPromise.then((data: any) => {
//         fetch(data.url, {
//           method: "PUT",
//           headers: {},
//           body: fileObj,
//         }).then((response) => {
//           if (index == store.getState().cohortSliceData.lastUploadIndex)
//             store.dispatch(
//               updateActionsByUploadStatus({
//                 uploadInProgress: false,
//                 lastUploadIndex: -1,
//               })
//             );
//           if (response.ok) {
//             // Save to DB
//             let saveFileUrl = `${API_ROOT}api/files/submissions/${currentSubmission.uuid}/save-file`;
//             fetchWrapper(
//               "POST",
//               saveFileUrl,
//               {},
//               {
//                 //document_uuid: data.guid,
//                 file_name: fileObj.name,
//                 file_path: data.path,
//                 mime_type: fileObj.type,
//               }
//             ).then((resData: any) => {
//               let [saveFilePromise, error] = resData;
//               if (saveFilePromise) {
//                 saveFilePromise.then(() => {
//                   let allFilesUrl = `${API_ROOT}api/files/submissions/${currentSubmission.uuid}`;
//                   fetchWrapper("GET", allFilesUrl, {}, null).then(
//                     (resData: any) => {
//                       let [updateFilesPromise, error] = resData;
//                       if (updateFilesPromise) {
//                         updateFilesPromise.then((data: any) => {
//                           store.dispatch(
//                             setUpdatedFiles({
//                               fileData: data,
//                               operation: "UPLOAD",
//                               fileName: fileObj.name,
//                             })
//                           );
//                         });
//                       } else {
//                         throw Error(error);
//                       }
//                     }
//                   );
//                 });
//               } else {
//                 throw Error(error);
//               }
//             });
//           } else {
//             throw Error(error);
//           }
//         });
//       });
//     } else {
//       throw Error(error);
//     }
//   });
// };
export const downloadFile = (fileUuid, currentSubmission) => __awaiter(void 0, void 0, void 0, function* () {
    let uploadedFile = currentSubmission.files.filter((file) => file.uuid === fileUuid)[0];
    let getUrlForDownload = `${API_ROOT}api/files/submissions/${currentSubmission.uuid}/sign-url-download`;
    yield fetchWrapper("POST", getUrlForDownload, {}, {
        file_uuid: fileUuid,
        file_path: uploadedFile.file_path,
    }).then((data) => {
        let [downloadUrlPromise, error] = data;
        if (downloadUrlPromise) {
            downloadUrlPromise.then((response) => {
                window.open(response.url);
            });
        }
        else {
            throw Error(error);
        }
    });
});
// export const deleteFile = async (
//   currentSubmission: UserRequirementResult,
//   deleteFileUuid: string,
//   timelineUuid: string | undefined,
//   generateActivity: boolean
// ) => {
//   let fileUuid = deleteFileUuid;
//   let urlToDelete = `${API_ROOT}api/files/delete/${fileUuid}`;
//   let fileName = currentSubmission.files.filter(
//     (file) => file.uuid === deleteFileUuid
//   )[0].file_name;
//   await fetchWrapper("DELETE", urlToDelete, {}, { generateActivity }).then(
//     (data: any) => {
//       let [deleteUrlPromise, error] = data;
//       if (deleteUrlPromise) {
//         deleteUrlPromise.then((response: any) => {
//           let allFilesUrl = `${API_ROOT}api/files/submissions/${currentSubmission.uuid}`;
//           fetchWrapper("GET", allFilesUrl, {}, null).then((resData: any) => {
//             let [updatedFilesPromise, error] = resData;
//             if (updatedFilesPromise) {
//               updatedFilesPromise.then((data: any) => {
//                 store.dispatch(
//                   setUpdatedFiles({
//                     fileData: data,
//                     operation: "DELETE",
//                     fileName: fileName,
//                     currentSubmission: currentSubmission,
//                   })
//                 );
//                 if (data.length === 0) {
//                   store.dispatch(
//                     updateSubmission({
//                       note: currentSubmission.reviewNote,
//                       status:
//                         currentSubmission.status == null
//                           ? "NOT_SUBMITTED"
//                           : currentSubmission.status,
//                       timelineUuid: timelineUuid,
//                       requirementUuid: currentSubmission.requirementUuid,
//                       submissionUuid: currentSubmission.uuid,
//                     })
//                   );
//                 } else {
//                   store.dispatch(
//                     getSubmissionRecord({
//                       timelineUuid: timelineUuid,
//                       requirementUuid: currentSubmission.requirementUuid,
//                       submissionUuid: currentSubmission.uuid,
//                     })
//                   );
//                 }
//               });
//             } else {
//               throw Error(error);
//             }
//           });
//         });
//       } else {
//         throw Error(error);
//       }
//     }
//   );
// };
export const saveLeadsByOrg = createAsyncThunk("/saveLeadsByOrg", ({ orgUuid, programUuid, cohortUuid, usersList }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgUuid}/programs/${programUuid}/cohorts/${cohortUuid}/lead`, {}, {
        userUuids: usersList,
    });
    return data ? data : error;
}));
// export const deleteFileOnDiscard = (
//   currentSubmission: UserRequirementResult,
//   deleteFileUuids: Array<string>,
//   timelineUuid: string | undefined,
//   callback: any
// ) => {
//   deleteFileUuids.map((deleteFileUuid) => {
//     let fileUuid = deleteFileUuid;
//     let urlToDelete = `${API_ROOT}api/files/delete/${fileUuid}`;
//     let fileName = currentSubmission.files.filter(
//       (file) => file.uuid === deleteFileUuid
//     )[0].file_name;
//     fetchWrapper("DELETE", urlToDelete, {}, { generateActivity: false }).then(
//       (data: any) => {
//         let [deleteUrlPromise, error] = data;
//         if (deleteUrlPromise) {
//           deleteUrlPromise.then((response: any) => {
//             let allFilesUrl = `${API_ROOT}api/files/submissions/${currentSubmission.uuid}`;
//             fetchWrapper("GET", allFilesUrl, {}, null).then((resData: any) => {
//               let [updatedFilesPromise, error] = resData;
//               if (updatedFilesPromise) {
//                 updatedFilesPromise.then((data: any) => {
//                   store.dispatch(
//                     setUpdatedFilesInSubmissionList({
//                       fileData: data,
//                       currentSubmissionUuid: currentSubmission.uuid,
//                     })
//                   );
//                   callback();
//                 });
//               } else {
//                 throw Error(error);
//               }
//             });
//           });
//         } else {
//           throw Error(error);
//         }
//       }
//     );
//   });
// };
export const getStudentApplicationCohort = createAsyncThunk("/getStudentApplicationCohort", ({ userRequirementResultUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/user-requirement-results/${userRequirementResultUuid}/cohort`, {}, null);
    return data ? data : error;
}));
export const getProgram = createAsyncThunk("/getProgram", ({ programUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/programs/${programUuid}`, {}, null);
    return data ? data : error;
}));
export const isStudentSubmissionExistForCohort = createAsyncThunk("/isStudentSubmissionExistForCohort", ({ cohortUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/cohort/${cohortUuid}/submission-exists`, {}, null);
    return data ? data : error;
}));
export const disconnectPlan = createAsyncThunk("/disconnectPlan", ({ cohortUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("DELETE", `${API_ROOT}api/cohort/${cohortUuid}/disconnect-plan`, {}, null);
    return data ? data : error;
}));
export const isStudentSubmissionExistForRequirement = createAsyncThunk("/isStudentSubmissionExistForRequirement", ({ requirementUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/requirements/${requirementUuid}/submission-exists`, {}, null);
    return data ? data : error;
}));
export const getTerms = createAsyncThunk("/getTerms", ({ orgUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/terms`, {}, null);
    return data ? data : error;
}));
export const getLogCategories = createAsyncThunk("/getLogCategories", (thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/log-categories`, {}, null);
    return data ? data : error;
}));
export const getLogCategoryCourses = createAsyncThunk("/getLogCategoryCourses", (courseIds, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/log-category/courses`, {}, { courseIds: courseIds });
    return data ? data : error;
}));
export const createExtract = createAsyncThunk("/createExtract", ({ cohortUuid, studentUuidList, selectedRequirements, orgId, progUuid, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgId}/programs/${progUuid}/cohorts/${cohortUuid}/extract`, {}, {
        studentUuidList,
        selectedRequirements,
    });
    return data ? data : error;
}));
export const getAllExtracts = createAsyncThunk("/getAllExtracts", ({ cohortUuid, studentUuidList, requirementUuidList, orgId, progUuid, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgId}/programs/${progUuid}/cohorts/${cohortUuid}/extracts`, {}, null);
    return data ? data : error;
}));
export const downloadExtract = (fileData) => __awaiter(void 0, void 0, void 0, function* () {
    let getUrlForDownload = `${API_ROOT}api/files/sign-url-download`;
    yield fetchWrapper("POST", getUrlForDownload, {}, {
        file_uuid: fileData.fileUuid,
        file_path: fileData.filePath,
    }).then((data) => {
        let [downloadUrlPromise, error] = data;
        if (downloadUrlPromise) {
            downloadUrlPromise.then((response) => {
                window.open(response, "_self");
            });
        }
        else {
            throw Error(error);
        }
    });
});
export const retryExtract = createAsyncThunk("/retryExtract", ({ orgId, progUuid, cohortUuid, extractUuid }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgId}/programs/${progUuid}/cohorts/${cohortUuid}/extract/${extractUuid}`, {}, null);
    return data ? data : error;
}));
export const retryUserExtract = createAsyncThunk("/retryUserExtract", ({ orgId, progUuid, cohortUuid, extractUuid }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgId}/programs/${progUuid}/cohorts/${cohortUuid}/extracts/${extractUuid}/retry`, {}, null);
    return data ? data : error;
}));
export const saveUserExtract = createAsyncThunk("/saveUserExtract", ({ cohortUuid, requirementUuidList, orgUuid, progUuid }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgUuid}/programs/${progUuid}/cohorts/${cohortUuid}/user-extract`, {}, {
        requirementUuidList,
    });
    return data ? data : error;
}));
export const createUserExtract = createAsyncThunk("/createUserExtract", ({ cohortUuid, orgUuid, progUuid, extractUuid }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgUuid}/programs/${progUuid}/cohorts/${cohortUuid}/extracts/${extractUuid}`, {}, null);
    return data ? data : error;
}));
export const updateUserExtract = createAsyncThunk("/updateUserExtract", ({ cohortUuid, orgUuid, progUuid, extractUuid }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("PUT", `${API_ROOT}api/organizations/${orgUuid}/programs/${progUuid}/cohorts/${cohortUuid}/extracts/${extractUuid}`, {}, null);
    return data ? data : error;
}));
export const getZipExport = createAsyncThunk("/getZipExport", ({ cohortUuid, orgUuid, progUuid, extractUuid }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organization/${orgUuid}/program/${progUuid}/cohort/${cohortUuid}/Zipexport/${extractUuid}`, {}, null);
    return data ? data : error;
}));
export const getSignedUrlForZip = createAsyncThunk("/getSignedUrlForZip", ({ cohortUuid, orgUuid, progUuid, extractUuid }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organization/${orgUuid}/program/${progUuid}/cohort/${cohortUuid}/zipfile/${extractUuid}`, {}, null);
    return data ? data : error;
}));
export const getUserExtract = createAsyncThunk("/getUserExtract", ({ cohortUuid, orgUuid, progUuid, extractUuid }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/organizations/${orgUuid}/programs/${progUuid}/cohorts/${cohortUuid}/extracts/${extractUuid}`, {}, null);
    return data ? data : error;
}));
export const getRequirementsSubmissions = createAsyncThunk("/getRequirementsSubmissions", ({ timelineUuid, cohortUuid }, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("GET", `${API_ROOT}api/timelines/${timelineUuid}/cohort/${cohortUuid}/reqSubmissions`, {}, null);
    return data ? data : error;
}));
export const createSubmissionCsv = createAsyncThunk("/createSubmissionCsv", ({ selectedRequirementUuids, selectedUserUuids, orgUuid, progUuid, cohortUuid, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgUuid}/programs/${progUuid}/cohorts/${cohortUuid}/create-csv`, {}, {
        requirements: selectedRequirementUuids,
        users: selectedUserUuids,
    }, undefined, false);
    if (error) {
        throw error;
    }
    return data;
}));
export const createCsvZip = createAsyncThunk("/createCsvZip", ({ selectedRequirements, selectedUserUuids, orgUuid, progUuid, cohortUuid, }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/organizations/${orgUuid}/programs/${progUuid}/cohorts/${cohortUuid}/create-csv-zip`, {}, {
        selectedRequirements: selectedRequirements,
        studentUuidList: selectedUserUuids,
    }, undefined, false);
    if (error) {
        throw error;
    }
    return data;
}));
export const downloadSubmissionPdf = createAsyncThunk("/downloadSubmissionPdf", ({ header, content, footer, version }) => __awaiter(void 0, void 0, void 0, function* () {
    const [data, error] = yield fetchWrapper("POST", `${API_ROOT}api/gateways/pdf/export`, {}, {
        header,
        content,
        footer,
        version,
    }, undefined, true, "arrayBuffer");
    if (error) {
        throw error;
    }
    return data;
}));
export const cohortSliceInitialState = {
    cohortList: [],
    disableAction: false,
    currentCohortUuid: "",
    snackNotifications: "[]",
    fileNotifications: "[]",
    planStatusFilter: [],
    cohortStatusFilter: [],
    selectedStatus: "ALL",
    statusCounter: null,
    unpublishCounter: null,
    filteredIndex: -1,
    allStudentSubmissions: [],
    currentSubmission: {},
    reviewableSubmissions: [],
    focusOnTable: null,
    isFetchingRequirementData: false,
    isFetchingCohorts: false,
    isFetchingCohort: false,
    currentCohort: {},
    isFetchingStudentData: false,
    persistState: false,
    currentStudentSubmissions: [],
    showStudentProgressFlyout: false,
    customText: "",
    publishingCohort: null,
    publishingCohortUuid: "",
    publishingCohortTitle: "",
    publishingCohortReleaseDate: "",
    publishingCohortSendNotification: false,
    apiDateError: false,
    uploadInProgress: false,
    lastUploadIndex: -1,
    selectedUserUuids: [],
    selectedExtractReqUuids: [],
    isFetchingSelectedUsers: false,
    pushedBulkUpdate: false,
    updatedNote: false,
    isFetchingStudentApplicationCohorts: false,
    studentApplicationCohort: {},
    isFetchingStudentProgram: false,
    isFetching: false,
    isSubmissionExistForCohort: false,
    isSubmissionExistForRequirement: false,
    isDisconnectingPlan: false,
    programData: {},
    extractList: [],
    isFetchingExtracts: false,
    isTimelineSubmissionExist: false,
    isFetchingLogCategories: false,
    logCategories: [],
    isFetchingLogCategoryCourses: false,
    logCategoryCourses: [],
    logCategory: {},
    termList: [],
    userExtract: {},
    isFetchingReqSubmissions: false,
    allRequirementSubmissions: [],
    runningApiCounter: 0,
    lpReqExportRejected: false,
    lpReqExportDetailsCsv: null,
    lpReqPdfExportRejected: false,
    lpReqPdfExportDetails: null,
    lpInsightsSearchReqText: "",
    lpInsightsFilterBy: "",
    lpInsightsSortBy: "",
    lpInsightsScrollTop: 0,
    lpInsightsExpandDetails: {},
    lpReqCsvZipExportDetails: null,
    lpReqCsvZipExportRejected: false,
};
export const cohortSlice = createSlice({
    name: "cohortSliceData",
    initialState: cohortSliceInitialState,
    reducers: {
        closeSnackbar: (state, { payload }) => {
            const snackList = JSON.parse(state.snackNotifications);
            const snackIndex = snackList.findIndex((snack) => snack.id === payload);
            snackList.splice(snackIndex, 1);
            state.snackNotifications = JSON.stringify(snackList);
        },
        closeFileSnackbar: (state, { payload }) => {
            const snackList = JSON.parse(state.fileNotifications);
            const snackIndex = snackList.findIndex((snack) => snack.id === payload);
            snackList.splice(snackIndex, 1);
            state.fileNotifications = JSON.stringify(snackList);
        },
        resetFileNotifications: (state) => {
            state.fileNotifications = JSON.stringify([]);
        },
        setSelectedStatus: (state, { payload }) => {
            state.selectedStatus = payload;
            return state;
        },
        resetCohort: (state) => {
            state.snackNotifications = "[]";
            state.cohortList = [];
            state.selectedStatus = "ALL";
            state.statusCounter = null;
            state.unpublishCounter = null;
            return state;
        },
        setCurrentSubmissionAndInitReviewableList: (state, { payload }) => {
            state.currentSubmission = payload.submission;
            state.reviewableSubmissions = payload.noOfStudentForReview;
            if (payload.isStudentProgressPage) {
                state.showStudentProgressFlyout = true;
            }
        },
        setShowStudentProgressFlyout: (state, { payload }) => {
            state.showStudentProgressFlyout = false;
        },
        updateReviewableAndAllSubmissions: (state, { payload }) => {
            let index = state.reviewableSubmissions.findIndex((sub) => sub.uuid === payload.uuid);
            state.reviewableSubmissions[index] = payload;
            index = state.allStudentSubmissions.findIndex((sub) => sub.uuid === payload.uuid);
            state.allStudentSubmissions[index] = payload;
        },
        setFocusOnTable: (state, { payload }) => {
            state.focusOnTable = payload;
        },
        clearAllStudentSubmission: (state, { payload }) => {
            state.allStudentSubmissions = payload;
        },
        setPersistState: (state, { payload }) => {
            state.persistState = payload;
        },
        setCustomText: (state, { payload }) => {
            state.customText = payload;
        },
        setUpdatedFiles: (state, { payload }) => {
            const submission = Object.assign({}, state.currentSubmission);
            submission.files = payload.fileData;
            state.currentSubmission = Object.assign({}, submission);
            if (payload.operation === "DELETE") {
                notifications(state, { title: payload.fileName }, "", " has been deleted.", "FILE");
            }
            else if (payload.operation === "UPLOAD") {
                notifications(state, { title: payload.fileName }, "", " has been uploaded.", "FILE");
            }
            return state;
        },
        setUpdatedFilesInSubmissionList: (state, { payload }) => {
            if (state.allStudentSubmissions) {
                let id = payload.currentSubmissionUuid;
                let selectedSubmission = state.allStudentSubmissions.filter((sub) => sub.uuid === id)[0];
                if (selectedSubmission) {
                    selectedSubmission.files = payload.fileData;
                    if (state.currentSubmission) {
                        state.currentSubmission.files = payload.fileData;
                    }
                }
            }
            if (state.currentStudentSubmissions) {
                let id = payload.currentSubmissionUuid;
                let selectedSubmission = state.currentStudentSubmissions.filter((sub) => sub.uuid === id)[0];
                if (selectedSubmission) {
                    selectedSubmission.files = payload.fileData;
                }
            }
        },
        setPushedBulkUpdate: (state, { payload }) => {
            state.pushedBulkUpdate = payload;
        },
        connectCohortFlyout: (state, { payload }) => {
            state.publishingCohort = payload.publishingCohort;
            state.publishingCohortUuid = payload.cohortUuid;
            state.publishingCohortTitle = payload.cohortTitle;
            state.publishingCohortReleaseDate = payload.releaseDate;
            state.publishingCohortSendNotification = payload.sendNotification;
        },
        setApiDateError: (state, { payload }) => {
            state.apiDateError = payload;
        },
        resetPublishCohortID: (state) => {
            state.publishingCohortTitle = "";
            state.publishingCohortUuid = "";
            state.publishingCohortReleaseDate = "";
            state.publishingCohortSendNotification = false;
        },
        updateActionsByUploadStatus: (state, { payload }) => {
            state.uploadInProgress = payload.uploadInProgress;
            state.lastUploadIndex = payload.lastUploadIndex;
        },
        resetIsSubmissionExistForRequirement: (state) => {
            state.isSubmissionExistForRequirement = false;
        },
        resetLogCategoryCourses: (state) => {
            state.logCategoryCourses = [];
        },
        resetLogCategory: (state) => {
            state.logCategory = {};
        },
        setLogCategory: (state, { payload }) => {
            var _a, _b;
            let category = Object.assign({}, payload);
            const courses = ((_a = category === null || category === void 0 ? void 0 : category.courses) === null || _a === void 0 ? void 0 : _a.length)
                ? (_b = [...category === null || category === void 0 ? void 0 : category.courses]) === null || _b === void 0 ? void 0 : _b.sort((a, b) => {
                    return (a === null || a === void 0 ? void 0 : a.name).localeCompare(b === null || b === void 0 ? void 0 : b.name);
                })
                : [];
            category.courses = courses;
            state.logCategory = category;
        },
        resetRequirementsSubmissions: (state) => {
            state.allRequirementSubmissions = [];
        },
        setLpInsightsState: (state, { payload }) => {
            var _a, _b, _c, _d, _e;
            state.lpInsightsExpandDetails =
                (_a = payload.lpInsightsExpandDetails) !== null && _a !== void 0 ? _a : state.lpInsightsExpandDetails;
            state.lpInsightsSearchReqText =
                (_b = payload.lpInsightsSearchReqText) !== null && _b !== void 0 ? _b : state.lpInsightsSearchReqText;
            state.lpInsightsFilterBy =
                (_c = payload.lpInsightsFilterBy) !== null && _c !== void 0 ? _c : state.lpInsightsFilterBy;
            state.lpInsightsSortBy =
                (_d = payload.lpInsightsSortBy) !== null && _d !== void 0 ? _d : state.lpInsightsSortBy;
            state.lpInsightsScrollTop =
                (_e = payload.lpInsightsScrollTop) !== null && _e !== void 0 ? _e : state.lpInsightsScrollTop;
        },
    },
    extraReducers: {
        "/getAllCohortsForOrganization/pending": (state, { payload }) => {
            state.isFetchingCohorts = true;
        },
        "/getAllCohortsForOrganization/fulfilled": (state, { payload }) => {
            state.cohortList = payload;
            state.isFetchingCohorts = false;
            state.planStatusFilter = Array.from(new Set(state.cohortList.map((cohort) => {
                return cohort.cohortTimeline
                    ? cohort.cohortTimeline.status
                    : "UNPUBLISHED";
            })))
                .sort()
                .reverse();
            state.cohortStatusFilter = Array.from(new Set(state.cohortList.map((cohort) => {
                return cohort.status;
            })))
                .sort()
                .reverse();
            return state;
        },
        "/connectPlanToCohort/pending": (state, { payload }) => {
            state.disableAction = true;
        },
        "/connectPlanToCohort/fulfilled": (state, { payload }) => {
            var _a;
            const cohortIndex = state.cohortList.findIndex((cohort) => cohort.uuid === payload.uuid);
            const filtered = state.cohortList
                .filter((coh) => (coh.cohortTimeline ? false : true))
                .findIndex((cohort) => cohort.uuid === payload.uuid);
            state.currentCohortUuid = payload.uuid;
            state.cohortList[cohortIndex] = payload;
            state.currentCohort = payload;
            state.disableAction = false;
            state.planStatusFilter = Array.from(new Set(state.cohortList.map((cohort) => {
                return cohort.cohortTimeline
                    ? cohort.cohortTimeline.status
                    : "UNPUBLISHED";
            })))
                .sort()
                .reverse();
            if (state.selectedStatus !== "ALL") {
                state.unpublishCounter = state.cohortList.filter((coh) => coh.cohortTimeline ? false : true).length;
                state.filteredIndex = filtered;
            }
            else {
                state.unpublishCounter = -Math.random();
            }
            if (!state.planStatusFilter.includes(state.selectedStatus)) {
                state.selectedStatus = "ALL";
                state.customText = "All";
            }
            const currentStatus = (_a = state.cohortList[cohortIndex].cohortTimeline) === null || _a === void 0 ? void 0 : _a.status;
            if (currentStatus) {
                let snackText = currentStatus == "SCHEDULED" ? "scheduled for release" : "connected";
                notifications(state, { title: state.publishingCohortTitle }, `Licensure plan has been ${snackText} to `, "", "");
            }
            state.publishingCohort = false;
            return state;
        },
        "/connectPlanToCohort/rejected": (state, { payload }) => {
            state.apiDateError = true;
            state.disableAction = false;
        },
        "/changeCohortStatus/fulfilled": (state, { payload }) => {
            const cohortIndex = state.cohortList.findIndex((cohort) => cohort.uuid === payload.cohortUuid);
            const cohort = state.cohortList[cohortIndex];
            const previousStatus = cohort.cohortTimeline.status;
            const filtered = state.cohortList
                .filter((coh) => coh.cohortTimeline
                ? coh.cohortTimeline.status == previousStatus
                : false)
                .findIndex((cohort) => cohort.uuid === payload.cohortUuid);
            cohort.cohortTimeline = payload;
            state.cohortList[cohortIndex] = cohort;
            state.planStatusFilter = Array.from(new Set(state.cohortList.map((cohort) => {
                return cohort.cohortTimeline
                    ? cohort.cohortTimeline.status
                    : "UNPUBLISHED";
            })))
                .sort()
                .reverse();
            if (state.selectedStatus !== "ALL") {
                state.statusCounter = state.cohortList.filter((coh) => coh.cohortTimeline
                    ? coh.cohortTimeline.status == previousStatus
                    : false).length;
                state.filteredIndex = filtered;
            }
            if (!state.planStatusFilter.includes(state.selectedStatus)) {
                state.selectedStatus = "ALL";
                state.customText = "All";
            }
            notifications(state, cohort, "Licensure plan for ", " has been " +
                (payload.status == "ARCHIVED" ? "archived." : "restored."), "");
            return state;
        },
        "/getStudentSubmissions/pending": (state, { payload }) => {
            state.isFetchingRequirementData = true;
        },
        "/getStudentSubmissions/fulfilled": (state, { payload }) => {
            var _a;
            state.allStudentSubmissions = payload;
            state.allRequirementSubmissions = (_a = state.allRequirementSubmissions) === null || _a === void 0 ? void 0 : _a.map((sub) => {
                if (state.allStudentSubmissions.some((element) => element.uuid == sub.uuid)) {
                    return state.allStudentSubmissions[state.allStudentSubmissions.findIndex((el) => el.uuid == sub.uuid)];
                }
                else {
                    return sub;
                }
            });
            state.isFetchingRequirementData = false;
        },
        "/changeBulkStatus/pending": (state, { payload }) => {
            state.pushedBulkUpdate = false;
        },
        "/changeBulkStatus/fulfilled": (state, { payload }) => {
            state.pushedBulkUpdate = true;
        },
        "/updateNote/pending": (state, { payload }) => {
            state.updatedNote = false;
        },
        "/updateNote/fulfilled": (state, { payload }) => {
            state.updatedNote = true;
            let tempSubHistories = [...state.currentSubmission.submissionHistories];
            const ind = tempSubHistories.findIndex((his) => his.uuid === payload.uuid);
            tempSubHistories[ind] = payload;
            const tempCurrentSub = Object.assign(Object.assign({}, state.currentSubmission), { submissionHistories: tempSubHistories });
            state.currentSubmission = tempCurrentSub;
        },
        "/updateSubmission/pending": (state, { payload }) => {
        },
        "/updateSubmission/fulfilled": (state, { payload }) => {
            const submissions = [...state.allStudentSubmissions];
            const updated = submissions.map((sub) => {
                if (sub.uuid == payload.uuid) {
                    return payload;
                }
                return sub;
            });
            state.currentSubmission = payload;
            state.allStudentSubmissions = [...updated];
            state.focusOnTable = true;
        },
        "/getFileContents/fulfilled": (state, { payload }) => { },
        "/getSubmissionRecord/pending": (state, { payload }) => {
            state.disableAction = true;
        },
        "/getSubmissionRecord/fulfilled": (state, { payload }) => {
            let index = state.allStudentSubmissions.findIndex((sub) => sub.uuid === payload.uuid);
            state.allStudentSubmissions[index] = payload;
            state.currentSubmission = payload;
            state.disableAction = false;
        },
        "/getCohort/pending": (state) => {
            state.isFetchingCohort = true;
        },
        "/getCohort/fulfilled": (state, { payload }) => {
            var _a;
            state.currentCohort = payload;
            state.isFetchingCohort = false;
            state.extractList = payload.extracts;
            state.selectedUserUuids = (_a = payload.leads) === null || _a === void 0 ? void 0 : _a.map((lead) => lead.uuid);
        },
        "/getCurrentStudentSubmissions/pending": (state, { payload }) => {
            state.isFetchingStudentData = true;
            state.isTimelineSubmissionExist = false;
        },
        "/getCurrentStudentSubmissions/fulfilled": (state, { payload }) => {
            var _a;
            state.isFetchingStudentData = false;
            state.currentStudentSubmissions = payload;
            (_a = state.currentStudentSubmissions) === null || _a === void 0 ? void 0 : _a.forEach((submission) => {
                var _a;
                if (((_a = submission.submissionHistories) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    state.isTimelineSubmissionExist = true;
                    return;
                }
            });
        },
        "/saveLeadsByOrg/pending": (state, { payload }) => {
            state.isFetchingSelectedUsers = true;
        },
        "/saveLeadsByOrg/fulfilled": (state, { payload }) => {
            state.isFetchingSelectedUsers = false;
            state.selectedUserUuids = payload.userUuids;
        },
        "/getStudentApplicationCohort/pending": (state, { payload }) => {
            state.isFetchingStudentApplicationCohorts = true;
        },
        "/getStudentApplicationCohort/fulfilled": (state, { payload }) => {
            state.isFetchingStudentApplicationCohorts = false;
            state.studentApplicationCohort = payload;
        },
        "/getProgram/pending": (state, { payload }) => {
            state.isFetchingStudentProgram = true;
        },
        "/getProgram/fulfilled": (state, { payload }) => {
            state.isFetchingStudentProgram = false;
            state.programData = payload;
        },
        "/isStudentSubmissionExistForCohort/pending": (state, { payload }) => {
            state.isFetching = true;
        },
        "/isStudentSubmissionExistForCohort/fulfilled": (state, { payload }) => {
            state.isFetching = false;
            state.isSubmissionExistForCohort = payload;
        },
        "/disconnectPlan/pending": (state, { payload }) => {
            state.isDisconnectingPlan = true;
        },
        "/disconnectPlan/fulfilled": (state, { payload }) => {
            state.isDisconnectingPlan = false;
        },
        "/isStudentSubmissionExistForRequirement/pending": (state, { payload }) => {
            state.isFetching = true;
        },
        "/isStudentSubmissionExistForRequirement/fulfilled": (state, { payload }) => {
            state.isFetching = false;
            state.isSubmissionExistForRequirement = payload;
        },
        "/createExtract/pending": (state) => {
            state.isFetchingExtracts = true;
        },
        "/createExtract/fulfilled": (state, { payload }) => {
            var _a, _b;
            (_b = (_a = state.currentCohort) === null || _a === void 0 ? void 0 : _a.extracts) === null || _b === void 0 ? void 0 : _b.push(payload);
            state.isFetchingExtracts = false;
            notifications(state, undefined, "Requirement Extract is being created.", "", "");
        },
        "/saveUserExtract/pending": (state) => {
            state.isFetchingExtracts = true;
        },
        "/saveUserExtract/fulfilled": (state, { payload }) => {
            state.userExtract = payload;
            state.isFetchingExtracts = false;
            notifications(state, undefined, "Requirement Extract is being created.", "", "");
        },
        "/createUserExtract/pending": (state) => {
            state.isFetchingExtracts = false;
        },
        "/createUserExtract/fulfilled": (state, { payload }) => {
            state.userExtract = payload;
            state.isFetchingExtracts = false;
        },
        "/retryUserExtract/pending": (state) => {
            state.isFetchingExtracts = false;
        },
        "/retryUserExtract/fulfilled": (state, { payload }) => {
            state.userExtract = payload;
            state.isFetchingExtracts = false;
        },
        "/updateUserExtract/pending": (state) => {
            state.isFetchingExtracts = false;
        },
        "/updateUserExtract/fulfilled": (state, { payload }) => {
            state.userExtract = payload;
            state.isFetchingExtracts = false;
        },
        "/getUserExtract/pending": (state) => {
            state.isFetchingExtracts = true;
        },
        "/getUserExtract/fulfilled": (state, { payload }) => {
            state.userExtract = payload;
            state.isFetchingExtracts = false;
        },
        "/getZipExport/pending": (state) => {
            state.isFetchingExtracts = true;
        },
        "/getZipExport/fulfilled": (state, { payload }) => {
            state.userExtract = payload;
            state.isFetchingExtracts = true;
        },
        "/getSignedUrlForZip/pending": (state) => {
            state.isFetchingExtracts = true;
        },
        "/getSignedUrlForZip/fulfilled": (state, { payload }) => {
            state.isFetchingExtracts = false;
            state.lpReqCsvZipExportDetails = payload;
        },
        "/getAllExtracts/pending": (state) => {
            state.isFetchingExtracts = true;
        },
        "/getAllExtracts/fulfilled": (state, { payload }) => {
            state.extractList = payload;
            state.isFetchingExtracts = false;
        },
        "/retryExtract/fulfilled": (state, { payload }) => {
            state.extractList.map((e) => {
                if (e.uuid === payload.uuid) {
                    e.status = payload.status;
                }
                return e;
            });
        },
        "/getLogCategories/pending": (state, { payload }) => {
            state.isFetchingLogCategories = true;
        },
        "/getLogCategories/fulfilled": (state, { payload }) => {
            state.isFetchingLogCategories = false;
            let categories = payload;
            categories === null || categories === void 0 ? void 0 : categories.sort((a, b) => {
                return a.title.localeCompare(b.title);
            });
            state.logCategories = categories;
        },
        "/getLogCategoryCourses/pending": (state, { payload }) => {
            state.isFetchingLogCategoryCourses = true;
        },
        "/getLogCategoryCourses/fulfilled": (state, { payload }) => {
            state.isFetchingLogCategoryCourses = false;
            let courses = payload;
            courses === null || courses === void 0 ? void 0 : courses.sort((a, b) => {
                return ((a === null || a === void 0 ? void 0 : a.catalogCode) + " - " + (a === null || a === void 0 ? void 0 : a.name)).localeCompare((b === null || b === void 0 ? void 0 : b.catalogCode) + " - " + (b === null || b === void 0 ? void 0 : b.name));
            });
            state.logCategoryCourses = courses;
        },
        "/getTerms/pending": (state, { payload }) => { },
        "/getTerms/fulfilled": (state, { payload }) => {
            state.termList = payload;
        },
        "/getRequirementsSubmissions/pending": (state, { payload }) => {
            state.isFetchingReqSubmissions = true;
        },
        "/getRequirementsSubmissions/fulfilled": (state, { payload }) => {
            state.allRequirementSubmissions = payload;
            state.isFetchingReqSubmissions = false;
        },
        "/createSubmissionCsv/pending": (state, { payload }) => {
            state.lpReqExportRejected = false;
        },
        "/createSubmissionCsv/fulfilled": (state, { payload }) => {
            state.lpReqExportDetailsCsv = payload;
        },
        "/createSubmissionCsv/rejected": (state, { payload }) => {
            state.lpReqExportRejected = true;
        },
        "/createCsvZip/pending": (state, { payload }) => {
            state.lpReqCsvZipExportRejected = false;
        },
        "/createCsvZip/fulfilled": (state, { payload }) => {
            state.userExtract = payload;
            state.isFetchingExtracts = false;
        },
        "/createCsvZip/rejected": (state, { payload }) => {
            state.lpReqCsvZipExportRejected = true;
        },
        "/downloadSubmissionPdf/pending": (state, { payload }) => {
            state.lpReqPdfExportRejected = false;
        },
        "/downloadSubmissionPdf/fulfilled": (state, { payload }) => {
            state.lpReqPdfExportDetails = payload;
        },
        "/downloadSubmissionPdf/rejected": (state, { payload }) => {
            state.lpReqPdfExportRejected = true;
        },
    },
});
const notifications = (state, payload, prefix, suffix, type) => {
    let notification = {
        id: (Math.floor(Math.random() * 100) + 1).toString(),
        message: prefix + (payload !== undefined ? payload.title : "") + suffix,
        link: "",
    };
    if (type == "FILE") {
        let notificationList = JSON.parse(state.fileNotifications);
        notificationList.unshift(notification);
        state.fileNotifications = JSON.stringify(notificationList);
    }
    else {
        let notificationList = JSON.parse(state.snackNotifications);
        notificationList.unshift(notification);
        state.snackNotifications = JSON.stringify(notificationList);
    }
};
export default cohortSlice.reducer;
export const { closeSnackbar, setSelectedStatus, clearAllStudentSubmission, setPersistState, resetCohort, setCurrentSubmissionAndInitReviewableList, setShowStudentProgressFlyout, updateReviewableAndAllSubmissions, setFocusOnTable, setCustomText, setUpdatedFiles, setUpdatedFilesInSubmissionList, closeFileSnackbar, resetFileNotifications, setApiDateError, resetPublishCohortID, connectCohortFlyout, updateActionsByUploadStatus, setPushedBulkUpdate, resetIsSubmissionExistForRequirement, resetLogCategoryCourses, resetLogCategory, setLogCategory, resetRequirementsSubmissions, setLpInsightsState, } = cohortSlice.actions;
export const cohortSelector = (state) => state.cohortSliceData;
