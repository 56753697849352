import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { WmButton } from "@watermarkinsights/ripple-react";
import { focusButtonComponent, openFlyout } from "../../../static/js/commons";
export const SideFlyout = (props) => {
    const { flyoutId, focusAfterClosed, ariaLabel } = props;
    useEffect(() => {
        focusButtonComponent("sideFlyout-close");
        setTimeout(() => openFlyout(flyoutId, focusAfterClosed, "sideFlyout-close"), 200);
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: "sr-only", id: "dialog-wrapper-top", tabIndex: 0 }), _jsxs("div", Object.assign({ className: "flyout-panel half-flyout sideFlyout show", role: "dialog", id: flyoutId, tabIndex: -1, "aria-label": ariaLabel }, { children: [_jsx("div", { className: "overlay" }), _jsx("div", Object.assign({ className: "container" }, { children: props.children }))] })), _jsx("div", { className: "sr-only", id: "dialog-wrapper-bottom", tabIndex: 0 })] }));
};
const SideFlyoutHeader = (props) => {
    const { breadCrumb, modalHeader, modalSubheader, modalRequiredPrefix, modalRequiredSuffix, closeFlyout, flyoutFrom, modalInfoText, } = props;
    const closeTooltipText = useIntl().formatMessage({
        id: "app.viewcohort.close",
    });
    return (_jsxs(_Fragment, { children: [_jsxs("section", Object.assign({ className: "header-section" }, { children: [_jsxs("div", Object.assign({ className: "header-title" }, { children: [breadCrumb, modalHeader && (_jsx("h2", Object.assign({ className: flyoutFrom == "application" ? "modal-subHeader" : "modal-header", style: {
                                    textTransform: flyoutFrom == "application" ? "capitalize" : "uppercase",
                                } }, { children: modalHeader }))), modalSubheader && (_jsx("h3", Object.assign({ className: flyoutFrom == "application" ? "modal-header" : "modal-subHeader", style: {
                                    textTransform: flyoutFrom == "application" ? "capitalize" : "none",
                                } }, { children: modalSubheader })))] })), _jsx(WmButton, { className: "close-btn", buttonType: "navigational", icon: "close", tooltip: closeTooltipText, tooltipPosition: "bottom", id: "sideFlyout-close", class: "hydrated", onClick: closeFlyout })] })), _jsxs("div", Object.assign({ className: "required-info-msg" }, { children: [modalInfoText && (_jsx("div", Object.assign({ className: "modal-info-text" }, { children: modalInfoText }))), modalRequiredPrefix && modalRequiredSuffix && (_jsxs("div", Object.assign({ className: classNames("required-fields", !modalInfoText && "center") }, { children: [modalRequiredPrefix, _jsx("span", Object.assign({ className: "reqd" }, { children: " * " })), modalRequiredSuffix] })))] }))] }));
};
const SideFlyoutContent = (props) => {
    return _jsx("section", Object.assign({ className: "content-section" }, { children: props.children }));
};
const SideFlyoutFooter = (props) => {
    return _jsx("section", Object.assign({ className: "footer-section" }, { children: props.children }));
};
SideFlyout.Header = SideFlyoutHeader;
SideFlyout.Content = SideFlyoutContent;
SideFlyout.Footer = SideFlyoutFooter;
