import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmModal, WmModalHeader, WmModalFooter, } from "@watermarkinsights/ripple-react";
export default function WmActionModal(props) {
    var _a, _b;
    if (!props.isOpen) {
        return _jsx(_Fragment, {});
    }
    else {
        return (_jsxs(WmModal, Object.assign({ open: props.isOpen, modalType: props.modalType, id: props.modalId, elementToFocus: (_a = props.elementToFocus) !== null && _a !== void 0 ? _a : "secondary", "aria-label": props.ariaLabelValue, onWmModalCloseTriggered: props.onClose, onWmModalPrimaryTriggered: props.onPrimaryClick, onWmModalSecondaryTriggered: props.onSecondaryClick, uid: "", placeholder: undefined }, { children: [_jsx(WmModalHeader, { heading: props.modalHeading, subheading: (_b = props.modalSubHeading) !== null && _b !== void 0 ? _b : "", placeholder: undefined }), _jsx("div", Object.assign({ id: props.contentId, className: "content" }, { children: props.modalContent })), _jsx(WmModalFooter, { primaryText: props.primaryText, secondaryText: props.secondayText, deleteStyle: false, placeholder: undefined })] })));
    }
}
