import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { WmSearch, WmToggletip } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { isNullOrEmpty } from "../../static/js/commons";
import { timelineSelector } from "../../features/licensurePlans/TimelineSlice";
import { useSelector } from "react-redux";
import { cohortSelector } from "./CohortSlice";
var CATEGORY_TYPE;
(function (CATEGORY_TYPE) {
    CATEGORY_TYPE["SECTION"] = "section";
    CATEGORY_TYPE["SECTION_REQ"] = "sectionRequirements";
    CATEGORY_TYPE["SUBSECTION"] = "subSection";
    CATEGORY_TYPE["SUBSECTION_REQ"] = "subSectionRequirements";
})(CATEGORY_TYPE || (CATEGORY_TYPE = {}));
const CATEGORY_DETAILS = {
    [CATEGORY_TYPE.SECTION]: {
        parentType: null,
        showCheckBox: (section, currentStudentSubmissions, isStudentView) => {
            const hasRequirements = section.requirements.length > 0;
            const hasSubSections = section.subSections.length > 0;
            if (!hasRequirements && !hasSubSections) {
                return false;
            }
            const noDocsReqs = section.requirements.filter((r) => {
                return isStudentView
                    ? !(r.isStudentFileUploadRequired &&
                        selectRequirementWithSubmission(r.uuid, currentStudentSubmissions))
                    : !r.isStudentFileUploadRequired;
            });
            const noDocsSubSections = section.subSections.filter((s) => !CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION].showCheckBox(s, currentStudentSubmissions, isStudentView));
            return ((hasRequirements && noDocsReqs.length < section.requirements.length) ||
                (hasSubSections &&
                    noDocsSubSections.length < section.subSections.length));
        },
    },
    [CATEGORY_TYPE.SECTION_REQ]: {
        parentType: CATEGORY_TYPE.SECTION,
        showCheckBox: (secReq, currentStudentSubmissions, isStudentView) => {
            const isCheckBoxNotVisibile = (!secReq.isStudentFileUploadRequired && !isStudentView) ||
                (isStudentView &&
                    (!secReq.isStudentFileUploadRequired ||
                        !selectRequirementWithSubmission(secReq.uuid, currentStudentSubmissions))) ||
                secReq.requirementType === "APPLICATION" ||
                secReq.requirementType === "TIME_LOG";
            return !isCheckBoxNotVisibile;
        },
    },
    [CATEGORY_TYPE.SUBSECTION]: {
        parentType: CATEGORY_TYPE.SECTION,
        showCheckBox: (subSec, currentStudentSubmissions, isStudentView) => {
            return (subSec.requirements.length > 0 &&
                subSec.requirements.some((r) => {
                    return isStudentView
                        ? r.isStudentFileUploadRequired &&
                            selectRequirementWithSubmission(r.uuid, currentStudentSubmissions)
                        : r.isStudentFileUploadRequired;
                }));
        },
    },
    [CATEGORY_TYPE.SUBSECTION_REQ]: {
        parentType: CATEGORY_TYPE.SUBSECTION,
        showCheckBox: (subSecReq, currentStudentSubmissions, isStudentView) => {
            const isCheckBoxNotVisibile = (!subSecReq.isStudentFileUploadRequired && !isStudentView) ||
                (isStudentView &&
                    (!subSecReq.isStudentFileUploadRequired ||
                        !selectRequirementWithSubmission(subSecReq.uuid, currentStudentSubmissions))) ||
                subSecReq.requirementType === "APPLICATION" ||
                subSecReq.requirementType === "TIME_LOG";
            return !isCheckBoxNotVisibile;
        },
    },
};
const selectRequirementWithSubmission = (uuid, currentStudentSubmissions) => {
    var _a;
    const submissionStatus = (_a = currentStudentSubmissions.filter((submission) => {
        return uuid === submission.requirementUuid;
    })) === null || _a === void 0 ? void 0 : _a[0].status;
    return (submissionStatus === "MET" ||
        submissionStatus === "AWAITING_REVIEW" ||
        submissionStatus === "NOT_MET");
};
const SelectRequirements = ({ selectedUuids, setSelectedUuids, noneSelectedError, setNoneSelectedError, isStudentView, }) => {
    var _a, _b, _c, _d;
    const { timelineData } = useSelector(timelineSelector);
    const { currentStudentSubmissions } = useSelector(cohortSelector);
    const [filteredTimeline, setFilteredTimeline] = useState(timelineData);
    const [selectAllCheckboxes, setSelectAllCheckboxs] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const reqSearchPlaceholder = useIntl().formatMessage({
        id: "app.licensure.plan.extracts.search.requirement.placeholder",
    });
    const moreInfoLabel = useIntl().formatMessage({
        id: "app.openCohort.moreInfoLabel",
    });
    const reqSearchToggletipText = useIntl().formatMessage({
        id: "app.licensure.plan.extracts.licensure.plan.req.tooltip.help.text",
    });
    const studentFlowToggletipText = useIntl().formatMessage({
        id: "app.studentWorkflow.extracts.licensure.plan.req.tooltip.help.text",
    });
    const reqSearchHeaderLabel = useIntl().formatMessage({
        id: "app.licensure.plan.extracts.licensure.plan.req.text",
    });
    const extractUuids = (timeline) => {
        const uuids = [];
        timeline.sections.forEach((section) => {
            section.requirements.forEach((requirement) => {
                if (CATEGORY_DETAILS[CATEGORY_TYPE.SECTION_REQ].showCheckBox(requirement, currentStudentSubmissions, isStudentView)) {
                    uuids.push(requirement.uuid);
                }
            });
            if (CATEGORY_DETAILS[CATEGORY_TYPE.SECTION].showCheckBox(section, currentStudentSubmissions, isStudentView)) {
                uuids.push(section.uuid);
            }
            section.subSections.forEach((subSection) => {
                subSection.requirements.forEach((requirement) => {
                    if (CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION_REQ].showCheckBox(requirement, currentStudentSubmissions, isStudentView)) {
                        uuids.push(requirement.uuid);
                    }
                });
                if (CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION].showCheckBox(subSection, currentStudentSubmissions, isStudentView)) {
                    uuids.push(subSection.uuid);
                }
            });
        });
        return uuids;
    };
    const allTimelineUuids = useMemo(() => extractUuids(timelineData), [timelineData]);
    const allTimelineRequirements = [
        ...((_b = (_a = timelineData === null || timelineData === void 0 ? void 0 : timelineData.sections) === null || _a === void 0 ? void 0 : _a.map((sec) => sec.requirements.length ? sec.requirements : [])) !== null && _b !== void 0 ? _b : []),
        ...((_d = (_c = timelineData === null || timelineData === void 0 ? void 0 : timelineData.sections) === null || _c === void 0 ? void 0 : _c.map((sec) => {
            var _a, _b;
            return (_b = (_a = sec.subSections) === null || _a === void 0 ? void 0 : _a.flatMap((sub) => sub.requirements.length ? sub.requirements : [])) !== null && _b !== void 0 ? _b : [];
        })) !== null && _d !== void 0 ? _d : []),
    ].flat();
    useEffect(() => {
        if (!isNullOrEmpty(searchInput)) {
            const filteredReqs = allTimelineRequirements.filter((req) => { var _a; return (_a = req === null || req === void 0 ? void 0 : req.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchInput.trim().toLowerCase()); });
            const containsSearchedReq = (r) => filteredReqs.some((filteredReq) => filteredReq.uuid === r.uuid);
            const filteredSections = timelineData.sections.map((sec) => {
                const requirements = sec.requirements.filter((req) => containsSearchedReq(req));
                const subSections = sec.subSections
                    .filter((sub) => sub.requirements.some((req) => containsSearchedReq(req)))
                    .map((sub) => (Object.assign(Object.assign({}, sub), { requirements: sub.requirements.filter((req) => containsSearchedReq(req)) })));
                return Object.assign(Object.assign({}, sec), { requirements,
                    subSections });
            });
            const filteredTimeline = Object.assign(Object.assign({}, timelineData), { sections: filteredSections.filter((sec) => sec.requirements.length > 0 || sec.subSections.length > 0) });
            setFilteredTimeline(filteredTimeline);
        }
        else {
            setFilteredTimeline(timelineData);
        }
    }, [searchInput]);
    useEffect(() => {
        if (selectedUuids.length) {
            setNoneSelectedError(false);
        }
    }, [selectedUuids]);
    const isSelected = (uuid) => {
        return selectedUuids.includes(uuid);
    };
    const updatedSelections = ({ currentItem, currentItemChecked, currentItemType, isParentChangeRequired = true, isChildChangeRequired = true, }) => {
        if (currentItemChecked) {
            setSelectedUuids((prevUuids) => [
                ...prevUuids,
                currentItem.uuid,
            ]);
        }
        else {
            setSelectedUuids((prevUuids) => prevUuids.filter((cUuid) => cUuid !== currentItem.uuid));
        }
        if (isChildChangeRequired) {
            updateChildrenSelections(currentItem, currentItemChecked, currentItemType);
        }
        if (isParentChangeRequired) {
            updateParentSelections(currentItem, currentItemChecked, currentItemType);
        }
    };
    const updateChildrenSelections = (currentItem, currentItemChecked, currentItemType) => {
        var _a, _b;
        (_a = currentItem.requirements) === null || _a === void 0 ? void 0 : _a.forEach((req) => {
            if ((currentItemType === CATEGORY_TYPE.SECTION &&
                CATEGORY_DETAILS[CATEGORY_TYPE.SECTION_REQ].showCheckBox(req, currentStudentSubmissions, isStudentView)) ||
                (currentItemType === CATEGORY_TYPE.SUBSECTION &&
                    CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION_REQ].showCheckBox(req, currentStudentSubmissions, isStudentView))) {
                updatedSelections({
                    currentItem: req,
                    currentItemChecked,
                    currentItemType: currentItemType === CATEGORY_TYPE.SECTION
                        ? CATEGORY_TYPE.SECTION_REQ
                        : CATEGORY_TYPE.SUBSECTION_REQ,
                    isParentChangeRequired: false,
                });
            }
        });
        (_b = currentItem.subSections) === null || _b === void 0 ? void 0 : _b.forEach((sub) => {
            if (currentItemType === CATEGORY_TYPE.SECTION &&
                CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION].showCheckBox(sub, currentStudentSubmissions, isStudentView)) {
                updatedSelections({
                    currentItem: sub,
                    currentItemChecked,
                    currentItemType: CATEGORY_TYPE.SUBSECTION,
                    isParentChangeRequired: false,
                });
            }
        });
    };
    const updateParentSelections = (currentItem, currentItemChecked, currentItemType) => {
        const parentType = currentItemType
            ? CATEGORY_DETAILS[currentItemType].parentType
            : null;
        if (!parentType) {
            return;
        }
        const parent = findParentItem({
            currentItem,
            allItems: (filteredTimeline === null || filteredTimeline === void 0 ? void 0 : filteredTimeline.sections) || [],
        });
        if (parent) {
            if (!currentItemChecked) {
                return updatedSelections({
                    currentItemType: parentType,
                    currentItem: parent,
                    currentItemChecked: false,
                    isParentChangeRequired: true,
                    isChildChangeRequired: false,
                });
            }
            let currentParentChecked = false;
            if (parentType === CATEGORY_TYPE.SECTION) {
                const isReqFound = parent === null || parent === void 0 ? void 0 : parent.requirements.find((req) => req.uuid !== currentItem.uuid &&
                    CATEGORY_DETAILS[CATEGORY_TYPE.SECTION_REQ].showCheckBox(req, currentStudentSubmissions, isStudentView) &&
                    !isSelected(req.uuid));
                if (!isReqFound) {
                    const isSubSecFound = parent === null || parent === void 0 ? void 0 : parent.subSections.find((subSection) => subSection.uuid !== currentItem.uuid &&
                        CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION].showCheckBox(subSection, currentStudentSubmissions, isStudentView) &&
                        !isSelected(subSection.uuid));
                    if (!isSubSecFound) {
                        currentParentChecked = true;
                    }
                }
            }
            else if (parentType === CATEGORY_TYPE.SUBSECTION) {
                const isReqFound = parent === null || parent === void 0 ? void 0 : parent.requirements.find((req) => req.uuid !== currentItem.uuid &&
                    CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION_REQ].showCheckBox(req, currentStudentSubmissions, isStudentView) &&
                    !isSelected(req.uuid));
                if (!isReqFound) {
                    currentParentChecked = true;
                }
            }
            updatedSelections({
                currentItemType: parentType,
                currentItem: parent,
                currentItemChecked: currentParentChecked,
                isParentChangeRequired: true,
                isChildChangeRequired: false,
            });
        }
    };
    const findParentItem = ({ currentItem, allItems = [], }) => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        for (let i = 0; i < (allItems === null || allItems === void 0 ? void 0 : allItems.length); i++) {
            if (allItems[i].uuid === currentItem.uuid) {
                return null;
            }
            for (let j = 0; j < ((_b = (_a = allItems[i]) === null || _a === void 0 ? void 0 : _a.requirements) === null || _b === void 0 ? void 0 : _b.length); j++) {
                if (currentItem.uuid === allItems[i].requirements[j].uuid) {
                    return allItems[i];
                }
            }
            for (let k = 0; k < ((_d = (_c = allItems[i]) === null || _c === void 0 ? void 0 : _c.subSections) === null || _d === void 0 ? void 0 : _d.length); k++) {
                if (currentItem.uuid === allItems[i].subSections[k].uuid) {
                    return allItems[i];
                }
                for (let l = 0; l < ((_g = (_f = (_e = allItems[i]) === null || _e === void 0 ? void 0 : _e.subSections[k]) === null || _f === void 0 ? void 0 : _f.requirements) === null || _g === void 0 ? void 0 : _g.length); l++) {
                    if (currentItem.uuid ===
                        ((_j = (_h = allItems[i]) === null || _h === void 0 ? void 0 : _h.subSections[k]) === null || _j === void 0 ? void 0 : _j.requirements[l].uuid)) {
                        return (_k = allItems[i]) === null || _k === void 0 ? void 0 : _k.subSections[k];
                    }
                }
            }
        }
        return null;
    };
    const selectAllHandler = (isSelected) => {
        setSelectAllCheckboxs(isSelected);
        if (isSelected) {
            setSelectedUuids([...allTimelineUuids]);
        }
        else {
            setSelectedUuids([]);
        }
    };
    const renderReqSearch = () => {
        return filteredTimeline ? (_jsx("div", Object.assign({ className: "search-div" }, { children: _jsx(WmSearch, { id: "search-req", className: "req-search", placeholder: reqSearchPlaceholder, label: reqSearchPlaceholder, onInput: (event) => {
                    event.preventDefault();
                    setNoneSelectedError(false);
                    setSearchInput(event.target.value);
                }, numResults: allTimelineRequirements.length, value: searchInput }) }))) : null;
    };
    const renderTableHeader = () => {
        return (_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsx("tr", Object.assign({ className: "dataheaderrow", role: "row" }, { children: _jsxs("th", Object.assign({ tabIndex: 0, role: "columnheader", scope: "col", "aria-label": reqSearchHeaderLabel }, { children: [_jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "select-all", checked: allTimelineUuids.length > 0 &&
                                        allTimelineUuids.length === selectedUuids.length, onChange: () => selectAllHandler(!selectAllCheckboxes), disabled: allTimelineUuids.length === 0 }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "select-all" }, { children: _jsx("div", { children: reqSearchHeaderLabel }) }))] })), _jsx(WmToggletip, { className: "toggletip", label: moreInfoLabel, tooltip: isStudentView
                                ? studentFlowToggletipText
                                : reqSearchToggletipText, tooltipPosition: "bottom-right", placeholder: undefined })] })) }), "headers") })));
    };
    const renderTimelineWiseRequirements = () => {
        var _a, _b;
        return (_jsxs(_Fragment, { children: [renderReqSearch(), ((_a = filteredTimeline === null || filteredTimeline === void 0 ? void 0 : filteredTimeline.sections) === null || _a === void 0 ? void 0 : _a.length) ? (_jsxs("table", Object.assign({ className: "data-table req-selection-table", role: "grid", "aria-readonly": true }, { children: [renderTableHeader(), _jsx("tbody", Object.assign({ id: "req-table-body", className: `databody req-selection-tbody immersive-container ${isStudentView ? "student-flow-tbody" : ""}`, tabIndex: 0 }, { children: (_b = filteredTimeline === null || filteredTimeline === void 0 ? void 0 : filteredTimeline.sections) === null || _b === void 0 ? void 0 : _b.map((section) => {
                                var _a, _b, _c, _d;
                                return (_jsxs(Fragment, { children: [_jsx("tr", Object.assign({ className: "datarow", role: "row" }, { children: _jsx("td", Object.assign({ role: "gridcell", className: "sec-selection-cell", "data-td": section === null || section === void 0 ? void 0 : section.title }, { children: !CATEGORY_DETAILS[CATEGORY_TYPE.SECTION].showCheckBox(section, currentStudentSubmissions, isStudentView) ? (_jsx("span", Object.assign({ className: "label-div" }, { children: section === null || section === void 0 ? void 0 : section.title }))) : (_jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "section-" + section.uuid, checked: isSelected(section.uuid), onChange: (event) => {
                                                                updatedSelections({
                                                                    currentItemType: CATEGORY_TYPE.SECTION,
                                                                    currentItem: section,
                                                                    currentItemChecked: event.target.checked,
                                                                    isParentChangeRequired: false,
                                                                });
                                                            } }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "section-" + section.uuid }, { children: _jsx("div", Object.assign({ className: "label-div" }, { children: section === null || section === void 0 ? void 0 : section.title })) }))] }))) })) })), ((_a = section === null || section === void 0 ? void 0 : section.requirements) === null || _a === void 0 ? void 0 : _a.length) > 0 &&
                                            ((_b = section === null || section === void 0 ? void 0 : section.requirements) === null || _b === void 0 ? void 0 : _b.map((secReq) => {
                                                return (_jsx("tr", Object.assign({ className: "datarow ", role: "row" }, { children: _jsx("td", Object.assign({ role: "gridcell", className: "secreq-selection-cell", "data-th": secReq === null || secReq === void 0 ? void 0 : secReq.title }, { children: !CATEGORY_DETAILS[CATEGORY_TYPE.SECTION_REQ].showCheckBox(secReq, currentStudentSubmissions, isStudentView) ? (_jsx("span", Object.assign({ className: "label-div" }, { children: secReq === null || secReq === void 0 ? void 0 : secReq.title }))) : (_jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "secReq-" + secReq.uuid, checked: isSelected(secReq.uuid), onChange: (event) => {
                                                                        updatedSelections({
                                                                            currentItemType: CATEGORY_TYPE.SECTION_REQ,
                                                                            currentItem: secReq,
                                                                            currentItemChecked: event.target.checked,
                                                                            isChildChangeRequired: false,
                                                                        });
                                                                    } }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "secReq-" + secReq.uuid }, { children: _jsx("div", Object.assign({ className: "label-div" }, { children: secReq === null || secReq === void 0 ? void 0 : secReq.title })) }))] }))) })) }), "sec-Req-" + secReq.uuid));
                                            })), ((_c = section === null || section === void 0 ? void 0 : section.subSections) === null || _c === void 0 ? void 0 : _c.length) > 0 &&
                                            ((_d = section === null || section === void 0 ? void 0 : section.subSections) === null || _d === void 0 ? void 0 : _d.map((subSec) => {
                                                var _a;
                                                return (_jsxs(Fragment, { children: [_jsx("tr", Object.assign({ className: "datarow", role: "row" }, { children: _jsx("td", Object.assign({ role: "gridcell", className: "subsec-selection-cell", "data-th": subSec === null || subSec === void 0 ? void 0 : subSec.title }, { children: CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION].showCheckBox(subSec, currentStudentSubmissions, isStudentView) ? (_jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "subSec-" + subSec.uuid, checked: isSelected(subSec.uuid), onChange: (event) => {
                                                                                updatedSelections({
                                                                                    currentItemType: CATEGORY_TYPE.SUBSECTION,
                                                                                    currentItem: subSec,
                                                                                    currentItemChecked: event.target.checked,
                                                                                });
                                                                            } }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "subSec-" + subSec.uuid }, { children: _jsx("div", Object.assign({ className: "label-div" }, { children: subSec === null || subSec === void 0 ? void 0 : subSec.title })) }))] }))) : (_jsx("span", Object.assign({ className: "label-div" }, { children: subSec === null || subSec === void 0 ? void 0 : subSec.title }))) })) })), (subSec === null || subSec === void 0 ? void 0 : subSec.requirements.length) > 0 &&
                                                            ((_a = subSec === null || subSec === void 0 ? void 0 : subSec.requirements) === null || _a === void 0 ? void 0 : _a.map((subSecReq) => {
                                                                return (_jsx("tr", Object.assign({ className: "datarow", role: "row" }, { children: _jsx("td", Object.assign({ role: "gridcell", className: "subsecreq-selection-cell", "data-th": subSecReq === null || subSecReq === void 0 ? void 0 : subSecReq.title }, { children: !CATEGORY_DETAILS[CATEGORY_TYPE.SUBSECTION_REQ].showCheckBox(subSecReq, currentStudentSubmissions, isStudentView) ? (_jsx("span", Object.assign({ className: "label-div" }, { children: subSecReq === null || subSecReq === void 0 ? void 0 : subSecReq.title }))) : (_jsxs("span", Object.assign({ className: "checkbox-option" }, { children: [_jsx("input", { type: "checkbox", className: "chkbx-input", id: "subSecReq-" + subSecReq.uuid, checked: isSelected(subSecReq.uuid), onChange: (event) => {
                                                                                        updatedSelections({
                                                                                            currentItemType: CATEGORY_TYPE.SUBSECTION_REQ,
                                                                                            currentItem: subSecReq,
                                                                                            currentItemChecked: event.target.checked,
                                                                                            isChildChangeRequired: false,
                                                                                        });
                                                                                    } }), _jsx("label", Object.assign({ className: "chkbx", htmlFor: "subSecReq-" + subSecReq.uuid }, { children: _jsx("div", Object.assign({ className: "label-div" }, { children: subSecReq === null || subSecReq === void 0 ? void 0 : subSecReq.title })) }))] }))) })) }), "subSecReq-" + subSecReq.uuid));
                                                            }))] }, subSec.uuid));
                                            }))] }, section.uuid));
                            }) }))] }))) : (_jsxs("div", Object.assign({ className: "empty-list", role: "alert", "aria-live": "assertive", "aria-atomic": true }, { children: [_jsx("table", Object.assign({ className: "data-table", role: "grid", "aria-readonly": true }, { children: _jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsx("tr", { className: "dataheaderrow empty-row", role: "row" }, "headers") })) })), _jsx("div", Object.assign({ className: "no-results" }, { children: translate("app.gatewaysLandingPage.noMatch") })), _jsx("div", Object.assign({ className: "try-again" }, { children: translate("app.gatewaysLandingPage.tryAgain") }))] })))] }));
    };
    return (_jsx(_Fragment, { children: _jsxs("section", Object.assign({ className: "extracts-main-body" }, { children: [isStudentView && (_jsxs("p", Object.assign({ className: "student-export-info-text" }, { children: [translate("app.cohort.requiredText.prefix"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), translate("app.cohort.requiredText.suffix")] }))), _jsxs("div", Object.assign({ className: "req-selection-body" }, { children: [filteredTimeline ? (_jsxs(_Fragment, { children: [_jsxs("p", Object.assign({ className: `select-student-text ${noneSelectedError ? "alert-label" : ""}` }, { children: [translate("app.licensure.plan.extracts.select.licensure.plan.req.text"), _jsx("span", Object.assign({ className: "reqd" }, { children: "*" })), noneSelectedError && (_jsx("span", { className: "alert-icon mdi mdi-alert" }))] })), noneSelectedError && (_jsx("p", Object.assign({ className: "none-selected-error error-message", id: "no-selection", tabIndex: 0 }, { children: translate("app.licensure.plan.extracts.no.reqs.error.text") })))] })) : null, renderTimelineWiseRequirements()] }))] })) }));
};
export default SelectRequirements;
