import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { WmButton } from "@watermarkinsights/ripple-react";
import "./Cohorts.scss";
import { openFlyout, focusButtonComponent } from "../../static/js/commons.js";
import translate from "../../i18n/translate";
export default function ViewCohort({ programTitle, cohortData, closeViewCohort, }) {
    useEffect(() => {
        focusButtonComponent("cohort-flyout-close");
        setTimeout(() => openFlyout("view-cohort-flyout", "program-button-id" + cohortData.uuid, "cohort-flyout-close"), 200);
    });
    const renderEmptyView = () => {
        return (_jsxs("div", Object.assign({ className: "empty-cohort-view" }, { children: [_jsx("div", Object.assign({ className: "no-students-label" }, { children: translate("app.viewcohort.empty.label") })), _jsx("div", Object.assign({ className: "no-students-info" }, { children: translate("app.viewcohort.empty.info") }))] })));
    };
    return (_jsxs("div", Object.assign({ className: "flyout-panel half-flyout show", role: "dialog", id: "view-cohort-flyout", tabIndex: -1, "aria-label": "View Cohort | " + programTitle }, { children: [_jsx("div", { className: "overlay" }), _jsxs("div", Object.assign({ className: "container" }, { children: [_jsxs("section", Object.assign({ className: "immersive-header header flexible-header" }, { children: [_jsxs("div", Object.assign({ className: "cohort-header-details" }, { children: [_jsxs("h2", Object.assign({ id: "cohort-flyout-header", className: "subtitle" }, { children: [translate("app.viewcohort"), " | ", programTitle] })), _jsx("p", Object.assign({ className: "title", title: cohortData.title }, { children: cohortData.title }))] })), _jsx(WmButton, { buttonType: "navigational", icon: "close", tooltip: "Cancel", tooltipPosition: "bottom", id: "cohort-flyout-close", class: "hydrated", onClick: closeViewCohort, placeholder: undefined })] })), _jsx("section", Object.assign({ className: "content" }, { children: cohortData.users.length ? (_jsxs("div", Object.assign({ className: "view-cohort-students", id: "view-cohort-students" }, { children: [_jsx("div", Object.assign({ className: "students-info" }, { children: translate("app.viewcohort.student.info") })), _jsxs("table", Object.assign({ className: "data-table", tabIndex: 0 }, { children: [_jsx("thead", Object.assign({ className: "dataheader" }, { children: _jsxs("tr", Object.assign({ className: "dataheaderrow" }, { children: [_jsx("th", Object.assign({ className: "student-name", "data-th": "Student Name" }, { children: translate("app.viewcohort.student.name") })), _jsx("th", Object.assign({ className: "reporting-year", "data-th": "Student Email" }, { children: translate("app.viewcohort.student.email") }))] })) })), _jsx("tbody", Object.assign({ className: "databody" }, { children: cohortData.users.map((student) => {
                                                return (_jsxs("tr", Object.assign({ className: "datarow" }, { children: [_jsx("td", Object.assign({ className: "student-fullname", "data-td": "Student Name" }, { children: _jsxs("div", { children: [student.lastName, ", ", student.firstName] }) })), _jsx("td", Object.assign({ "data-td": "Student Email" }, { children: _jsx("div", { children: student.email }) }))] }), "student-" + student.uuid));
                                            }) }))] }))] }))) : (renderEmptyView()) })), _jsxs("div", Object.assign({ className: "flyout-footer" }, { children: [_jsx("div", {}), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "close-flyout-button", onClick: closeViewCohort, placeholder: undefined }, { children: translate("app.viewcohort.close") }))] }))] }))] })));
}
