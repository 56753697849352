import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import translate from "../../../i18n/translate";
const ViewMore = ({ truncationLimit, children, showContent, setIndex, }) => {
    const text = children;
    return (_jsxs("span", Object.assign({ className: "text", "aria-label": text }, { children: [(text === null || text === void 0 ? void 0 : text.length) > truncationLimit ? (_jsxs("span", Object.assign({ className: "view-more-component" }, { children: [!showContent ? text.slice(0, truncationLimit) + " ..." : text + " ", _jsx(WmButton, Object.assign({ buttonType: "textonly", icon: "f40a", iconRotate: !showContent ? 0 : 270, onClick: () => setIndex(), className: !showContent ? "hide" : "show", placeholder: undefined }, { children: !showContent
                            ? translate("global.view.more.label")
                            : translate("global.show.less.label") }))] }))) : (text), _jsx("span", Object.assign({ className: "sr-only", "aria-live": "polite", "aria-atomic": "true" }, { children: showContent && text.slice(truncationLimit) }))] })));
};
export default ViewMore;
