import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmModal, WmModalHeader, WmModalFooter, } from "@watermarkinsights/ripple-react";
export default function InformationModal(props) {
    if (!props.isOpen) {
        return _jsx(_Fragment, {});
    }
    else {
        return (_jsxs(WmModal, Object.assign({ open: props.isOpen, modalType: "dialog", id: props.modalId, elementToFocus: "primary", "aria-label": props.ariaLabelValue, onWmModalPrimaryTriggered: props.onPrimaryClick, onWmModalCloseTriggered: props.onCloseClick ? props.onCloseClick : props.onPrimaryClick, uid: "", placeholder: undefined }, { children: [_jsx(WmModalHeader, { heading: props.modalHeading, placeholder: undefined }), _jsx("div", Object.assign({ id: props.contentId, className: "body" }, { children: props.modalContent })), _jsx(WmModalFooter, { primaryText: props.primaryButtonText ? props.primaryButtonText : "Close", secondaryText: "", deleteStyle: false, placeholder: undefined })] })));
    }
}
