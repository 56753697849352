import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import translate from "../../i18n/translate";
import LOGO from "../../images/icons/favicon-96.png";
import { useEffect, useRef, useState } from "react";
import { cohortSelector, downloadExtract, retryUserExtract, createUserExtract, } from "../../features/cohorts/CohortSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const StudentExportPage = () => {
    document.title = "Student Exports | Watermark";
    const dispatch = useDispatch();
    const { userExtract } = useSelector(cohortSelector);
    const [isFileDownloaded, setIsFileDownloaded] = useState(false);
    const [isRetryButtonClicked, setIsRetryButtonClicked] = useState(false);
    const currentTabOpener = useRef(window.opener);
    const { cohortUuid, orgUuid, programUuid, extractUuid } = useParams();
    useEffect(() => {
        dispatch(createUserExtract({
            cohortUuid: cohortUuid,
            orgUuid: orgUuid,
            progUuid: programUuid,
            extractUuid: extractUuid,
        }));
        const handleUnload = () => {
            localStorage.setItem("isExtractTabOpen", "false");
            currentTabOpener.current.postMessage("tabClosed", "*");
        };
        window.addEventListener("beforeunload", handleUnload);
        return () => window.removeEventListener("beforeunload", handleUnload);
    }, []);
    useEffect(() => {
        if (userExtract.status === "COMPLETED") {
            downloadExtract({
                fileUuid: userExtract.uuid,
                filePath: userExtract.zipFilePath,
            }).then(() => {
                setTimeout(() => {
                    setIsFileDownloaded(true);
                }, 2000);
            });
        }
    }, [userExtract.status]);
    useEffect(() => {
        if (isFileDownloaded) {
            window.close();
        }
    }, [isFileDownloaded]);
    return (_jsxs("div", Object.assign({ className: "export-message-page" }, { children: [_jsx("img", { className: "export-message-logo", src: LOGO, alt: "Watermark logo" }), _jsxs("div", Object.assign({ className: "export-pending" }, { children: [_jsx("h2", Object.assign({ className: `export-message-text ${userExtract.status === "FAILED" && !isRetryButtonClicked
                            ? "export-failed-message"
                            : ""}` }, { children: translate(userExtract.status === "FAILED" && !isRetryButtonClicked
                            ? "app.licensure.plan.student.extracts.failed.header"
                            : "app.licensure.plan.student.extracts.in.progress.header") })), _jsxs("p", Object.assign({ className: "export-message-sub-text" }, { children: [translate(userExtract.status === "FAILED" && !isRetryButtonClicked
                                ? "app.licensure.plan.student.extracts.failed.sub.header"
                                : "app.licensure.plan.student.extracts.in.progress.sub.header"), userExtract.status === "FAILED" && !isRetryButtonClicked ? null : (_jsx("span", { children: _jsx("b", { children: translate("app.licensure.plan.student.extracts.in.progress.sub.header.info") }) }))] })), _jsx(WmButton, Object.assign({ buttonType: "primary", className: "export-primary-button", id: userExtract.status === "FAILED" ? "retry-export" : "cancel-export", onClick: () => {
                            if (userExtract.status === "FAILED" && !isRetryButtonClicked) {
                                setIsRetryButtonClicked(true);
                                dispatch(retryUserExtract({
                                    orgId: orgUuid,
                                    progUuid: programUuid,
                                    cohortUuid: cohortUuid,
                                    extractUuid: userExtract.uuid,
                                })).then(() => {
                                    setIsRetryButtonClicked(false);
                                });
                            }
                            else {
                                window.close();
                            }
                        }, placeholder: undefined }, { children: translate(userExtract.status === "FAILED" && !isRetryButtonClicked
                            ? "app.licensure.plan.student.extracts.retry.export"
                            : "app.licensure.plan.student.extracts.cancel.export") }))] }))] })));
};
export default StudentExportPage;
