import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import translate from "../../i18n/translate";
import { WmButton } from "@watermarkinsights/ripple-react";
import "./StudentWorkflow.scss";
import "../cohorts/Cohorts.scss";
import { useEffect, useState } from "react";
import { cohortSelector } from "../../features/cohorts/CohortSlice";
import { useSelector } from "react-redux";
export default function OpenActivityLogPanel({ submissionHistories, closeShowActivityLog, activityHistory, }) {
    const { currentSubmission } = useSelector(cohortSelector);
    const [activityLog, setActivityLog] = useState([]);
    useEffect(() => {
        let activity = Array();
        let flag = false;
        let histories = [...currentSubmission.submissionHistories];
        for (let his of histories.reverse()) {
            if (his.action === "FILE_UPLOAD" ||
                his.action === "FILE_DELETE" ||
                his.action === "STATUS_UPDATE") {
                if (flag && his.createdByObject.uuid === currentSubmission.userUuid) {
                    let his_1 = Object.assign({}, his);
                    his_1.resubmission = true;
                    his = his_1;
                }
                activity.push(his);
                if (his.createdByObject.uuid === currentSubmission.userUuid) {
                    flag = true;
                }
            }
        }
        setActivityLog(activity.reverse());
    }, [currentSubmission]);
    return (_jsxs("div", Object.assign({ className: "mini-panel immersive-container" }, { children: [_jsx("div", Object.assign({ className: "header" }, { children: _jsxs("div", Object.assign({ className: "flex-row -justified -verticallycentered" }, { children: [_jsx("span", Object.assign({ className: "title" }, { children: translate("app.studentWorkflow.flyout.activityFeed") })), _jsx(WmButton, { id: "close-activity-log-panel", buttonType: "navigational", icon: "f156", tooltip: "Close", tooltipPosition: "left", onClick: closeShowActivityLog, placeholder: undefined })] })) })), _jsx("div", Object.assign({ className: "body", tabIndex: 0 }, { children: _jsx("div", Object.assign({ className: "log-section" }, { children: activityLog.length > 0 ? (_jsx(_Fragment, { children: activityLog.map((history) => {
                            return activityHistory(history);
                        }) })) : (_jsxs("div", Object.assign({ className: "no-requirements -align" }, { children: [translate("app.studentWorkflow.noActivityFeed"), " "] }))) })) }))] })));
}
