import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { WmButton } from "@watermarkinsights/ripple-react";
import { SideFlyout } from "../../../features/common/component/SideFlyout";
import { useIntl } from "react-intl";
export const RequirementFlyoutFooter = ({ closeFlyout, handleNextClick, showError, countRequirementSelection, }) => {
    const primaryButtonText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirement.cancel",
    });
    const secondaryButtonText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirement.next",
    });
    const requirementsSelectionCountText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirements.selected.count",
    }, { count: countRequirementSelection !== null && countRequirementSelection !== void 0 ? countRequirementSelection : 0 });
    const requirementSelectionCountText = useIntl().formatMessage({
        id: "app.cohort.export.flyout.content.requirement.selected.count",
    }, { count: countRequirementSelection !== null && countRequirementSelection !== void 0 ? countRequirementSelection : 0 });
    return (_jsxs(SideFlyout.Footer, { children: [_jsx("div", Object.assign({ className: "info-section" }, { children: _jsx("span", Object.assign({ className: `count-text ${showError ? "error" : ""}`, tabIndex: 0 }, { children: countRequirementSelection === 1 || countRequirementSelection === 0
                        ? requirementSelectionCountText
                        : requirementsSelectionCountText })) })), _jsxs("div", Object.assign({ className: "button-container" }, { children: [_jsx(WmButton, Object.assign({ buttonType: "secondary", id: "cancel-btn", onClick: closeFlyout, placeholder: undefined }, { children: primaryButtonText })), _jsx(WmButton, Object.assign({ buttonType: "primary", id: "requirement-next-btn", onClick: handleNextClick, placeholder: undefined }, { children: secondaryButtonText }))] }))] }));
};
